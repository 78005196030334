import React from 'react';
import { Tabs } from 'antd';
import Main from './../Layout';
import ScheduledInterviewsClient from './ScheduledInterviews';
import Interviewers from './Interviewers';
import Calendar from '../Calender/Calender';

import "./AllInterviewDetails.css"
const { TabPane } = Tabs;

const items = [
    {
        key: '1',
        label: 'Scheduled Interviews',
        children: <ScheduledInterviewsClient />
    },
    {
        key: '2',
        label: "All Interviewers",
        children: <Interviewers />
    }
]

const AllInterviewDetails = () => {
    return (
        <Main defaultSelectedKey="6">
            <Tabs className="tabs" defaultActiveKey="1" type="card">
                <TabPane tab="Scheduled Interviews" key="1">
                    <ScheduledInterviewsClient />
                </TabPane>
                <TabPane tab="Interviewers" key="2">
                    <Interviewers />
                </TabPane>
                <TabPane tab="Calender" key="3">
                    <Calendar />
                </TabPane>
    
            </Tabs>
            <div>
                <Tabs defaultActiveKey="1" items={items}>
                </Tabs>
            </div>
        </Main>
    );
};

export default AllInterviewDetails;
