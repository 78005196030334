import React from 'react'
import './InterviewsCard.css';
const InterviewsCard = ({ time, round_num, job_title, interviewer_name, is_highlighted }) => {
  return (
    <div className="manager-interview-card">
      <div className="time-slot">{time}</div>
      <div className="interview-details">
        <span className="candidate-name">{interviewer_name} : {job_title}; {round_num} round </span>
      </div>
    </div>
  );
}

export default InterviewsCard



