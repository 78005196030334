import React, { useEffect, useState } from "react";
import { useAuth } from "../../common/useAuth";
import { message, Button, Input } from "antd";
import "./OrganizationApplications.css";
import phoneicon from "../../../images/common/phoneicon.svg";
import { SearchOutlined } from "@ant-design/icons";

const OrganizationApplications = ({ setDraggedId, onDropShareResume ,setIsVisible}) => {
  const { apiurl, token } = useAuth();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [draggedItem, setDraggedItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredData = data.filter((app) => {
    return (
      app.job_title.toLowerCase().includes(searchTerm) ||
      app.candidate_name.toLowerCase().includes(searchTerm) ||
      app.cand_number.toLowerCase().includes(searchTerm)
    );
  });


  const fetchData = async (pageNumber) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${apiurl}/recruiter/organization-applications/?page=${pageNumber}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const result = await response.json();

      if (result.error) {
        message.error(result.error);
      } else {
        setData(result.results || []);
      }
    } catch (e) {
      console.log(e);
      message.error("Failed to fetch data");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchData(page);
    }
  }, [token, page]);

  const handleDragStart = (application_id) => {
    setDraggedId(application_id);
  };

  console.log("printing the applications data ", data);

  return (
    <div className="applications-container">
      <h2 style={{textAlign:"left"}}>Previous Applications</h2>
      <Input
        placeholder="Search"
        prefix={<SearchOutlined style={{ color: "#555" }} />}
        style={{
          borderRadius: "9999px",
          padding: "8px 12px",
          maxWidth: "500px",
        }}
        value={searchTerm}
        onChange={handleSearch}
      />
      {loading ? <p>Loading...</p> : null}
      <div className="cards-container">
        {filteredData.length > 0 ? (
          filteredData.map((app, index) => (
            <div
              key={app.application_id}
              className="card"
              draggable
              onDragStart={() => handleDragStart(app.application_id)}
            >
              <div
                style={{ display: "flex", gap: "40px", alignItems: "center" }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ fontWeight: "600", color: "#5F6469" }}>
                    {app.job_title}
                  </span>
                  <span style={{ color: "#7A7575", fontWeight: "500" }}>
                    {app.candidate_name}
                  </span>
                </div>
                <div
                  style={{
                    backgroundColor: "#EDF7F7",
                    color: "#5F6469",
                    padding: "4px 12px ",
                    borderRadius: "20px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <img src={phoneicon} style={{ width: "17px" }} />
                  {app.cand_number}
                </div>
              </div>

              <p className={`status ${app.status.toLowerCase()}`}>
                {app.status}
              </p>
            </div>
          ))
        ) : (
          <p>No applications found</p>
        )}
      </div>

      <div className="pagination-buttons">
        <Button disabled={page === 1} onClick={() => setPage(page - 1)}>
          Previous
        </Button>
        <Button onClick={() => setPage(page + 1)}>Next</Button>
      </div>
    </div>
  );
};

export default OrganizationApplications;
