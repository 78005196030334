import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Button, Image } from "antd";
import { useNavigate } from "react-router-dom";
import "./ComingSoon.css";
import Logo from "../images/GAHIRESYNC-LOGO.svg";

export default function ComingSoon() {
    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    const navigate = useNavigate();

    // 🎯 Set the actual launch date & time (YYYY, MM - 1, DD, HH, MM, SS)
    const launchDate = new Date(2025, 2, 30, 10, 0, 0); // Change this to your actual launch date

    const calculateTimeLeft = () => {
        const now = new Date().getTime(); // Get current time in milliseconds
        const difference = launchDate.getTime() - now; // Compare in milliseconds

        if (difference <= 0) {
            navigate("/welcome");
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        return {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    };


    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="coming-soon-container">
            <div className="stars"></div>
            <div className="twinkling"></div>

            <div className="content-wrapper">
                <motion.div className="logo-container" initial={{ y: -50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1 }}>
                    <Image src={Logo} alt="HireSync Logo" width={200} height={60} className="logo" />
                </motion.div>

                <motion.div className="maintenance-text" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5, duration: 1 }}>
                    <span className="badge">Alpha-testing version</span>
                    <h3>Website Is Under Development</h3>
                </motion.div>

                <motion.div className="launch-text" initial={{ scale: 0.8, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} transition={{ delay: 0.8, duration: 1 }}>
                    <h1>
                        <span className="text-white">We're </span>
                        <span className="text-gradient">Launching</span>
                        <span className="text-white"> Soon</span>
                    </h1>
                </motion.div>

                <motion.div className="countdown-container" initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 1.2, duration: 1 }}>
                    <div className="countdown-item">
                        <div className="countdown-value">{timeLeft.days}</div>
                        <div className="countdown-label">Days</div>
                    </div>
                    <div className="countdown-item">
                        <div className="countdown-value">{timeLeft.hours}</div>
                        <div className="countdown-label">Hours</div>
                    </div>
                    <div className="countdown-item">
                        <div className="countdown-value">{timeLeft.minutes}</div>
                        <div className="countdown-label">Minutes</div>
                    </div>
                    <div className="countdown-item">
                        <div className="countdown-value">{timeLeft.seconds}</div>
                        <div className="countdown-label">Seconds</div>
                    </div>
                </motion.div>

                <motion.div className="description" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.5, duration: 1 }}>
                    <p>Welcome to HireSync - Revolutionizing the hiring process with AI-powered matching and seamless candidate management.</p>
                </motion.div>
            </div>
        </div>
    );
}
