import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import "./Interviewers.css";
import AddInterviewer from "./AddInterviewer";
import { useAuth } from "../../../common/useAuth";
import Note from "../../../../images/Client/note.svg";
import Mail from "../../../../images/Client/gmail.svg";
import Profile from "../../../../images/Client/profile.png";
import PlusIcon from "../../../../images/Client/plusicon.svg";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const InterviewerCard = ({ item }) => {
	const date = new Date(item.joining_date);
	const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
	const navigate = useNavigate()
	return (
		<div className="interviewer-card-client">
			<div className="profile">
				<div className="details">
					<img src={Profile} alt="" />
					<div className="name">
						<span className="interviewer-name">{item.interviewer_name}</span>
						<span className="joining-date">{formattedDate} (added)</span>
					</div>
				</div>
				<div className="view-all" onClick={() => { navigate(`/client/interviewer/${item.id}`) }}>
					View all
				</div>
			</div>
			<span className="mail"><img src={Mail} alt="" />{item.interviewer_email}</span>
			<div className="pending">
				<span className="alloted note"><img src={Note} alt="" /> {item.scheduled_interviews} Alloted
				</span>
				<span className="completed note"><img src={Note} alt="" /> {item.rounds_completed} Completed
				</span>
			</div>
		</div>
	);
};

const Interviewers = () => {
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [isModalVisible, setIsModalVisible] = useState(false);
	const { apiurl, token } = useAuth();

	const loadInterviewers = async () => {
		try {
			const response = await fetch(`${apiurl}/client/get-interviewers/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			setData(data);
			setFilteredData(data); // Initialize filteredData with all data
		} catch (error) {
			console.error("Error fetching Interviewers:", error);
		}
	};

	useEffect(() => {
		if (token) {
			loadInterviewers();
		}
	}, [token]);

	// Handle search input change
	const handleSearch = (e) => {
		const value = e.target.value.toLowerCase();
		setSearchTerm(value);

		const filtered = data.filter((item) =>
			item.interviewer_name.toLowerCase().includes(value)
		);
		setFilteredData(filtered);
	};

	return (
		<div defaultSelectedKey="6">
			<div className="interviewers">
				<div className="title">All Interviewers</div>
				<div className="top-content">
					<div className="search-btn">
						<SearchOutlined />
						<input
							type="text"
							value={searchTerm}
							onChange={handleSearch}
							placeholder="Search Interviewer"
						/>
					</div>
					<button onClick={() => setIsModalVisible(true)}>
						<img src={PlusIcon} alt="" />
						Add Interviewer
					</button>
				</div>
				<div className="interviewer-cards-grid">
					{filteredData && filteredData.length > 0 ? (
						filteredData.map((item, index) => (
							<InterviewerCard key={index} item={item} />
						))
					) : (
						<p>No interviewers found.</p>
					)}
				</div>
				<Modal
					open={isModalVisible}
					onCancel={() => setIsModalVisible(false)}
					footer={null}
				>
					<AddInterviewer onclose={loadInterviewers} />
				</Modal>
			</div>
		</div>
	);
};

export default Interviewers;
