"use client"

import { useState } from "react"
import "./FileUploadCard.css"

const FileUploadCard = ({handleDragOverShareResume,handleDropOnShareResume,setIsVisible}) => {
  const [questionaryFile, setQuestionaryFile] = useState({ name: "File_name.pdf", size: 1500 })
  const [resumeFile, setResumeFile] = useState({ name: "File_name.pdf", size: 1500 })

  const handleQuestionaryDrop = (e) => {
    e.preventDefault()
    const files = e.dataTransfer.files
    if (files.length) {
      setQuestionaryFile({
        name: files[0].name,
        size: Math.round(files[0].size / 1024),
      })
    }
  }


 

  const handleResumeDrop = (e) => {
    e.preventDefault()
    const files = e.dataTransfer.files
    if (files.length) {
      setResumeFile({
        name: files[0].name,
        size: Math.round(files[0].size / 1024),
      })
    }
  }

  const handleQuestionaryFileChange = (e) => {
    const files = e.target.files
    if (files.length) {
      setQuestionaryFile({
        name: files[0].name,
        size: Math.round(files[0].size / 1024),
      })
    }
  }

  const handleResumeFileChange = (e) => {
    const files = e.target.files
    if (files.length) {
      setResumeFile({
        name: files[0].name,
        size: Math.round(files[0].size / 1024),
      })
    }
  }

  const preventDefault = (e) => {
    e.preventDefault()
  }


  const handleOpen=()=>{
    console.log("running")
    setIsVisible(true)
  }
  return (
    <div className="file-upload-container">
      <div className="upload-card">
        <h2 className="section-title">Questionary Section</h2>
        <div
          className="drop-area"
          onDrop={handleQuestionaryDrop}
          onDragOver={preventDefault}
          onDragEnter={preventDefault}
        >
          <div className="upload-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
              <polyline points="17 8 12 3 7 8" />
              <line x1="12" y1="3" x2="12" y2="15" />
            </svg>
          </div>
          <p className="upload-text">Drag and Drop resume</p>
          <p className="upload-text">Or Choose file</p>
          <input type="file" id="questionary-file" className="file-input" onChange={handleQuestionaryFileChange} />
        </div>
        {questionaryFile && (
          <div className="file-info">
            <div className="file-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                <polyline points="14 2 14 8 20 8" />
                <line x1="16" y1="13" x2="8" y2="13" />
                <line x1="16" y1="17" x2="8" y2="17" />
                <polyline points="10 9 9 9 8 9" />
              </svg>
            </div>
            <div className="file-details">
              <span className="file-name">{questionaryFile.name}</span>
              <span className="file-size">{questionaryFile.size} KB</span>
            </div>
           
          </div>

        )}
            <button>
              generate questions 
            </button>
      </div>

      <div className="upload-card">
        <h2 className="section-title" >Share Resume</h2>
        <div className="drop-area" onDragOver={handleDragOverShareResume} onDrop={handleDropOnShareResume} >
          <div className="upload-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
              <polyline points="17 8 12 3 7 8" />
              <line x1="12" y1="3" x2="12" y2="15" />
            </svg>
          </div>
          <p className="upload-text">Drag and Drop resume</p>
          <p className="upload-text">Or Choose file</p>
          <input type="file" id="resume-file" className="file-input" onChange={handleResumeFileChange} />
        </div>
        <div className="file-action-row">
          {resumeFile && (
            <div className="file-info file-info-compact">
              <div className="file-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                  <polyline points="14 2 14 8 20 8" />
                  <line x1="16" y1="13" x2="8" y2="13" />
                  <line x1="16" y1="17" x2="8" y2="17" />
                  <polyline points="10 9 9 9 8 9" />
                </svg>
              </div>
              <div className="file-details">
                <span className="file-name">{resumeFile.name}</span>
                <span className="file-size">{resumeFile.size} KB</span>
              </div>
            </div>
          )}
          <button className="share-button" onClick={handleOpen}>Share to Client</button>
        </div>
      </div>
    </div>
  )
}

export default FileUploadCard

