import React from "react";
import { Form, Input, Space, Select, InputNumber } from "antd";

const { Option } = Select;

const SecondarySkillsForm = ({ secondarySkills }) => {

    return (
        <div>
            {secondarySkills.length > 0 ? (
                secondarySkills.map((skill, index) => (
                    <Space
                        key={index}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                    >
                        {/* Skill Name */}
                        <Form.Item
                            name={["secondary_skills", index, "skill_name"]}
                            initialValue={skill.skill_name}
                            label="Skill Name"
                        >
                            <Input disabled />
                        </Form.Item>

                        {/* Metric Type Dropdown */}
                        <Form.Item
                            name={["secondary_skills", index, "metric_type"]}
                            initialValue={skill.metric_type}
                            label="Metric Type"
                        // rules={[{ required: true, message: "Metric type is required" }]}
                        >
                            <div className="metric-type">{skill.metric_type}</div>
                        </Form.Item>

                        {/* Conditional Field: Rating or Experience */}
                        <Form.Item
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.secondary_skills?.[index]?.metric_type !==
                                currentValues.secondary_skills?.[index]?.metric_type
                            }
                        >
                            {({ getFieldValue }) => {
                                const metricType = getFieldValue(["secondary_skills", index, "metric_type"]);

                                if (metricType === "rating") {
                                    return (
                                        <Form.Item
                                            label="Rating (out of 10)"
                                            name={["secondary_skills", index, "rating"]}
                                            rules={[{ required: true, message: "Rating is required" }]}
                                        >
                                            <InputNumber min={1} max={10} placeholder="Enter rating" />
                                        </Form.Item>
                                    );
                                }

                                if (metricType === "experience") {
                                    return (
                                        <Form.Item
                                            label="Years of Experience"
                                            name={["secondary", index, "experience"]}
                                            rules={[{ required: true, message: "Experience is required" }]}
                                        >
                                            <Select placeholder="Select experience">
                                                <Option value="0">0 years</Option>
                                                <Option value="1-2">1-2 years</Option>
                                                <Option value="2-3">2-3 years</Option>
                                                <Option value="3-4">3-4 years</Option>
                                                <Option value="4-5">4-5 years</Option>
                                                <Option value="5+">5+ years</Option>
                                                <Option value="N/A">N/A</Option>
                                            </Select>
                                        </Form.Item>
                                    );
                                }

                                return (
                                    <Form.Item
                                        label="Metric Value"
                                        name={["secondary", index, "metric_value"]}
                                        rules={[{ required: true, message: "Metric value is required" }]}
                                    >
                                        <Input placeholder="Enter metric value" />
                                    </Form.Item>
                                );
                            }}
                        </Form.Item>
                    </Space>
                ))
            ) : (
                <p>No secondary skills to display</p>
            )}
        </div>
    );
};

export default SecondarySkillsForm;
