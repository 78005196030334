import React from 'react'
import home from "./../../../images/agency/Home.svg"
import files from "./../../../images/agency/Files.svg"
import jobs from "./../../../images/agency/Jobs.svg"
import candidates from "./../../../images/agency/Candidates.svg"
import calendar from "./../../../images/agency/Calendar.svg"
import MainLayout from '../../common/Layout/MainLayout'

const Layout2 = ({ children, defaultSelectedKey }) => {

    const options = [
        {
            "key": 1,
            "label": "Home",
            "logo": home,
            "path": '/'
        },
        {
            "key": 2,
            "label": "Negotiations",
            "logo": calendar,
            "path": '/agency/negotiations'
        },
        {
            "key": 3,
            "label": "Jobs",
            "logo": jobs,
            "path": '/agency/jobs'
        },
        {
            "key": 4,
            "label": "Candidates",
            "logo": candidates,
            'path': '/agency/candidates',
        },
        {
            "key": 5,
            "label": "Recruiters",
            "logo": candidates,
            "path": "/agency/recruiters/"
        },
        {
            "key": 6,
            "label": "Reports",
            "logo": files,
            "path": '/agency/reports'
        },
        {
            "key": 7,
            "label": "Selected Candidates",
            "logo": files,
            "path": '/agency/selected-candidates'
        },
    ]

    return (
        <>
            <MainLayout children={children} defaultSelectedKey={defaultSelectedKey} options={options} />
        </>
    )
}

export default Layout2;