import React from 'react'
import { Tabs } from 'antd'
import Main from '../Layout'
import Onhold from './OnHold/Onhold'
import SelectedCandidates from './SelectedCandidates'
import JoinedCandidates from './JoinedCandidates/JoinedCandidates'
import CandidatesLeft from './CandidatesLeft'
import ShortlistedCandidates from './ShortlistedCandidates'

const items = [

    {
        key: "1",
        label: "Processing",
        children: <ShortlistedCandidates />
    },
    {
        key: "2",
        label: "On hold",
        children: <Onhold />
    },
    {
        key: "3",
        label: "Selected Candidates",
        children: <SelectedCandidates />
    },
    {
        key: "4",
        label: "Joined candidates",
        children: <JoinedCandidates />
    },
    {
        key: "5",
        label: "Candidates Left",
        children: <CandidatesLeft />
    }
]


const AllCandidatesClient = () => {
    return (
        <Main defaultSelectedKey="4">
            <Tabs defaultActiveKey="1" items={items} ></Tabs>
        </Main>
    )
}

export default AllCandidatesClient