import React, { useEffect, useState } from 'react'
import { Button, Modal, message, Form, Input, Spin } from 'antd'
import { useAuth } from '../../../common/useAuth'
import { useNavigate } from 'react-router-dom'
import Terms from '../../../../images/Client/Terms.svg'


const OrganizationTerms = ({ setCurrentStep, termsData, setTermsData, companyCode, setCompanyCode }) => {
    const { token, apiurl } = useAuth();
    const [htmlContent, setHtmlContent] = useState();
    const [loading, setLoading] = useState(false);
    const [negotiatedData, setNegotiatedData] = useState(null)
    const [isNegotiating, setIsNegotiating] = useState(false);
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const [agree, setAgree] = useState(true)    //to agree with the organization terms, if the organization yet not accepted the client's negotiation request

    // const fetchOrganizationTerms = async () => {
    //     setLoading(true)
    //     try {
    //         const response = await fetch(
    //             `${apiurl}/get-organization-terms/?org_code=${companyCode}`,
    //             {
    //                 method: "GET",
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             }
    //         );

    //         const contentType = response.headers.get("Content-Type");
    //         if (contentType.includes("text/html")) {
    //             const html = await response.text();
    //             setHtmlContent(html.html);
    //             const dataJsonMatch = html.match(/window\.__DATA__ = (\{.*?\});/);
    //             if (dataJsonMatch && dataJsonMatch[1]) {
    //                 const data = JSON.parse(dataJsonMatch[1]);
    //                 setTermsData(data);
    //             }
    //         } else {
    //             const data = await response.json();

    //             if (data.error) {
    //                 setCurrentStep(0)
    //                 return message.error(data.error)
    //             }
    //             setTermsData(data);
    //         }
    //         message.success("Company terms fetched successfully!");
    //     } catch (error) {
    //         message.error(error.message);
    //     }
    //     finally { setLoading(false) }
    // };


    console.log(htmlContent, " is the html Content")
    const fetchOrganizationTerms = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiurl}/get-organization-terms/?org_code=${companyCode}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const contentType = response.headers.get("Content-Type");

            if (contentType && contentType.includes("application/json")) {
                const data = await response.json();
                if (data.error) {
                    setCurrentStep(0);
                    return message.error(data.error);
                }

                // Extract JSON response
                setTermsData(data.terms_data || {});
                setNegotiatedData(data.negotiated_data || null);
                if (data.negotiated_data) {
                    setAgree(false)
                }
                setHtmlContent(data.html || null);
            } else if (contentType && contentType.includes("text/html")) {
                const html = await response.text();
                setHtmlContent(html);
            } else {
                message.error("Unexpected response format received.");
            }

            message.success("Company terms fetched successfully!");
        } catch (error) {
            message.error(`Failed to fetch terms: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };


    const handleNegotiateTerms = () => {
        setIsNegotiating(true);
    };

    const handleNegotiationSubmit = async (values) => {
        console.log("Negotiated Terms:", values);
        values = { ...values, code: companyCode }
        try {
            const response = await fetch(`${apiurl}/negotiate-terms/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(values),
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.detail || "Failed to create job posting");
            }
            message.success("Negotiation raised successfully!");
            navigate('/');
            setIsNegotiating(false);
        } catch (error) {
            message.error(error.message);
        }

    };


    return (
        <div>
            <div className="step1">
                <div className="step1-form">
                    <label>Company Code</label>
                    <div className="form-content">
                        <Input
                            className="input"
                            placeholder="Enter company code"
                            value={companyCode}
                            onChange={(e) => {
                                setCompanyCode(e.target.value);
                            }}
                        />
                        <Button className="button" type="primary" onClick={fetchOrganizationTerms}>
                            Fetch Terms
                        </Button>
                    </div>
                </div>
                {negotiatedData &&
                    <div className="terms-form">
                        <span style={{ fontWeight: '600', fontSize: '18px' }}>Your previous negotiation is not yet accepted by the agency</span>
                        <span style={{ fontWeight: '400', fontSize: '16px' }}>Do you want to continue with organization terms?</span>
                        <div className="buttons">
                            <button onClick={() => setAgree(true)}>Yes</button>
                            <button className='danger' onClick={() => navigate(-1)}>No</button>
                        </div>
                    </div>
                }
                {htmlContent && agree &&
                    <div className='terms-and-conditions'>
                        <h3 className='terms-heading'><img src={Terms} alt="" />Terms and conditions</h3>
                        {loading ? (
                            <Spin size="large" />
                        ) : (
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
                            </div>
                        )}
                    </div>
                }
                {htmlContent && agree &&
                    <div className="actions">
                        <button className="back-button" onClick={() => { navigate(-1) }}>Back </button>
                        <div className="action-items">
                            <button className='negotiate' onClick={handleNegotiateTerms}>
                                Negotiate
                            </button>
                            <button className='agree' style={{ marginLeft: 10, borderRadius: '6px' }} type="primary" onClick={() => { setCurrentStep(2) }}>
                                Agree Terms
                            </button>
                        </div>
                    </div>
                }

                <Modal
                    title="Negotiate Terms"
                    open={isNegotiating}
                    onCancel={() => setIsNegotiating(false)}
                    footer={null}>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleNegotiationSubmit}>
                        <Form.Item
                            label="Service Fee"
                            name="service_fee"
                            initialValue={termsData?.service_fee}
                            rules={[
                                { required: true, message: "Please enter service fee" },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Replacement Clause"
                            name="replacement_clause"
                            initialValue={termsData?.replacement_clause}
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter replacement clause",
                                },
                            ]}>
                            <Input.TextArea rows={4} />
                        </Form.Item>
                        <Form.Item
                            label="Invoice After"
                            name="invoice_after"
                            initialValue={termsData?.invoice_after}
                            rules={[
                                { required: true, message: "Please enter invoice after" },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Payment Within"
                            name="payment_within"
                            initialValue={termsData?.payment_within}
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter payment within",
                                },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Interest Percentage"
                            name="interest_percentage"
                            initialValue={termsData?.interest_percentage}
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter interest percentage",
                                },
                            ]}>
                            <Input />
                        </Form.Item>

                        <Button type="primary" htmlType="submit">
                            Submit Negotiated Terms
                        </Button>
                    </Form>
                </Modal>
            </div>
        </div>
    )
}

export default OrganizationTerms