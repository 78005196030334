import React, { useEffect, useState } from 'react';
import "./Compare.css";
import Main from "../Layout";
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../common/useAuth';
import { message, Modal, Input } from 'antd';
import JobDescription from "../../../../images/Client/CreateJob/Jobdescription.svg"
import Note from "../../../../images/Client/note.svg"
import Experience from "../../../../images/Client/Experience.svg"
import Notice from "../../../../images/Client/Vacancies.svg"
import Bag from "../../../../images/Client/Bag.svg"
import { CloseOutlined } from '@ant-design/icons';



const JobComponent = ({ job }) => {
    return (
        <div className="job-post-header">
            <div className="job-post-details">
                <div className="left-side">
                    <img src={JobDescription} alt="" />
                    <span className="job-details-heading">Job Details</span>
                </div>
            </div>
            <div className="ctc-details">
                <span className="ctc">
                    <img src={Note} alt="" /> {job.ctc}
                </span>
                <span className="job_title">
                    <img src={Bag} alt="" /> {job.job_title}
                </span>
            </div>
            <div className="job-description">
                <span>{job.job_description}</span>
            </div>
        </div>
    )
}

const Compare = () => {
    const { id } = useParams();
    const { apiurl, token } = useAuth();

    const [data, setData] = useState([]);
    const [jobData, setJobData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modalType, setModalType] = useState(""); // accept, reject, select
    const [currentApplicationId, setCurrentApplicationId] = useState(null);
    const [reason, setReason] = useState("")


    const fetchCompareData = async (applicationIds) => {
        try {
            const response = await fetch(`${apiurl}/client/compare-list-view/`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    jobid: id,
                    application_ids: applicationIds
                })
            });

            if (!response.ok) throw new Error("Failed to fetch comparison data!");

            const result = await response.json();
            setData(result.data);
            setJobData(result.job_data)
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const compareListRaw = sessionStorage.getItem('compareList');
        if (compareListRaw) {
            const compareList = JSON.parse(compareListRaw);
            const applicationIds = compareList[id]; // Get list specific to this job id

            if (applicationIds && applicationIds.length > 0) {
                fetchCompareData(applicationIds);
            } else {
                setLoading(false);
                setError("No applications selected for comparison.");
            }
        } else {
            setLoading(false);
            setError("No applications selected for comparison.");
        }
    }, [id]);

    const handleRemoveFromCompare = (applicationId, resumeId) => {
        const compareListRaw = sessionStorage.getItem('compareList');
        if (compareListRaw) {
            const compareList = JSON.parse(compareListRaw);
            const applicationIds = compareList[id] || [];

            const updatedApplicationIds = applicationIds.filter(appId => appId !== applicationId);

            compareList[id] = updatedApplicationIds;
            sessionStorage.setItem('compareList', JSON.stringify(compareList));

            console.log(compareList)

            setData(prevData => prevData.filter(item => item.resume_id !== resumeId));

            if (updatedApplicationIds.length === 0) {
                setError("No applications selected for comparison.");
            }
        }
    };


    const openModal = (type, applicationId) => {
        setModalType(type);
        setCurrentApplicationId(applicationId);
        setReason("");
    };

    const fields = [
        { label: 'Candidate Name', key: 'candidate_name' },
        { label: 'Sender', key: 'sender' },
        { label: 'Job Status', key: 'job_status' },
        { label: 'Current Organization', key: 'current_organization' },
        { label: 'Current Job Location', key: 'current_job_location' },
        { label: 'Current Job Type', key: 'current_job_type' },
        { label: 'Date of Birth', key: 'date_of_birth' },
        { label: 'Experience (yrs)', key: 'experience' },
        { label: 'Current CTC (LPA)', key: 'current_ctc' },
        { label: 'Expected CTC (LPA)', key: 'expected_ctc' },
        { label: 'Notice Period (days)', key: 'notice_period' },
        { label: 'Primary Skills', key: 'primary_skills' },
        { label: 'Secondary Skills', key: 'secondary_skills' },
        { label: 'Resume', key: 'resume' },
        { label: 'Actions', key: 'actions' } // This will be handled separately
    ];

    const handleModalSubmit = async () => {
        setLoading(true);
        let url = "";
        let body = {};
        console.log(currentApplicationId, " is the current application id")

        if (modalType === "accept") {
            url = `${apiurl}/client/accept-application/?id=${currentApplicationId}`;
            body = { acceptReason: reason, resume_id: currentApplicationId, round_num: 1 };
        } else if (modalType === "reject") {
            url = `${apiurl}/client/reject-application/?id=${currentApplicationId}`;
            body = { feedback: reason };
        } else if (modalType === "select") {
            url = `${apiurl}/client/select-application/?id=${currentApplicationId}`;
            body = { acceptReason: reason };
        }

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            });

            const resData = await response.json();
            if (!response.ok) {
                message.error(resData.error || "An error occurred.");
            } else {
                message.success(resData.message);
                window.location.reload();
            }
        } catch (e) {
            message.error(e.message);
        } finally {
            setLoading(false);
            setModalType("");
        }
    };


    return (
        <Main>
            <div className="compare-container">
                {jobData && <JobComponent job={jobData} />}
                <h2>Compare Applications</h2>
                {loading && <p>Loading applications...</p>}
                {error && <p style={{ color: 'red' }}>{error}</p>}

                {!loading && !error && data.length > 0 && (
                    <div className="compare-table-wrapper">
                        <table className="compare-table">
                            <tbody>

                                {fields.map((field, idx) => (
                                    <tr key={idx}>
                                        <th>{field.label}</th>
                                        {data.map((item, index) => (
                                            <td key={index}>
                                                {field.key === 'resume' ? (
                                                    item.resume ? <a href={item.resume} target="_blank" rel="noopener noreferrer">View Resume</a> : 'Not uploaded'
                                                ) : field.key === 'actions' ? (
                                                    item.status === 'pending' ? (
                                                        <div className="action-buttons">
                                                            {jobData && jobData.interviews === 0 ? (
                                                                <button onClick={() => openModal("select", item.resume_id)}>Direct Select</button>
                                                            ) : (
                                                                <button onClick={() => openModal("accept", item.resume_id)}>Shortlist</button>
                                                            )}
                                                            <button onClick={() => openModal("reject", item.resume_id)}>Reject</button>
                                                        </div>
                                                    ) : (
                                                        <span>{item.status}</span>
                                                    )
                                                ) :
                                                    field.key === 'primary_skills' ? (
                                                        item.primary_skills.length > 0 ? (
                                                            item.primary_skills.map((skill, skillIndex) => (
                                                                <span key={skillIndex} className="skill-tag">
                                                                    {skill.skill_name} ({skill.metric_value} {skill.skill_metric})
                                                                    {skillIndex !== item.primary_skills.length - 1 && ', '}
                                                                </span>
                                                            ))
                                                        ) : (
                                                            '-'
                                                        )
                                                    ) : field.key === 'secondary_skills' ? (
                                                        item.secondary_skills.length > 0 ? (
                                                            item.secondary_skills.map((skill, skillIndex) => (
                                                                <span key={skillIndex} className="skill-tag">
                                                                    {skill.skill_name} ({skill.metric_value} {skill.skill_metric})
                                                                    {skillIndex !== item.secondary_skills.length - 1 && ', '}
                                                                </span>
                                                            ))
                                                        ) : (
                                                            '-'
                                                        )
                                                    ) : (
                                                        item[field.key] !== null && item[field.key] !== undefined ? item[field.key] : '-'
                                                    )}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                <tr>
                                    <th> </th>
                                    {data.map((item, index) => (
                                        <td key={index} style={{ textAlign: 'center' }}>
                                            <button className="remove-compare-btn" style={{
                                                display: "flex",
                                                gap: "10px",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "25px"
                                            }} onClick={() => handleRemoveFromCompare(item.id, item.resume_id)}>
                                                <CloseOutlined></CloseOutlined> Remove From Compare list
                                            </button>
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
                }

                {
                    !loading && !error && data.length === 0 && (
                        <p>No data available for comparison.</p>
                    )
                }
                <Modal
                    title={
                        modalType === "accept" ? "Shortlist Candidate" :
                            modalType === "reject" ? "Reject Candidate" :
                                modalType === "select" ? "Direct Select Candidate" : ""
                    }
                    open={modalType !== ""}
                    onOk={handleModalSubmit}
                    onCancel={() => setModalType("")}
                    okText="Submit"
                >
                    <p>Please provide a reason:</p>
                    <Input.TextArea
                        rows={4}
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        placeholder="Enter reason here..."
                    />
                </Modal>
            </div >
        </Main >
    );
};

export default Compare;
