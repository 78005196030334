
import React from 'react';
import { FileTextOutlined, TeamOutlined, UserAddOutlined, PhoneOutlined, OrderedListOutlined, CheckOutlined } from '@ant-design/icons';
import jobposting from '../../../../../images/agency/summary/jobpostings.svg'


const RctrSummaryCard = ({ type, value, label }) => {
  const getCardStyles = () => {
    switch (type) {
      case 'opening':
        return {
          bgClass: 'bg-card-purple',
          textClass: 'text-card-purple-text',
          icon: <img src={jobposting} className="stat-icon" />
        };
      case 'positions':
        return {
          bgClass: 'bg-card-blue',
          textClass: 'text-card-blue-text',
          icon: <TeamOutlined className="stat-icon" style={{ color: '#1681FF' }} />
        };
      case 'candidates':
        return {
          bgClass: 'bg-card-pink',
          textClass: 'text-card-pink-text',
          icon: <UserAddOutlined className="stat-icon" />
        };
      case 'interview':
        return {
          bgClass: 'bg-card-blue',
          textClass: 'text-card-blue-text',
          icon: <PhoneOutlined className="stat-icon" style={{rotate:"90deg"}} />
        };
      case 'feedback':
        return {
          bgClass: 'bg-card-green',
          textClass: 'text-card-green-text',
          icon: <OrderedListOutlined className="stat-icon" style={{color:'#22CCB2'}} />
        };
      case 'hired':
        return {
          bgClass: 'bg-card-purple',
          icon: <CheckOutlined className="stat-icon"  />
        };
      default:
        return {
          bgClass: 'bg-card-blue',
          textClass: 'text-card-blue-text',
          icon: <CheckOutlined  className="stat-icon" style={{color:'#636AE8'}} />
        };
    }
  };

  const { bgClass, textClass, icon } = getCardStyles();

  return (
    <div className={`stat-card ${bgClass} animate-fade-in`}>
      <div className={`stat-card-content ${textClass}`}>
        <div className="stat-card-header">
          {icon}
          <h2 className="stat-value">{value}</h2>
        </div>
        <p className="stat-label">{label}</p>
      </div>
    </div>
  );
};

export default RctrSummaryCard;
