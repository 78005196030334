import React, { useEffect, useState } from 'react'
import Main from "../../Layout"
import "./CandidateClient.css"
import Profile from "../../../../../images/Client/profile.png"
import Location from "../../../../../images/Client/Location.svg"
import Bag from "../../../../../images/Client/Bag.svg"
import Envelope from "../../../../../images/Client/Envelope.svg"
import Resume from "../../../../../images/Client/TV.svg"
import Comments from "../../../../../images/Client/Comments.svg"
import { useAuth } from '../../../../common/useAuth'
import { PhoneOutlined, MailOutlined, BankOutlined, CalendarOutlined, FileProtectOutlined } from '@ant-design/icons'
import { message } from 'antd'
import { useParams } from 'react-router-dom'

const CandidateClient = () => {
    const { apiurl, token } = useAuth();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [data, setData] = useState(null);
    const fetchData = async () => {
        try {
            const response = await fetch(`${apiurl}/client/candidate/?application_id=${id}`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            const data = await response.json();
            if (data.error) {
                message.error(data.error);
            }
            else {
                setData(data)
            }
        }
        catch (e) {
            message.error(e);
        }
        finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        if (token) {
            fetchData();
        }
    }, [id])

    return (
        <Main defaultSelectedKey="4">
            <div className="candidate-details-client">
                <div className="main-heading">
                    <div className="details">Candidate Details</div>
                </div>
                {/* <div className="status-bar">
                    <div className="box">Applied</div>
                    <div className="box">UnderReview</div>
                    <div className="box">Interview</div>
                    <div className="box">Rejected</div>
                    <div className="box">Hired</div>
                </div> */}
                <div className="profile-section">
                    <div className="profile-pic">
                        <img src={Profile} alt="" />
                    </div>
                    <div className="name-details">
                        <div className="name">Ameer</div>
                        <div className="features">
                            <div className="feature phone"> <PhoneOutlined></PhoneOutlined>8008234424</div>
                            <div className="feature location" >  <img src={Location} alt="" />Hyderabad, india</div>
                            <div className="feature email"><MailOutlined></MailOutlined>sivakalkipusala6@gmail.com</div>
                        </div>
                    </div>
                </div>
                <div className="information">
                    <div className="left">
                        <span className="information-title">
                            Information
                        </span>
                        <div className="work-experience-details">
                            <div className="experience-title">
                                <img src={Bag} alt="" />Working experience <span className="value">4 yrs</span>
                            </div>
                            <div className="experiences">
                                <div className="experience">
                                    <div className="job-role">
                                        Senior Software Engineer  <span className="status">Working</span>
                                    </div>
                                    <div className="job-role-details">
                                        <span className="details type"><FileProtectOutlined /> Full Time</span>
                                        <span className="details company-name"><BankOutlined /> GA Digital Solutions</span>
                                        <span className="details working-from"><CalendarOutlined />Jan 2021 - present</span>
                                    </div>
                                </div>
                                <div className="experience">
                                    <div className="job-role">
                                        Senior Software Engineer  <span className="status">Working</span>
                                    </div>
                                    <div className="job-role-details">
                                        <span className="details type"><FileProtectOutlined /> Full Time</span>
                                        <span className="details company-name"><BankOutlined /> GA Digital Solutions</span>
                                        <span className="details working-from"><CalendarOutlined />Jan 2021 - present</span>
                                    </div>
                                </div>
                                <div className="experience">
                                    <div className="job-role">
                                        Senior Software Engineer  <span className="status">Working</span>
                                    </div>
                                    <div className="job-role-details">
                                        <span className="details type"><FileProtectOutlined /> Full Time</span>
                                        <span className="details company-name"><BankOutlined /> GA Digital Solutions</span>
                                        <span className="details working-from"><CalendarOutlined />Jan 2021 - present</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="education-details">
                            <div className="education-title">
                                <img src={Bag} alt="" />Education Details
                            </div>
                            <div className="educations">
                                <div className="education">
                                    <div className="job-role">
                                        SSC  <span className="status">Completed</span>
                                    </div>
                                    <div className="job-role-details">
                                        <span className="details type"><FileProtectOutlined /> 80 Percent</span>
                                        <span className="details company-name"><BankOutlined /> Narayana Junior College</span>
                                        <span className="details working-from"><CalendarOutlined />Jan 2021 - Jan 2025</span>
                                    </div>
                                </div>
                                <div className="education">
                                    <div className="job-role">
                                        SSC  <span className="status">Completed</span>
                                    </div>
                                    <div className="job-role-details">
                                        <span className="details type"><FileProtectOutlined /> 80 Percent</span>
                                        <span className="details company-name"><BankOutlined /> Narayana Junior College</span>
                                        <span className="details working-from"><CalendarOutlined />Jan 2021 - Jan 2025</span>
                                    </div>
                                </div>
                                <div className="education">
                                    <div className="job-role">
                                        SSC  <span className="status">Completed</span>
                                    </div>
                                    <div className="job-role-details">
                                        <span className="details type"><FileProtectOutlined /> 80 Percent</span>
                                        <span className="details company-name"><BankOutlined /> Narayana Junior College</span>
                                        <span className="details working-from"><CalendarOutlined />Jan 2021 - Jan 2025</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* <div className="cover-letter">
                            <span className="cover-letter-head">
                                <img src={Envelope} alt="" />Cover letter
                            </span>
                            <span className="cover-letter-text">
                                Dear hiring Manager
                                afdsfasf asfasjf;l jaf jaslj fakjsfasj fl alajflas fsjalf slafjlkafjioafsm asfjnaehnef an,cms ga;eh;aeifhainfda
                            </span>
                        </div> */}
                        <div className="resume">
                            <span className="resume-head">
                                <img src={Resume} alt="" />Resume
                            </span>
                            <div className='resume-value'>Resume.pdf</div>
                        </div>
                        <div className="comments">
                            <span className="comments-head">
                                <img src={Comments} alt="" />Comments
                            </span>
                            <div className="comment">
                                <span className="interviewer-name">Interviewer-1</span>
                                <span className="txt">This is the review given by me , which is the candidate performance is so good and have nice communication skills</span>
                            </div>
                            <div className="comment">
                                <span className="interviewer-name">Interviewer-1</span>
                                <span className="txt">This is the review given by me , which is the candidate performance is so good and have nice communication skills</span>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </Main >
    )
}

export default CandidateClient