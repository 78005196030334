"use client"
import { useState, useEffect } from "react"
import { Button, Typography } from "antd"
import { LeftOutlined, RightOutlined, ClockCircleOutlined, VideoCameraOutlined } from "@ant-design/icons"
import "./calendar.css"

const { Title, Text } = Typography

const Calendar = ({ events }) => {
    const [currentDate, setCurrentDate] = useState(new Date())
    const [currentTime, setCurrentTime] = useState(new Date())

    // Update current time every minute
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date())
        }, 60000)

        return () => {
            clearInterval(timer)
        }
    }, [])

    // Navigate to previous day
    const goToPreviousDay = () => {
        const newDate = new Date(currentDate)
        newDate.setDate(newDate.getDate() - 1)
        setCurrentDate(newDate)
    }

    // Navigate to next day
    const goToNextDay = () => {
        const newDate = new Date(currentDate)
        newDate.setDate(newDate.getDate() + 1)
        setCurrentDate(newDate)
    }

    // Format date as DD-MM-YYYY
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, "0")
        const month = String(date.getMonth() + 1).padStart(2, "0")
        const year = date.getFullYear()
        return `${day}-${month}-${year}`
    }

    // Convert time string to minutes since start of day
    const timeToMinutes = (timeStr) => {
        const [time, period] = timeStr.split(" ")
        let [hours, minutes] = time.split(":").map(Number)

        if (period === "PM" && hours !== 12) {
            hours += 12
        } else if (period === "AM" && hours === 12) {
            hours = 0
        }

        return hours * 60 + minutes
    }

    // Calculate position for current time indicator
    const getCurrentTimePosition = () => {
        const hours = currentTime.getHours()
        const minutes = currentTime.getMinutes()
        const totalMinutes = hours * 60 + minutes
        console.log(hours, minutes, totalMinutes, "is the total minutes")

        // Start time (9 AM) in minutes
        const startTime = 9 * 60
        // End time (5 PM) in minutes
        const endTime = 18 * 60

        // If current time is outside the calendar range, don't show the indicator
        if (totalMinutes < startTime || totalMinutes > endTime) {
            return -1
        }

        // Calculate position as percentage
        console.log(((totalMinutes - startTime) / (endTime - startTime)) * 100)
        return ((totalMinutes - startTime) / (endTime - startTime)) * 100
    }

    // Calculate position and height for event cards
    const getEventStyle = (event) => {
        const startMinutes = timeToMinutes(event.startTime)
        const endMinutes = timeToMinutes(event.endTime)

        // Start time (9 AM) in minutes
        const startTime = 9 * 60
        // End time (5 PM) in minutes
        const endTime = 18 * 60

        const top = ((startMinutes - startTime) / (endTime - startTime)) * 100
        const height = ((endMinutes - startMinutes) / (endTime - startTime)) * 100

        return {
            top: `${top}%`,
            height: `${height}%`,
        }
    }

    // Get background color based on event type
    const getEventBackground = (type) => {
        switch (type) {
            case "success":
                return "event-success"
            case "processing":
                return "event-processing"
            case "warning":
                return "event-warning"
            default:
                return "event-default"
        }
    }

    // Generate time labels for the calendar
    const timeLabels = []
    for (let hour = 9; hour <= 17; hour++) {
        const displayHour = hour > 12 ? hour - 12 : hour
        const period = hour >= 12 ? "PM" : "AM"
        timeLabels.push(`${displayHour}:00 ${period}`)
    }

    const currentTimePosition = getCurrentTimePosition()

    return (
        <div className="calendar-container">
            <div className="calendar-header">
                <Title level={4} style={{ margin: 0 }}>
                    Today Interviews
                </Title>
                <div className="calendar-navigation">
                    {/* <Button type="text" icon={<LeftOutlined />} onClick={goToPreviousDay} className="nav-button" /> */}
                    <div className="calendar-date">
                        <Text>{formatDate(currentDate)}</Text>
                    </div>
                    {/* <Button type="text" icon={<RightOutlined />} onClick={goToNextDay} className="nav-button" /> */}
                </div>
            </div>

            <div className="calendar-body">
                <div className="time-column">
                    {timeLabels.map((time, index) => (
                        <div key={index} className="time-slot">
                            <span className="time-label">{time}</span>
                        </div>
                    ))}
                </div>

                <div className="events-container">
                    {/* Horizontal grid lines */}
                    {timeLabels.map((_, index) => (
                        <div key={index} className="grid-line"></div>
                    ))}

                    {/* Current time indicator */}
                    {currentTimePosition >= 0 && (
                        <div className="current-time-indicator" style={{ top: `${currentTimePosition}%` }}></div>
                    )}

                    {/* Event cards */}
                    {events && events.map((event) => (
                        <div key={event.id} className={`event-card ${getEventBackground(event.type)}`} style={getEventStyle(event)}>
                            <div className="event-icon">
                                <VideoCameraOutlined />
                            </div>
                            <div className="event-content">
                                <div className="event-title">{event.title}</div>
                                <div className="event-time">
                                    <ClockCircleOutlined /> {event.startTime} - {event.endTime}
                                </div>
                            </div>
                            <div className="event-actions">
                                <Button type="primary" className="join-button">
                                    Join Meet
                                </Button>
                                <Button className="reschedule-button">Reschedule</Button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Calendar

