import React from 'react'
import MainLayout from '../../common/Layout/MainLayout'
import dashboard from "./../../../images/Dashboard.svg"
import events from "./../../../images/Events.svg"

const Layout = ({ children, defaultSelectedKey }) => {
	const cilentoptions = [
		{
			"key": 1,
			"label": "Dashboard",
			"logo": dashboard,
			"path": '/'
		},
		{
			"key": 2,
			"label": "Tickets",
			"logo": events,
			"path": '/admin/tickets'
		},
	]

	return (
		<>
			<MainLayout children={children} defaultSelectedKey={defaultSelectedKey} options={cilentoptions}></MainLayout>
		</>
	)
}

export default Layout