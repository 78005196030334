import React, { createContext, useEffect, useState } from "react";
import Loader from "../Loader/Loader";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [token, setToken] = useState(null);
	const [userData, setUserData] = useState({ "username": " ", "user_id": "", "role": "" })

	// const apiurl = 'https://backend.gahiresync.com';
	// const apiurl = ' http://localhost:8001'


	const apiurl = process.env.REACT_APP_BACKEND_URL;



	useEffect(() => {
		const storedToken = localStorage.getItem("hiresynctoken");
		if (storedToken) {
			setToken(storedToken);
		} else {
			const sessionToken = sessionStorage.getItem("hiresynctoken");
			if (sessionToken) {
				setToken(sessionToken);
			}
		}
		let formattedUserData = localStorage.getItem("hireSyncUser");
		if (formattedUserData) {
			setUserData(formattedUserData);
		}
		else {
			formattedUserData = sessionStorage.getItem("hireSyncUser");
			setUserData(formattedUserData)
		}
		setIsLoading(false);
	}, []);

	const handleLogin = (token, userData = null) => {
		setToken(token);
		console.log(userData, "is the user data")

		if (userData !== null) {
			const formattedUserData = {
				username: userData.username,
				role: userData.role,
				user_id: userData.id,
			};
			console.log("entered, added user data to the token", formattedUserData)
			setUserData(formattedUserData);
			localStorage.setItem("hireSyncUser", JSON.stringify(formattedUserData));
		}

		localStorage.setItem("hiresynctoken", token);
	};


	const handleSessionLogin = (token, userData = null) => {
		setToken(token);

		if (userData !== null) {
			const formattedUserData = {
				username: userData.username,
				role: userData.role,
				user_id: userData.id,
			};
			setUserData(formattedUserData);
			sessionStorage.setItem("hireSyncUser", JSON.stringify(formattedUserData));
		}

		sessionStorage.setItem("hiresynctoken", token);
	};

	const handleLogout = () => {
		setToken(null);
		setUserData({ username: "", role: "", user_id: "" });
		localStorage.removeItem("hiresynctoken");
		localStorage.removeItem('hireSyncUser');
		sessionStorage.removeItem('hireSyncUser');
		sessionStorage.removeItem("hiresynctoken");
	};

	if (isLoading) {
		return <Loader></Loader>;
	}

	return (
		<UserContext.Provider
			value={{
				handleLogin,
				handleSessionLogin,
				handleLogout,
				token,
				apiurl,
				userData,
			}}>
			{children}
		</UserContext.Provider>
	);
};