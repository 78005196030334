import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Input, InputNumber, Select, Upload, message, DatePicker } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import "./CalendarManage.css";
import "./UploadResume.css"
import { useAuth } from "../../../common/useAuth";
import PrimarySkillsForm from "./PrimarySkills";
import SecondarySkillsForm from "./SecondarySkills";
import dayjs from "dayjs";
const { Option } = Select;
const apiurl = process.env.REACT_APP_BACKEND_URL;

const UploadData = ({ primary_skills, secondary_skills, draggedId }) => {
	const { token } = useAuth();
	const [candidates, setCandidates] = useState([]);
	const navigate = useNavigate();
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [useResumeBank, setUseResumeBank] = useState(null);
	const [resumes, setResumes] = useState([]);
	const [error, setError] = useState("");
	const [jobStatus, setJobStatus] = useState("");
	const [applicationData, setApplicationData] = useState()
	const [form] = Form.useForm();

	const fetchResumesFromBank = async () => {
		try {
			const response = await fetch(`${apiurl}/api/recruiter/get_resume_bank/`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (!response.ok) {
				console.error("There is an error");
				alert("Error fetching resume data");
			} else {
				const data = await response.json();
				setResumes(data.data);
				console.log(data);
			}
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		if (draggedId) {
			handleLocalInput();
			fetchApplicationDetails(draggedId);
		}
	}, [draggedId])

	const fetchApplicationDetails = async (draggedId) => {
		try {
			console.log(draggedId)
			const response = await fetch(`${apiurl}/recruiter/organization-applications/?application_id=${draggedId}`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`
				}
			})
			const data = await response.json()
			if (data.error) {
				message.error(data.error)
			}
			else {
				setApplicationData(data)
				console.log(data)
			}
		}
		catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		if (useResumeBank) {
			fetchResumesFromBank();
		}
	}, [useResumeBank]);

	useEffect(() => {
		if (applicationData && Object.keys(applicationData).length > 0) {
			console.log("entered here", applicationData.candidate_name)
			form.setFieldsValue({
				...applicationData,
				date_of_birth: applicationData.date_of_birth ? dayjs(applicationData.date_of_birth) : null,
				experience: applicationData.experience || 20,
				current_ctc: applicationData.current_ctc || 0,
				expected_ctc: applicationData.expected_ctc || 0,
				resume: applicationData.resume
					? [{ uid: "-1", name: `${applicationData.candidate_name}.pdf`, url: `${apiurl}/${applicationData.resume}` }]
					: [],
			});
			console.log("entered here",)
			setJobStatus(applicationData.job_status || ""); // Ensure job status is updated
		}
	}, [applicationData]);

	const handleResumeBank = () => {
		setUseResumeBank(true);
	};

	const handleLocalInput = () => {
		setUseResumeBank(false);
	};

	const handleResumeSelection = (resume) => {
		console.log("Selected resume:", resume);

		const updatedCandidates = candidates.map((candidate, index) => {
			if (index === 0) {
				return {
					...candidate,
					candidate_name: `${resume.first_name} ${resume.middle_name} ${resume.last_name}`,
					candidate_email: resume.email,
					current_organisation: resume.current_organisation,
					job_status: resume.job_status,
					current_job_type: resume.current_job_type,
					current_job_location: resume.current_job_location,
					date_of_birth: resume.date_of_birth,
					total_years_of_experience: resume.total_years_of_experience,
					current_ctc: resume.current_ctc,
					expected_ctc: resume.expected_ctc,
					notice_period: resume.notice_period,
					joining_days_required: resume.joining_days_required,
					highest_qualification: resume.highest_qualification,
					contact_number: resume.contact_number,
					alternate_contact_number: resume.alternate_contact_number,
					resume: resume.resume,
					resumeFileList: [
						{
							uid: "-1",
							name: "resume.pdf",
							status: "done",
							url: resume.resume,
						},
					],
				};
			}
			return candidate;
		});

		setCandidates(updatedCandidates);
		setUseResumeBank(false);
	};

	const handleFileChange = (index, fileList) => {
		const updatedCandidates = candidates.map((candidate, i) =>
			i === index
				? {
					...candidate,
					resumeFileList: fileList,
					resume: fileList[0]?.originFileObj,
				}
				: candidate
		);
		setCandidates(updatedCandidates);
	};


	const isValidContact = (number) => {
		number = number.trim();
		number = String(number);
		const pattern = /^[0-9]{10}$/;
		return pattern.test(number);
	};

	const formatDate = (date) => {
		const d = new Date(date);
		const year = d.getFullYear();
		const month = (d.getMonth() + 1).toString().padStart(2, '0');
		const day = d.getDate().toString().padStart(2, '0');
		return `${year}-${month}-${day}`;
	}

	const handleSubmit = async (values) => {
		try {
			setLoading(true);
			const formattedDateOfBirth = formatDate(values.date_of_birth)
			const formData = new FormData();

			Object.keys(values).forEach(key => {
				formData.append(key, values[key]);
			});

			console.log(values.resume)

			if (values.resume && values.resume.length > 0) {
				const file = values.resume[0];

				if (file.originFileObj) {
					// Append only the newly uploaded file
					formData.append("resume", file.originFileObj);
				} else {
					message.error("Please upload a new resume from your device.");
					return;
				}
			} else {
				message.error("Resume file is required.");
				return;
			}

			const primarySkillsArray = values.primary_skills
				? values.primary_skills.map(item => [
					item.skill_name || "",
					item.metric_type || "",
					item.metric_type === "rating" ? item.rating || null : null,
					{ is_primary: true },
					item.metric_type === "experience" ? item.experience || "" : "",
				])
				: [];


			const secondarySkillsArray = values.secondary_skills
				? values.secondary_skills.map(item => [
					item.skill_name || "",
					item.metric_type || "",
					item.metric_type === "rating" ? item.rating || null : null,
					{ is_primary: false },
					item.metric_type === "experience" ? item.experience || "" : "",
				])
				: [];

			// Append all form fields to FormData
			Object.keys(values).forEach(key => {
				if (key === 'primary_skills') {
					formData.append(key, JSON.stringify(primarySkillsArray)); // Store as a JSON string
				} else if (key === 'secondary_skills') {
					formData.append(key, JSON.stringify(secondarySkillsArray)); // Store as a JSON string
				} else if (key === 'date_of_birth') {
					formData.append(key, formattedDateOfBirth);  // Add only the properly formatted date
				} else {
					formData.append(key, values[key]);
				}
			});


			const response = await fetch(`${apiurl}/recruiter/create-candidate/?id=${id}`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formData,  // Send the FormData as the body
			});

			if (!response.ok) {
				throw new Error("Failed to add candidate.");
			}

			const result = await response.json();
			message.success("Candidate added successfully!");
		} catch (error) {
			console.error("Error submitting candidate:", error);
			message.error("Failed to add candidate. Please try again.");
		} finally {
			setLoading(false);
		}
	};
	return (
		<div>
			{message && message.success &&
				<div className="success">{message.success}
				</div>}
			{message && message.error &&
				<div className="error">{message.error}
				</div>}
			{useResumeBank === null ? (
				<div>
					<p>Do you want to upload from Resume Bank?</p>
					<Button onClick={handleResumeBank}>Yes</Button>
					<Button onClick={handleLocalInput}>No</Button>
				</div>
			) : useResumeBank ? (
				<div>
					<h2>Select a Resume from the Resume Bank</h2>
					{resumes.length > 0 ? (
						resumes.map((resume) => (
							<div key={resume.id}>
								<p>
									{resume.first_name} {resume.middle_name} {resume.last_name}
								</p>
								{resume.freeze === false && (
									<Button onClick={() => handleResumeSelection(resume)}>
										Select
									</Button>
								)}
								{resume.freeze === true && (
									<Button disabled={true}>Select</Button>
								)}
							</div>
						))
					) : (
						<p>No resumes available.</p>
					)}
				</div>
			) : (
				<div>
					<h1>Add Candidate</h1>
					{error && <p style={{ color: "red" }}>{error}</p>}
					<Form form={form} layout="vertical" className="rb-form" onFinish={handleSubmit}>
						<div >
							<Form.Item label="Candidate Name" name='candidate_name'
								rules={[
									{ required: true, message: "Candidate name is required" },
									{ max: 150, message: "Candidate name cannot exceed 150 characters" },
									{
										pattern: /^[\w.@+-]+$/,
										message: "Candidate name can only contain letters, digits, and @/./+/-/_"
									}
								]}
							>
								<Input />
							</Form.Item>

							<Form.Item label="Candidate Email" name='candidate_email'
								rules={[{ required: true, message: "Candidate email is required" }]} >
								<Input />
							</Form.Item>

							<h2>Primary Skills</h2>
							<PrimarySkillsForm primarySkills={primary_skills}></PrimarySkillsForm>

							<h2>Secondary Skills</h2>
							<SecondarySkillsForm secondarySkills={secondary_skills}></SecondarySkillsForm>

							<Form.Item label="Job Status" name="job_status"
								rules={[{ required: true, message: "Current job status is required" }]} >
								<Select placeholder='Select your job status' onChange={(value) => {
									setJobStatus(value)
								}}>
									<Option value="available">Available</Option>
									<Option value="not_available">Not Available</Option>
								</Select>
							</Form.Item>

							{jobStatus === 'available' &&
								<Form.Item label="Current Organisation" name='current_organization'
									rules={[{ required: true, message: "Current Organization is required" }]} >
									<Input placeholder="If no organization type N/A" />
								</Form.Item>
							}

							{jobStatus === 'available' &&
								<Form.Item label="Current Job Type" name="current_job_type"
									rules={[{ required: true, message: "Current job type is required" }]} >
									<Select>
										<Option value="permanent">Permanent</Option>
										<Option value="contract">Contract</Option>
									</Select>
								</Form.Item>
							}

							{jobStatus === 'available' &&
								<Form.Item label="Current Job Location" name="current_job_location"
									rules={[{ required: true, message: "current job location is required" }]} >
									<Input />
								</Form.Item>
							}

							<Form.Item label="Date of Birth" name="date_of_birth"
								rules={[{ required: true, message: "Date of birth is required" }]}
								initialValue={dayjs("2000-01-01")}
							>
								<DatePicker
									defaultValue={dayjs("2000-01-01")}
									disabledDate={(current) => {
										return current && current > dayjs().subtract(10, "year");
									}}
								/>
							</Form.Item>

							<Form.Item label="Total Years of Experience" name="experience"
								rules={[{ required: true, message: "years of experience is required" }]}>
								<InputNumber
									placeholder="years"
									min={0}
								/>
							</Form.Item>

							<Form.Item label="Other details" name="other_details"
							>
								<Input />
							</Form.Item>

							<Form.Item label="Current CTC" name="current_ctc"
								rules={[{ required: true, message: "Current CTC is required" }]}>
								<InputNumber
									precision={3}
									placeholder="CTC"
									min={0}
								/>
							</Form.Item>

							<Form.Item label="Expected CTC" name="expected_ctc"
								rules={[{ required: true, message: "Expected CTC is required" }]}>
								<InputNumber
									precision={3}
									placeholder="CTC"
									min={0}
								/>
							</Form.Item>

							<Form.Item label="Notice Period" name="notice_period"
								rules={[{ required: true, message: "Notice Period is required" }]}>
								<InputNumber min={0} placeholder="give notice period in days" />
							</Form.Item>

							<Form.Item label="Joining Days Required" name="joining_days_required"
								rules={[{ required: true, message: "Number of Joining Days is required" }]}>
								<InputNumber min={0} />
							</Form.Item>

							<Form.Item label="Highest Qualification" name='highest_qualification'
								rules={[{ required: true, message: "Highest Qualification is required" }]}>
								<Input />
							</Form.Item>

							<Form.Item label="Contact Number" name="contact_number"
								rules={[{ required: true, message: "Contact Number is required" }]}>
								<Input />
							</Form.Item>

							<Form.Item label="Alternate Contact Number" name='alternate_contact_number'>
								<Input />
							</Form.Item>

							<Form.Item
								label="Resume"
								name="resume"
								rules={[{ required: true, message: "Please upload a resume" }]}
							>
								<Upload
									fileList={form.getFieldValue("resume")}
									beforeUpload={() => false} // Prevent automatic upload
									accept=".pdf,.doc,.docx"
									onChange={({ fileList }) => {
										// Only allow new file from local device, remove any previous file
										if (fileList.length > 1) fileList = [fileList[fileList.length - 1]];
										// Ensure it's a newly selected file (must have originFileObj)
										const newFileList = fileList.filter(file => file.originFileObj);
										form.setFieldsValue({ resume: newFileList });
									}}
								>
									<Button icon={<UploadOutlined />}>Upload Resume</Button>
								</Upload>

							</Form.Item>
						</div>
						<Button type="primary" htmlType="submit" loading={loading}>
							Submit
						</Button>
					</Form>
				</div>
			)}
		</div>
	);
};

// const createEmptyCandidate = (
// 	jobId = "",
// 	skillset = { primary_skills: {}, secondary_skills: {} }
// ) => ({
// 	job_id: jobId,
// 	candidate_name: "",
// 	candidate_email: "",
// 	current_organisation: "",
// 	job_status: "",
// 	current_job_type: "",
// 	current_job_location: "",
// 	date_of_birth: "",
// 	total_years_of_experience: "",
// 	skillset: skillset,
// 	current_ctc: "",
// 	expected_ctc: "",
// 	notice_period: "",
// 	joining_days_required: "",
// 	highest_qualification: "",
// 	contact_number: "",
// 	alternate_contact_number: "",
// 	resume: null,
// 	resumeFileList: [],
// });

export default UploadData;
