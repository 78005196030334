import React, { useState, useEffect } from 'react'
import TopNav from './TopNav'
import BG from "../../images/LandingPage/BG2.png"
import ourStory from "../../images/LandingPage/aboutus1.png"
import Target from "../../images/LandingPage/target.svg"
import Idea from "../../images/LandingPage/idea.svg"
import "./AboutUs.css"
import feature1 from "../../images/LandingPage/feature1.png"
import feature2 from "../../images/LandingPage/feature2.png"
import feature3 from "../../images/LandingPage/feature3.png"
import feature4 from "../../images/LandingPage/feature4.png"
import Pluginn from "../../images/LandingPage/plugin.svg"
import Person from "../../images/LandingPage/team.png"
import Footer from './Footer'

import { motion, AnimatePresence } from "framer-motion"
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

const TeamCarousel = ({ teamMembers }) => {
    const [activeIndex, setActiveIndex] = useState(0)
    const [direction, setDirection] = useState(0)

    const handlePrev = () => {
        setDirection(-1)
        setActiveIndex((prevIndex) => (prevIndex === 0 ? teamMembers.length - 1 : prevIndex - 1))
    }

    const handleNext = () => {
        setDirection(1)
        setActiveIndex((prevIndex) => (prevIndex === teamMembers.length - 1 ? 0 : prevIndex + 1))
    }

    const getVisibleMembers = () => {
        const result = []
        const lastIndex = teamMembers.length - 1

        // Previous member
        const prevIndex = activeIndex === 0 ? lastIndex : activeIndex - 1
        result.push({ member: teamMembers[prevIndex], position: "prev", index: prevIndex })

        // Active member
        result.push({ member: teamMembers[activeIndex], position: "active", index: activeIndex })

        // Next member
        const nextIndex = activeIndex === lastIndex ? 0 : activeIndex + 1
        result.push({ member: teamMembers[nextIndex], position: "next", index: nextIndex })

        return result
    }

    const variants = {
        prev: {
            x: -250,
            scale: 0.8,
            zIndex: 1,
            filter: "grayscale(100%) brightness(70%)",
            transition: { type: "tween", ease: "easeInOut", duration: 0.5 },
        },
        active: {
            x: 0,
            scale: 1,
            zIndex: 3,
            filter: "grayscale(0%) brightness(100%)",
            transition: { type: "tween", ease: "easeInOut", duration: 0.5 },
        },
        next: {
            x: 250,
            scale: 0.8,
            zIndex: 1,
            filter: "grayscale(100%) brightness(70%)",
            transition: { type: "tween", ease: "easeInOut", duration: 0.5 },
        },
    }

    return (
        <div className="team-carousel">
            <div className="carousel-container">
                {getVisibleMembers().map((item) => (
                    <motion.div
                        key={item.index}
                        className="team-member"
                        initial={direction > 0 ? "next" : direction < 0 ? "prev" : "active"}
                        animate={item.position}
                        variants={variants}
                    >
                        <div className="member-image-container">
                            <img src={item.member.image || "/placeholder.svg"} alt={item.member.name} className="member-image" />
                            <AnimatePresence>
                                {item.position === "active" && (
                                    <motion.div
                                        className="member-info"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: 20 }}
                                        transition={{ delay: 0.2, duration: 0.3 }}
                                    >
                                        <h3>{item.member.name}</h3>
                                        <p>{item.member.role}</p>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                    </motion.div>
                ))}
            </div>

            <div className="carousel-controls">
                <motion.button
                    className="nav-button prev"
                    onClick={handlePrev}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <LeftOutlined size={24} style={{ fontSize: '24px' }} />
                </motion.button>
                <motion.button
                    className="nav-button next"
                    onClick={handleNext}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <RightOutlined size={24} style={{ fontSize: '24px' }} />
                </motion.button>
            </div>
        </div>
    )
}




const AboutUs = () => {

    const [activeIndex, setActiveIndex] = useState(0);

    const nextSlide = () => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % team.length);
    };

    const prevSlide = () => {
        setActiveIndex((prevIndex) => (prevIndex - 1 + team.length) % team.length);
    };

    const team = [
        {
            "image": Person,
            "name": "Satyanarayana",
            "role": "Developer"
        },
        {
            "image": Person,
            "name": "Venkat Gunnam",
            "role": "GA DIGITAL SOLUTIONS CEO"
        },
        {
            "image": Person,
            "name": "RAJINI",
            "role": "HR MANAGER"
        },
        {
            "image": Person,
            "name": "Arjun",
            "role": "Designer"
        },
        {
            "image": Person,
            "name": "Ameer",
            "role": "Developer"
        },
        {
            "image": Person,
            "name": "Jay Tej",
            "role": "Designer"
        },
    ]

    return (
        <div className='about-us'>
            <div className="section1"
                style={{ backgroundImage: `url(${BG})`, backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '0px 0px 0px 0px' }}
            >
                <TopNav color="blue"></TopNav>
                <div className="line1">
                    About Us
                </div>
                <div className="line2">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quia, placeat?
                </div>
            </div>
            <div className="our-story">
                <img src={ourStory} alt="" />
                <div className="content">
                    <span className="name">Our Story</span>
                    <span className="text">
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat a fugit repellat enim natus ipsum quis ipsa, molestias nostrum earum unde nesciunt sit, eos quia esse atque incidunt? Illum illo odio eligendi odit qui! Sunt eligendi fugit eaque laboriosam quo sit eius libero amet ad dicta sint quidem hic suscipit inventore voluptatum tempore rem voluptas eveniet alias deserunt, harum natus illum? Iure sit magnam id consequatur sed saepe, ipsam ducimus dicta dolorum, officiis aliquid accusamus ex distinctio enim facilis nulla. Itaque expedita unde maiores, explicabo fuga ipsum neque! Dignissimos, repudiandae ab veniam quia sequi doloribus sit qui harum esse corporis! Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi pariatur iusto accusantium! Placeat, alias, iste officiis maxime reiciendis, mollitia doloremque unde labore voluptatem quam tempore dolor! Dolore nulla blanditiis omnis. Nihil iure eaque nisi quas sed rerum pariatur aut, explicabo mollitia quod soluta facere tempora nobis ipsam repudiandae nesciunt laborum molestiae veniam sapiente rem quibusdam! Officia at quo enim sapiente facilis excepturi, distinctio totam asperiores, nesciunt assumenda eaque aspernatur voluptatibus aliquid iure quod, expedita maiores cum. Quis modi eveniet exercitationem veritatis doloribus voluptas, voluptatum minus, rerum quod aliquam, nemo blanditiis ipsa velit nisi? Dignissimos magnam illum modi corrupti a reiciendis, quod, beatae cumque voluptatem cum dolores cupiditate, amet natus debitis molestias reprehenderit totam neque sunt ullam quia quis nisi. Porro dicta quis consequatur? Similique, quod voluptatibus doloribus placeat nam aperiam dolorum blanditiis dicta vero. Eligendi consequuntur laudantium voluptates repellat accusantium sunt aperiam, dolorem ipsam reiciendis commodi quae, odit quaerat incidunt!
                    </span>
                </div>
            </div>
            <div className="mission-vision">
                <div className="mission">
                    <span className="name">
                        <img src={Target} alt="" />
                        Our Mission
                    </span>
                    <span className="text">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam nulla, assumenda, repudiandae ullam rerum id aliquid quisquam similique quasi magnam dicta. Hic ducimus repellat nihil consequuntur ut earum ipsa, optio omnis cum facilis odit a dignissimos illum consectetur id tenetur perferendis, suscipit officiis, fugit nostrum perspiciatis quod deleniti! Beatae, dolore.
                    </span>
                </div>
                <div className="vision">
                    <span className="name">
                        <img src={Idea} alt="" />
                        Our Vision
                    </span>

                    <span className="text">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam nulla, assumenda, repudiandae ullam rerum id aliquid quisquam similique quasi magnam dicta. Hic ducimus repellat nihil consequuntur ut earum ipsa, optio omnis cum facilis odit a dignissimos illum consectetur id tenetur perferendis, suscipit officiis, fugit nostrum perspiciatis quod deleniti! Beatae, dolore.
                    </span>
                </div>
            </div>
            <div className="section2" style={{ backgroundImage: `url(${BG})`, backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '0px 0px 0px 0px' }}>
                <div className="matter">
                    You can do it all with <span className="fifty">Hiresync</span>
                </div>
                <div className="features">
                    <div className="features-1">
                        <img src={feature1} alt="" />
                        <img src={feature2} alt="" />
                    </div>
                    <img src={feature3} alt="" />
                    <img src={feature4} alt="" />
                </div>
                <div className="subscribers">
                    <div className="counter">
                        <span className="count">5,000+</span>
                        <span className="about">Loved by 5000+ Team members</span>
                    </div>
                    <div className="counter">
                        <span className="count">25,000+</span>
                        <span className="about">Trusted by 25000+ Customers</span>
                    </div>
                    <div className="counter">
                        <span className="count">48 hrs</span>
                        <span className="about">Onboard talent in as little as 48 hours</span>
                    </div>
                </div>
            </div>
            <div className="section3">
                <div className="name">
                    Meet the <span className="pink">Minds</span> Behind the Innovation
                </div>
                <div className="text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt, commodi. Lorem ipsum dolor sit amet. </div>
                <div className="team">
                    <TeamCarousel teamMembers={team} />
                </div>

            </div>
            <div className="section4" style={{ backgroundImage: `url(${BG})`, backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '0px 0px 0px 0px' }}>
                <div className="name">
                    <span className="pink">Customer Centric</span> Approach
                </div>
                <div className="text">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit, tempore.</div>
                <div className="itemss">
                    <div className="item">
                        <img src={Pluginn} alt="" />
                        <span className="name">Plugin Name here</span>
                        <span className="description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa delectus, quisquam eos enim aspernatur illo esse nesciunt unde animi nam.</span>
                    </div>
                    <div className="item">
                        <img src={Pluginn} alt="" />
                        <span className="name">Plugin Name </span>
                        <span className="description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa delectus, quisquam eos enim aspernatur illo esse nesciunt unde animi nam.</span>
                    </div>
                    <div className="item">
                        <img src={Pluginn} alt="" />
                        <span className="name">Plugin Name here</span>
                        <span className="description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa delectus, quisquam eos enim aspernatur illo esse nesciunt unde animi nam.</span>
                    </div>
                    <div className="item">
                        <img src={Pluginn} alt="" />
                        <span className="name">Plugin Name here</span>
                        <span className="description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa delectus, quisquam eos enim aspernatur illo esse nesciunt unde animi nam.</span>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default AboutUs