import React, { useState, useEffect } from 'react';
import './RecTaskTracking.css'
import File from '../../../../images/agency/rec-dashboard/File.svg'
import Completed from '../../../../images/agency/rec-dashboard/Completed.svg'
import Incomplete from '../../../../images/agency/rec-dashboard/Incomplete.svg'
import PlayButton from '../../../../images/agency/rec-dashboard/playbutton.svg'
import Date from '../../../../images/agency/rec-dashboard/Date.svg'
import { FilterOutlined, PlusCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useAuth } from '../../../common/useAuth';


const Row = ({ job }) => {
    return (
        <div className="row">
            <div className="" style={{ fontWeight: '500', cursor: 'pointer' }}>{job["job_title"]}</div>
            <div className="num-of-positions">{job['num_of_positions']}</div>
            <div className={`priority ${job['priority']}`}>
                {job["priority"]}</div>
            <div className="last-date">
                <img src={Date} alt="" />
                {job["due_date"]}
            </div>
            <div className="status">
                <span className={`${job['status'] < 25 ? "lowbar" : job['status'] < 75 ? "mediumbar" : "highbar"} bar`}>
                    <span className={`${job['status'] < 25 ? "low" : job['status'] < 75 ? "medium" : "high"} barinside`} style={{ width: `${job['status']}%` }}></span>
                </span>
                {job["status"]}%
            </div>
            <div className="circle-color" style={{ cursor: 'pointer' }}>
                {
                    job['recruiters']?.map((item, index) => (
                        <Tooltip title={item}>
                            {item?.profile ? (
                                <img src={job["profile"]} alt="" style={{ width: '20px', height: '20px', borderRadius: '50%' }} />
                            ) : (
                                <UserOutlined style={{ fontSize: "20px", color: "#40a9ff" }} />
                            )}
                        </Tooltip>
                    ))
                }
            </div>
        </div>
    );
};

const RecTaskTracking = () => {

    const { apiurl, token } = useAuth();
    const [jobData, setJobData] = useState([]);
    const [recruiters, setRecruiters] = useState([]);
    const [recentActivities, setRecentActivities] = useState([]);
    const [mainComponents, setMainComponents] = useState([]);

    const [selectedFilter, setSelectedFilter] = useState("New")

    const fetchData = async () => {
        const response = await fetch(`${apiurl}/manager/recruiters-task-tracking/`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        const data = await response.json();
        if (data.error) {
            console.log(data.error)
        }

        setRecruiters(data.recruiters_list)
        setJobData(data.job_data)
        setRecentActivities(data.recent_activities)
        setMainComponents(data.main_components)
    }

    useEffect(() => {
        if (token) {
            fetchData()
        }
    }, [token])

    const handleSelectedFilter = (filter) => {
        setSelectedFilter(filter)
    }

    return (
        <div className='rec-task-tracking'>
            <div className="left">
                <div className="main-components">
                    <div className="section new">
                        <div className="topp">
                            <span >New</span>
                            <img className='icon' src={File} alt="" />
                        </div>
                        <span className="count">{mainComponents?.new}</span>
                        <span className="thumbnail"> +5 from yesterday</span>
                    </div>
                    <div className="section in-progress">
                        <div className="topp">
                            <span>In Progress</span>
                            <img className='icon' src={PlayButton} alt="" />
                        </div>
                        <span className="count">{mainComponents?.on_going}</span>
                        <span className="thumbnail"> +8 from yesterday</span>
                    </div>
                    <div className="section completed">
                        <div className="topp">
                            <span>Completed</span>
                            <img className='icon' src={Completed} alt="" />
                        </div>
                        <span className="count">{mainComponents?.completed_posts}</span>
                        <span className="thumbnail"> +8 from yesterday</span>
                    </div>
                    <div className="section missed-deadline">
                        <div className="topp">
                            <span>Missed Deadline</span>
                            <img className='icon' src={Incomplete} alt="" />
                        </div>
                        <span className="count">{mainComponents?.completed_deadline}</span>
                        <span className="thumbnail"> +3 from yesterday</span>
                    </div>
                </div>

                <div className="job-tracking">
                    <div className="title" >
                        <span className='heading'>Job Tracking</span>
                        <span className='filter'><FilterOutlined /> Filter</span>
                    </div>
                    <div className="sec-2">
                        <div className="filter1">
                            {["New", "In Progress", "Completed"].map((filter) => (
                                <span
                                    key={filter}
                                    className={`item ${selectedFilter === filter ? "selected" : ""}`}
                                    onClick={() => handleSelectedFilter(filter)}
                                >
                                    {filter}
                                </span>
                            ))}
                        </div>
                        <div className="sort">Sort by: dropdown</div>
                    </div>
                    <div className="job-list">
                        {jobData.map((job, index) => (
                            <Row key={index} job={job} />
                        ))}
                    </div>
                </div>
            </div>
            <div className="right">
                <div className="title">
                    <span className='heading'>Recruiters</span>
                    <span className='view-all'>View All</span>
                </div>
                <div className="list">
                    {recruiters.map((item, index) => (
                        <div className="profile" key={index}>
                            {item.Profile ? (
                                <img src={item.Profile} alt="" />
                            ) : (
                                <UserOutlined style={{ fontSize: "20px", padding: '10px', borderRadius: '50%', color: ["rgba(253, 242, 242, 1)", "rgba(254, 249, 238, 1)", "rgba(238, 253, 243, 1)"][index % 3], backgroundColor: ["rgba(55, 154, 230, 1)", "rgba(34, 204, 178, 1)", "rgba(35, 30, 59, 1)", "rgba(223, 28, 61, 1)"][index % 4] }} />
                            )}
                            <span className="name">{item.name}</span>
                        </div>
                    ))}
                    <div className="profile add-more">
                        <div className='logo'>
                            <PlusCircleOutlined style={{ fontSize: "40px", color: "gray", }} />
                        </div>
                        <span className="name">Add</span>
                    </div>
                </div>
                <div className="recent-activities">
                    <div className="title">Recent activites</div>
                    {recentActivities.map((item, index) => (
                        <div className="activity">
                            <div className="activity-item">
                                {item.profile ? (
                                    <img src={item.profile} alt="" />
                                ) : (
                                    <UserOutlined style={{ fontSize: "20px", padding: '10px', borderRadius: '50%', color: ["rgba(253, 242, 242, 1)", "rgba(254, 249, 238, 1)", "rgba(238, 253, 243, 1)"][index % 3], backgroundColor: ["rgba(55, 154, 230, 1)", "rgba(34, 204, 178, 1)", "rgba(35, 30, 59, 1)", "rgba(223, 28, 61, 1)"][index % 4] }} />
                                )}
                                <div className="details">
                                    <span className="name">{item.name}</span>
                                    <span className="thumbnail">{item.thumbnail}</span>
                                    <span className="candidate">{item.task}</span>
                                    <span className="job">{item.job_title}</span>
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RecTaskTracking;
