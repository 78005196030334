import React, { useEffect, useState } from "react";
import CandidatesCard from "../managercards/CandidatesCard";
import Main from "../Layout";
import "./Candidates.css";
import searchicon from "../../../../images/agency/job-postings/searchicon.svg";
import { message, Select } from "antd";
import { useAuth } from "../../../common/useAuth";

const Candidates = () => {
  const { apiurl, token } = useAuth();

  const [jobTitles, setJobTitles] = useState([
    "Software Developer",
    "Django Developer",
    "React Developer",
    "Full Stack Developer",
  ]);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [candidatesApplcactions, setCandidatesApplications] = useState(null);

  const handleJobTitleChange = (values) => {
    setSelectedJobs(values);
    console.log("Selected Job Titles:", values);
  };

  useEffect(() => {
    fetchCandidatesApplications();
  }, []);

  const fetchCandidatesApplications = async () => {
    // setLoading(true);
    // setError(null); // Reset error state before making a new request

    try {
      // Replace with your API URL (e.g., `/manager/applications/`)
      const url = `${apiurl}/manger/applications`;

      // Fetch request to get the applications data based on selected jobs
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Add token if needed
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch job applications.");
      }

      const data = await response.json();

      // Filter the data to only include applications for selected jobs

      setCandidatesApplications(data || []); // Store the filtered applications
    } catch (err) {
      // setError(err.message);  /// Handle errors
      message.error(err.message);
    } finally {
      // setLoading(false);
    }
  };

  // console.log("candidatesApplcactions",candidatesApplcactions)

  // candidatesApplcactions.map((obj)=>{
  //   console.log(obj)
  // })
  // candidatesApplcactions?.job_postings_with_applications?.map((eachjob)=>{

  //   console.log("first i need to fetch the eachjob.job_posting.job_title, eachjob.job_posting.job_description")
  //   console.log("first i need to fetch the eachjob.applications.map((app)=>{ app.resume.candidate_name,app.resume.id, app.status , })")

  //   console.log("eachjob applications",eachjob.applications)
  //   console.log("eachjob job postings ",eachjob.job_posting, "is equal to the content typed then returnt the object from the filter")
  // })

  return (
    <Main defaultSelectedKey="4">
      <div className="agency-candidates">
        <h2>Candidates</h2>
        <div className="row1">
          <div className="dropdown">
            <span>Job Title</span>
            <Select
              mode="multiple"
              style={{ width: 200 }}
              placeholder="Select Job Titles"
              onChange={handleJobTitleChange}
              value={selectedJobs} // Keeps checkboxes ticked
              showArrow={true} // Keeps dropdown behavior normal
              tagRender={() => null} // Hides selected values from input
            >
              {jobTitles.map((title, index) => (
                <Select.Option key={index} value={title}>
                  {title}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="search-inputbtn-container">
            <div className="search-btn">
              <img src={searchicon} alt="Search" />
              <input className="input" placeholder="Search Candidates" />
            </div>
          </div>
        </div>
        {selectedJobs.length > 0 && (
          <div className="row2">
            <p
              style={{ textAlign: "left", fontWeight: "600", fontSize: "15px" }}
            >
              Results
            </p>
            <div className="results-clearall">
              <div className="results">
                {selectedJobs.map((job) => {
                  return (
                    <div className="header">
                      <div className="selected-name">
                        <span>{job}</span>
                      </div>
                    </div>
                  );
                })}
              </div>

              {selectedJobs.length > 1 && (
                <button
                  style={{
                    width: "100px",
                    height: "30px",
                    borderRadius: "12px",
                    color: "#1681FF",
                    outline: "none",
                    margin: "none",
                    border: "1px solid #1681FF",
                    cursor: "pointer",
                  }}
                >
                  Clear All
                </button>
              )}
            </div>
          </div>
        )}

        <div className="row3">
          {candidatesApplcactions?.job_postings_with_applications?.map(
            (eachJob) => {
              // Fetching job posting details
              const { job_title, job_description } = eachJob.job_posting;

              // Mapping over applications for the specific job
              return eachJob.applications.map((app) => {
                const { status } = app;
                const { candidate_name, id } = app.resume;

                // Render CandidatesCard for each application here
                return (
                  <div key={id}>
                
                    <CandidatesCard
                      candidateName={candidate_name}
                      resumeId={id}
                      applicationStatus={status}
                      jobTitle={job_title}
                      jobDescription={job_description}
                    />
                  </div>
                );
              });
            }
          )}
        </div>
      </div>
    </Main>
  );
};

export default Candidates;
