import { useNavigate } from "react-router-dom"
import "./CardInterviewCard.css"

const CandInterviewCard = ({ round, interviewerName, startTime, endTime, company, position ,scheduledDate}) => {
  const Navigate=useNavigate()
  return (
    <div className="cand-dash-interview-card">
      <div className="card-container">
      <div className="card-content">
        <div className="card-info">
          <h3 className="card-title">
            Round-{round} and {interviewerName}
          </h3>
          <p className="card-time">
           Date : {scheduledDate} Time : {startTime} - {endTime}
          </p>
          
          <p className="card-company">
            {company} - {position}
          </p>
        </div>
        <button className="view-button" onClick={()=> Navigate("/candidate/upcoming_interviews")}>View</button>
      </div>
    </div>

    </div>
    
  )
}



export default CandInterviewCard

