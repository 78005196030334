// import React from "react";
// import MainLayout from "../../common/Layout/MainLayout"
// import {
// 	DashboardOutlined,
// 	UnorderedListOutlined,
// 	LogoutOutlined,
// 	ProfileOutlined
// } from "@ant-design/icons";
// import logo from "./../../../images/GAHIRESYNC-LOGO.svg"
// import { useAuth } from "../../common/useAuth";

// const { Header, Sider } = Layout;

// const Main = ({ children, defaultSelectedKey }) => {
// 	const location = useLocation();
// 	const navigate = useNavigate();
// 	const [openKeys, setOpenKeys] = useState([]);
// 	const { handleLogout } = useAuth();

// 	const defaultSelectedKeys = () => {
// 		const pathname = location.pathname;

// 		const menuItems = [
// 			"/",
// 			"/recruiter/postings",
// 			"/recruiter/manage_calender_events",
// 			"/recruiter/profile",

// 		];

// 		const index = menuItems.findIndex((item) => pathname.startsWith(item));
// 		if (index === -1) return ["1"];
// 		return [`${index + 2}`];
// 	};

// 	const handleMenuItemClick = ({ key, action }) => {
// 		if (action === "logout") {
// 			Logout();
// 		}
// 	};

// 	const Logout = () => {
// 		handleLogout();
// 		navigate("/login");
// 	};

// 	const menuItems = [
// 		{
// 			key: "1",
// 			icon: <DashboardOutlined />,
// 			label: <Link to="/">Dashboard</Link>,
// 		},
// 		{
// 			key: "2",
// 			icon: <UnorderedListOutlined />,
// 			label: <Link to="/recruiter/postings">Assigned Posts</Link>,
// 		},
// 		{
// 			key: "3",
// 			icon: <UnorderedListOutlined />,
// 			label: <Link to="/recruiter/manage_calender_events">My Events</Link>,
// 		},
// 		{
// 			key: "4",
// 			icon: <UnorderedListOutlined />,
// 			label: <Link to="/recruiter/profile">Profile</Link>,
// 		},
// 		{
// 			key: "5",
// 			icon: <UnorderedListOutlined />,
// 			label: <Link to="/recruiter/schedule_applications">Schedule Applications</Link>,
// 		},
// 		{
// 			key: "6",
// 			icon: <ProfileOutlined />,
// 			label: <Link to="/recruiter/reconfirmation-applications">Reconfirm Applications</Link>,
// 		},
// 		{
// 			key: "7",
// 			icon: <LogoutOutlined />,
// 			label: "Logout",
// 			onClick: Logout,
// 		},
// 	];

// 	return (
// 		<Layout>
// 			<Sider
// 				className="side"
// 				breakpoint="md"
// 				collapsedWidth="0"
// 				width={"225px"}
// 				style={{
// 					height: "calc(100vh - 100px)",
// 					position: "fixed",
// 					left: "0",
// 					top: "100px",
// 					bottom: 0,
// 					zIndex: 1,
// 					background: "#fff",
// 				}}>
// 				<Menu
// 					theme="light"
// 					defaultSelectedKeys={defaultSelectedKey ? defaultSelectedKey : defaultSelectedKeys()}
// 					openKeys={openKeys}
// 					onOpenChange={(keys) => setOpenKeys(keys)}
// 					onClick={handleMenuItemClick}
// 					className="menu"
// 					mode="inline"
// 					items={menuItems}></Menu>
// 			</Sider>

// 			<Layout>
// 				<Header className="head" style={{ padding: 0 }}>
// 					<div className="header-logo">
// 						<a href="/">
// 							<img alt="logo" src={logo}></img>
// 						</a>
// 					</div>
// 					<div className="header-buttons"></div>
// 				</Header>
// 				<div className="content">{children}</div>
// 			</Layout>
// 		</Layout>
// 	);
// };

// export default Main;


import React from 'react'
import MainLayout from '../../common/Layout/MainLayout'
import dashboard from "./../../../images/Dashboard.svg"
import assigned from "./../../../images/AssignedPosts.svg"
import events from "./../../../images/Events.svg"
import calendar from "./../../../images/Calendar.svg"

import Reconfirm from "./../../../images/Reconfirm.svg"

const Layout = ({ children, defaultSelectedKey }) => {
	const cilentoptions = [
		{
			"key": 1,
			"label": "Dashboard",
			"logo": dashboard,
			"path": '/'
		},
		{
			"key": 2,
			"label": "Assigned Jobs",
			"logo": assigned,
			"path": '/recruiter/postings'
		},
		{
			"key": 3,
			"label": "My Events",
			"logo": events,
			"path": '/recruiter/manage_calender_events'
		},
		{
			"key": 4,
			"label": "Schedule Applications",
			"logo": calendar,
			'path': '/recruiter/schedule_applications',
		},
		{
			"key": 5,
			"label": "Reconfirm Applications",
			"logo": Reconfirm,
			"path": "/recruiter/reconfirmation-applications"
		},
	]

	return (
		<>
			<MainLayout children={children} defaultSelectedKey={defaultSelectedKey} options={cilentoptions}></MainLayout>
		</>
	)
}

export default Layout