import React, { useEffect, useState } from "react";
import "./ApplicationsToScedule.css";
import { useAuth } from "../../../common/useAuth";
import {
  message,
  Table,
  Modal,
  Button,
  Input,
  DatePicker,
  Form,
  TimePicker,
  Tag,
} from "antd";
import Main from "../Layout";
import dayjs from "dayjs";
import { SearchOutlined } from "@ant-design/icons";
import InterviewerReview from "./reviewcard/InterviewerReview";

const ApplicationsToSchedule = () => {
  const { apiurl, token } = useAuth();
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [selectedDate, setSelectedDate] = useState(null);
  const [fromTime, setFromTime] = useState(null);
  const [inputSearch, setInputSearch] = useState(null);
  const [component, setComponent] = useState("yet to scedule");


  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${apiurl}/recruiter/schedule_interview/pending_application/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data.error) {
        return message.error(data.error);
      }
      setData(data);
    } catch (e) {
      console.error(e);
      message.error("Failed to fetch applications");
    }
  };

  const fetchApplicationDetails = async (application_id) => {
    console.log(application_id, "is the application id ");
    setLoading(true);
    try {
      const response = await fetch(
        `${apiurl}/recruiter/schedule_interview/pending_application/?application_id=${application_id}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const applicationData = await response.json();
      if (applicationData.error) {
        return message.error(applicationData.error);
      }

      setSelectedApplication(applicationData);
      setIsModalOpen(true);
    } catch (error) {
      message.error("Failed to fetch application details");
    } finally {
      setLoading(false);
    }
  };

  const handleScheduleInterview = async (values) => {
    const payload = {
      scheduled_date: values.scheduled_date.format("YYYY-MM-DD"),
      from_time: values.from_time.format("HH:mm:ss"),
      to_time: values.to_time.format("HH:mm:ss"),
      meet_link: values.meet_link,
    };

    console.log(selectedApplication.application_id);

    try {
      const response = await fetch(
        `${apiurl}/recruiter/schedule_interview/pending_application/?application_id=${selectedApplication.application_id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const responseData = await response.json();
      if (response.ok) {
        message.success("Interview scheduled successfully!");
        setIsModalOpen(false);
        fetchData();
      } else {
        message.error(responseData.error || "Failed to schedule interview");
      }
    } catch (error) {
      message.error("Failed to schedule interview");
    }
  };

  const columns = [
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_id",
    },
    {
      title: "Candidate Name",
      dataIndex: "candidate_name",
      key: "candidate_name",
    },
    {
      title: "Round Number",
      dataIndex: "round_num",
      key: "round_num",
    },
    {
      title: "Schedule Interview",
      key: "schedule_interview",
      render: (_, record) =>
        record.next_interview ? (
          <h7>Interview scheduled</h7>
        ) : (
          <button
            className="scedule-button"
            onClick={() => fetchApplicationDetails(record.application_id)}
          >
            Schedule Interview
          </button>
        ),
    },
  ];

  const columns2 = [
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_id",
    },
    {
      title: "Candidate",
      dataIndex: "candidate_name",
      key: "candidate_name",
    },
    {
      title: "Round",
      dataIndex: "round_num",
      key: "round_num",
    },
    {
      title: "scheduled_date",
      dataIndex: "scheduled_date",
      key: "scheduled_date",
    },
    {
      title: "Timings",
      dataIndex: "timings",
      key: "timings",
      render: (_, record) => `${record.from_time} - ${record.to_time}`,
    },
    {
      title: "status",
      dataIndex: "status",
      key: "status",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let textColor = "";
        let backgroundColor = "";

        switch (status) {
          case "scheduled":
            textColor = "#D664CF";
            backgroundColor = "#FFF3F9";
            break;
          case "pending":
            textColor = "#FF7E80";
            backgroundColor = "#FFF3F3";
            break;
          case "completed":
            textColor = "#6FD664";
            backgroundColor = "#F5FFF3";
            break;
          default:
            textColor = "gray";
            backgroundColor = "#f0f0f0";
        }

        return (
          <Tag
            style={{
              color: textColor,
              background: backgroundColor,
              fontSize: "11px",
              border: "none",
              padding: "2px 8px",
              borderRadius: "4px",
            }}
          >
            {status.toUpperCase()}
          </Tag>
        );
      },
    },

    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Tag
          style={{
            backgroundColor: "#F3F9FF",
            color: "#1890ff",
            borderColor: "transparent",
            padding: "5px 10px",
            fontSize: "14px",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={handleReview}
        >
          view
        </Tag>
      ),
    },
  ];

  const [openReview, SetOpenReview] = useState(false);

  const handleReview = () => {
    SetOpenReview(true)
  }


  console.log("date of interview ", data);

  const scheduled = data.filter((obj) => obj.next_interview);
  const notScheduled = data.filter((obj) => obj.next_interview == null);
  // Generic filter function for both arrays
  const filterBySearch = (array, search) => {
    if (!search) return array;
    return array.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(search.toLowerCase())
      )
    );
  };

  // Apply filter based on inputSearch
  const filteredScheduled = filterBySearch(scheduled, inputSearch);
  const filteredNotScheduled = filterBySearch(notScheduled, inputSearch);

  return (
    <Main defaultSelectedKey="4">
      {/* <h1>Schedule Interviews</h1> */}
      <div
        style={{
          width: "100%",
          display: "flex",
          gap: "20px",
          margin: "0px auto",
          justifyContent: "center",
        }}
      >
        <h4
          onClick={() => setComponent("yet to scedule")}
          style={{
            cursor: "pointer",
            borderBottom:
              component === "yet to scedule" ? "2px solid #1890ff" : "none",
            color: component === "yet to scedule" ? "#228AF5" : "#565E6C",
            paddingBottom: "4px",
          }}
        >
          Yet to Schedule
        </h4>
        <h4
          onClick={() => setComponent("already")}
          style={{
            cursor: "pointer",
            borderBottom:
              component === "already" ? "2px solid #1890ff" : "none",
            color: component === "already" ? "#228AF5" : "#565E6C",
          }}
        >
          Already Schedule
        </h4>
      </div>

      <div className="search-sceduletable">
        <div className="search-container">
          <Input
            prefix={<SearchOutlined className="search-icon" />}
            placeholder="Search"
            className="search-input"
            value={inputSearch}
            onChange={(e) => setInputSearch(e.target.value)}
          />
        </div>
        {component == "yet to scedule" ? (
          <Table
            columns={columns}
            dataSource={filteredNotScheduled}
            rowKey="application_id"
            pagination={false}
            className="styled-table"
          />
        ) : (
          <Table
            columns={columns2}
            dataSource={filteredScheduled}
            rowKey="application_id"
            pagination={false}
            className="styled-table"
          />
        )}
      </div>

      <Modal
        title="Schedule interview"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width={700} // wider modal
      >
        {selectedApplication && (
          <Form
            form={form}
            layout="vertical"
            onFinish={handleScheduleInterview}
            style={{ padding: "10px" }}
          >
            {/* Interviewer Details */}
            <div
              style={{
                border: "1px solid #e6e6e6",
                borderRadius: "8px",
                padding: "16px",
                marginBottom: "16px",
              }}
            >
              <h3 style={{ fontWeight: "600", marginBottom: "16px" }}>
                Interviewer Details
              </h3>
              <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                <Form.Item style={{ flex: "1 1 45%" }}>
                  <Input
                    prefix={<i className="fa fa-user" />}
                    value={selectedApplication.interviewer_name}
                    disabled
                  />
                </Form.Item>
                <Form.Item style={{ flex: "1 1 45%" }}>
                  <Input
                    prefix={<i className="fa fa-envelope" />}
                    value={selectedApplication.interviewer_email}
                    disabled
                  />
                </Form.Item>
              </div>
            </div>

            {/* Candidate Details */}
            <div
              style={{
                border: "1px solid #e6e6e6",
                borderRadius: "8px",
                padding: "16px",
                marginBottom: "16px",
              }}
            >
              <h3 style={{ fontWeight: "600", marginBottom: "16px" }}>
                Candidate Details
              </h3>
              <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                <Form.Item style={{ flex: "1 1 45%" }}>
                  <Input
                    prefix={<i className="fa fa-user" />}
                    value={selectedApplication.candidate_name}
                    disabled
                  />
                </Form.Item>
                <Form.Item style={{ flex: "1 1 45%" }}>
                  <Input
                    prefix={<i className="fa fa-envelope" />}
                    value={selectedApplication.candidate_email}
                    disabled
                  />
                </Form.Item>
                <Form.Item style={{ flex: "1 1 45%" }}>
                  <Input
                    prefix={<i className="fa fa-phone" />}
                    value={selectedApplication.candidate_contact}
                    disabled
                  />
                </Form.Item>
                <Form.Item style={{ flex: "1 1 45%" }}>
                  <Input
                    prefix={<i className="fa fa-phone" />}
                    value={selectedApplication.candidate_alternate_contact}
                    disabled
                  />
                </Form.Item>
              </div>
            </div>

            {/* Interview Details */}
            <div
              style={{
                border: "1px solid #e6e6e6",
                borderRadius: "8px",
                padding: "16px",
              }}
            >
              <h3 style={{ fontWeight: "600", marginBottom: "16px" }}>
                Interview Details
              </h3>
              <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                <Form.Item style={{ flex: "1 1 45%" }}>
                  <Input
                    prefix={<i className="fa fa-briefcase" />}
                    value={selectedApplication.job_title}
                    disabled
                  />
                </Form.Item>
                <Form.Item style={{ flex: "1 1 45%" }}>
                  <Input
                    prefix={<i className="fa fa-code" />}
                    value={selectedApplication.interview_type}
                    disabled
                  />
                </Form.Item>
                <Form.Item style={{ flex: "1 1 45%" }}>
                  <Input
                    prefix={<i className="fa fa-globe" />}
                    value={selectedApplication.interview_mode}
                    disabled
                  />
                </Form.Item>
                <Form.Item
                  style={{ flex: "1 1 45%" }}
                  name="scheduled_date"
                  rules={[
                    {
                      required: true,
                      message: "Please select a date of interview!",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder="Date of interview"
                    format="YYYY-MM-DD"
                    style={{ width: "100%" }}
                    disabledDate={(current) =>
                      current && current < dayjs().startOf("day")
                    }
                    onChange={(date) => setSelectedDate(date)}
                    suffixIcon={<i className="fa fa-calendar" />}
                  />
                </Form.Item>
                <Form.Item
                  style={{ flex: "1 1 45%" }}
                  name="from_time"
                  rules={[
                    { required: true, message: "Please select from time!" },
                  ]}
                >
                  <TimePicker
                    placeholder="from time"
                    format="HH:mm"
                    style={{ width: "100%" }}
                    minuteStep={5}
                    disabledTime={() => {
                      if (!selectedDate) return {};
                      const now = dayjs();
                      const isToday = selectedDate.isSame(now, "day");
                      if (isToday) {
                        return {
                          disabledHours: () => [...Array(now.hour()).keys()],
                          disabledMinutes: (hour) =>
                            hour === now.hour()
                              ? [...Array(now.minute() + 1).keys()]
                              : [],
                        };
                      }
                      return {};
                    }}
                    suffixIcon={<i className="fa fa-clock-o" />}
                    onChange={(time) => setFromTime(time)}
                  />
                </Form.Item>
                <Form.Item
                  style={{ flex: "1 1 45%" }}
                  name="to_time"
                  rules={[
                    { required: true, message: "Please select to time!" },
                    {
                      validator: (_, value) =>
                        fromTime && value && value.isAfter(fromTime)
                          ? Promise.resolve()
                          : Promise.reject("To Time must be after From Time"),
                    },
                  ]}
                >
                  <TimePicker
                    placeholder="to time"
                    format="HH:mm"
                    style={{ width: "100%" }}
                    minuteStep={5}
                    suffixIcon={<i className="fa fa-clock-o" />}
                    disabledTime={() => {
                      if (!fromTime) return {};
                      return {
                        disabledHours: () => [...Array(fromTime.hour()).keys()],
                        disabledMinutes: (hour) =>
                          hour === fromTime.hour()
                            ? [...Array(fromTime.minute() + 1).keys()]
                            : [],
                      };
                    }}
                  />
                </Form.Item>

                {selectedApplication.interview_mode === "online" && (
                  <Form.Item style={{ flex: "1 1 100%" }} name="meet_link">
                    <Input
                      prefix={<i className="fa fa-link" />}
                      placeholder="Meet Link"
                    />
                  </Form.Item>
                )}
              </div>
            </div>

            {/* Footer Buttons */}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "16px",
                gap: "8px",
              }}
            >
              <Button onClick={() => form.resetFields()}>Reset All</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Schedule Meet
              </Button>
            </div>
          </Form>
        )}
      </Modal>
      <Modal
        open={openReview}
        onCancel={() => SetOpenReview(false)}
        footer={null} // You can add buttons here if needed
        width={900}
      >
        <InterviewerReview />
      </Modal>
    </Main>
  );
};

export default ApplicationsToSchedule;
