"use client";

import { useState, useEffect } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "./Calender.css";
import "./CalendarInterviewCard.css";
import Modal from "antd/es/modal/Modal";
import { Button } from "antd";

export default function Calendar() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [view, setView] = useState("week");

  // Sample interview data
  const [interviews, setInterviews] = useState([
    {
      id: 1,
      title: "System Design Discussion",
      day: "2025-01-10",
      startTime: "09:30",
      endTime: "10:30",
      type: "design",
    },
    {
      id: 2,
      title: "Coding Assessment",
      day: "2025-01-12",
      startTime: "11:00",
      endTime: "12:00",
      type: "coding",
    },
    {
      id: 3,
      title: "HR Round",
      day: "2025-01-15",
      startTime: "14:00",
      endTime: "14:45",
      type: "hr",
    },
    {
      id: 4,
      title: "Behavioral Interview",
      day: "2025-01-18",
      startTime: "10:00",
      endTime: "11:00",
      type: "behavioral",
    },
    {
      id: 5,
      title: "Technical Interview",
      day: "2025-01-20",
      startTime: "15:00",
      endTime: "16:00",
      type: "technical",
    },
    {
      id: 6,
      title: "Machine Learning Round",
      day: "2025-01-22",
      startTime: "13:30",
      endTime: "14:30",
      type: "ml",
    },
    {
      id: 7,
      title: "Frontend Development Test",
      day: "2025-01-25",
      startTime: "10:15",
      endTime: "11:15",
      type: "frontend",
    },
    {
      id: 8,
      title: "Backend Development Test",
      day: "2025-01-28",
      startTime: "16:00",
      endTime: "17:00",
      type: "backend",
    },
    {
      id: 9,
      title: "Full-Stack Interview",
      day: "2025-02-02",
      startTime: "12:00",
      endTime: "13:00",
      type: "fullstack",
    },
    {
      id: 10,
      title: "Final Technical Interview",
      day: "2025-02-05",
      startTime: "14:30",
      endTime: "15:30",
      type: "technical",
    },
    {
      id: 11,
      title: "Database Architecture Discussion",
      day: "2025-02-08",
      startTime: "11:45",
      endTime: "12:45",
      type: "database",
    },
    {
      id: 12,
      title: "Product Management Interview",
      day: "2025-02-12",
      startTime: "09:00",
      endTime: "10:00",
      type: "product",
    },
    {
      id: 13,
      title: "Cloud Computing Round",
      day: "2025-02-15",
      startTime: "13:00",
      endTime: "14:00",
      type: "cloud",
    },
    {
      id: 14,
      title: "DevOps Assessment",
      day: "2025-02-18",
      startTime: "15:30",
      endTime: "16:30",
      type: "devops",
    },
    {
      id: 15,
      title: "Cybersecurity Interview",
      day: "2025-02-22",
      startTime: "10:30",
      endTime: "11:30",
      type: "security",
    },
    {
      id: 16,
      title: "Data Science Round",
      day: "2025-02-25",
      startTime: "12:15",
      endTime: "13:15",
      type: "datascience",
    },
    {
      id: 17,
      title: "Blockchain Interview",
      day: "2025-02-28",
      startTime: "14:45",
      endTime: "15:45",
      type: "blockchain",
    },
    {
      id: 18,
      title: "AI/ML Final Round",
      day: "2025-03-02",
      startTime: "09:45",
      endTime: "10:45",
      type: "ai",
    },
    {
      id: 19,
      title: "Networking and Systems Interview",
      day: "2025-03-05",
      startTime: "11:30",
      endTime: "12:30",
      type: "networking",
    },
    {
      id: 20,
      title: "Final HR Discussion",
      day: "2025-03-08",
      startTime: "16:00",
      endTime: "16:45",
      type: "hr",
    },
    {
      id: 21,
      title: "Software Architecture Review",
      day: "2025-03-10",
      startTime: "10:00",
      endTime: "11:00",
      type: "architecture",
    },
    {
      id: 22,
      title: "Cloud Infrastructure Interview",
      day: "2025-03-12",
      startTime: "14:00",
      endTime: "15:00",
      type: "cloud",
    },
    {
      id: 23,
      title: "Advanced Data Structures Round",
      day: "2025-03-15",
      startTime: "13:30",
      endTime: "14:30",
      type: "datastructures",
    },
    {
      id: 24,
      title: "Final Technical Screening",
      day: "2025-03-18",
      startTime: "16:00",
      endTime: "17:00",
      type: "technical",
    },
    {
      id: 26,
      title: "Behavioral & Cultural Fit Interview",
      day: "2025-03-22",
      startTime: "11:00",
      endTime: "12:00",
      type: "behavioral",
    },
    {
      id: 27,
      title: "Software Architecture Review",
      day: "2025-03-23",
      startTime: "11:00",
      endTime: "12:00",
      type: "architecture",
    },
    {
      id: 28,
      title: "AI  Review",
      day: "2025-03-27",
      startTime: "11:00",
      endTime: "12:00",
      type: "architecture",
    },
    {
      id: 29,
      title: "AI/ML Final Round",
      day: "2025-03-23",
      startTime: "11:00",
      endTime: "12:00",
      type: "ai",
    },
    {
      id: 30,
      title: "AI  Review",
      day: "2025-03-28",
      startTime: "12:00",
      endTime: "1:20",
      type: "cloud",
    },
  ]);

  // Get the start of the current week (Monday)
  const getStartOfWeek = (date) => {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1); // Adjust when day is Sunday
    return new Date(date.setDate(diff));
  };

  // Get week days starting from Monday

  const getWeekDays = () => {
    const startOfWeek = getStartOfWeek(new Date(currentDate));
    const weekDays = [];

    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    for (let i = 0; i < 7; i++) {
      const day = new Date(startOfWeek);
      day.setDate(startOfWeek.getDate() + i);

      weekDays.push({
        dayName: dayNames[day.getDay()],
        date: day,
      });
    }

    return weekDays;
  };

  // Get time slots from 8 AM to 5 PM
  const getTimeSlots = () => {
    const slots = [];
    for (let hour = 8; hour <= 19; hour++) {
      slots.push(`${hour.toString().padStart(2, "0")}:00`);
    }
    return slots;
  };

  // Format date as "DD"
  const formatDate = (date) => {
    return date.date.getDate().toString();
  };

  // Format month and year
  const formatMonthYear = (date) => {
    return date.toLocaleDateString("en-US", { month: "long", year: "numeric" });
  };

  // Get day name (3 letters)
  const getDayName = (date) => {
    return date.date
      .toLocaleDateString("en-US", { weekday: "short" })
      .toUpperCase();
  };

  // Navigate to previous week
  const goToPreviousWeek = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() - 7);
    setCurrentDate(newDate);
  };

  // Navigate to next week
  const goToNextWeek = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + 7);
    setCurrentDate(newDate);
  };

  // Go to today
  const goToToday = () => {
    setCurrentDate(new Date());
  };

  // Check if a date is today
  const isToday = (date) => {
    console.log("date", date.date);
    const today = new Date();
    return (
      date.date.getDate() === today.getDate() &&
      date.date.getMonth() === today.getMonth() &&
      date.date.getFullYear() === today.getFullYear()
    );
  };

  // Calculate interview position and size
  const calculateInterviewStyle = (interview) => {
    // Convert start and end times to hours for positioning
    const [startHour, startMinute] = interview.startTime.split(":").map(Number);
    const [endHour, endMinute] = interview.endTime.split(":").map(Number);

    // Calculate top position (relative to 8 AM)
    const startFromTop = (startHour - 8) * 60 + startMinute;

    // Calculate height based on duration
    const durationMinutes =
      (endHour - startHour) * 60 + (endMinute - startMinute);

    return {
      top: `${startFromTop}px`,
      height: `${durationMinutes}px`,
      left: `${interview.day * (100 / 7)}%`,
      width: `${100 / 7.7}%`,
    };
  };

  // Current Time Indicator Component
  const CurrentTimeIndicator = () => {
    const [currentTimePosition, setCurrentTimePosition] = useState(null);

    useEffect(() => {
      const updateCurrentTimePosition = () => {
        const now = new Date();
        const currentHour = now.getHours();
        const currentMinute = now.getMinutes();

        // Calculate the current position based on the time slots (relative to 8 AM)
        const minutesSince8AM = (currentHour - 8) * 60 + currentMinute;

        // Ensure the indicator is only displayed within the defined time slots (8 AM to 5 PM)
        if (currentHour >= 8 && currentHour <= 17) {
          setCurrentTimePosition(minutesSince8AM);
        } else {
          setCurrentTimePosition(null); // Hide the indicator if outside the time slots
        }
      };

      // Update the position every minute
      updateCurrentTimePosition();
      const intervalId = setInterval(updateCurrentTimePosition, 60000);

      return () => clearInterval(intervalId);
    }, []);

    if (currentTimePosition === null) {
      return null;
    }

    return (
      <div
        className="current-time-indicator"
        style={{
          top: `${currentTimePosition}px`,
        }}
      />
    );
  };

  const weekDays = getWeekDays();
  const timeSlots = getTimeSlots();

  const [openIntPop, setOpenIntPop] = useState(false);
  const [selectedInterview, setSelectedInterview] = useState(null);

  const handleOpenDetails = (interview) => {
    setSelectedInterview(interview);
    setOpenIntPop(true);
  };

  return (
    <>
    <div className="calendar-container">
      <div className="calendar-header">
        <div className="calendar-navigation">
          <span className="month-year">{formatMonthYear(currentDate)}</span>
          <div className="nav-buttons">
            <button onClick={goToPreviousWeek} className="nav-button">
              <LeftOutlined size={16} />
            </button>
            <button onClick={goToNextWeek} className="nav-button">
              <RightOutlined size={16} />
            </button>
          </div>
          <button onClick={goToToday} className="today-button">
            Today
          </button>
        </div>
        <div className="view-options">
          {/* <button
            className={`view-button ${view === "day" ? "active" : ""}`}
            onClick={() => setView("day")}
          >
            Day
          </button> */}
          <button
            className={`view-button ${view === "week" ? "active" : ""}`}
            onClick={() => setView("week")}
          >
            Week
          </button>
          {/* <button
            className={`view-button ${view === "month" ? "active" : ""}`}
            onClick={() => setView("month")}
          >
            Month
          </button> */}
        </div>
      </div>
      <div className="calendar-body">
        {/* Day headers */}
        <div className="calendar-days-header">
          <div className="time-column-header"></div>
          {weekDays.map((day, index) => (
            <div key={index} className="day-header">
              <div className={`date-number ${isToday(day) ? "today" : ""}`}>
                {formatDate(day)}
              </div>
              <div className="day-name">{getDayName(day)}</div>
            </div>
          ))}
        </div>

        <div className="calendar-content">
          {/* Time column */}
          <div className="time-column">
            {timeSlots.map((time, index) => (
              <div key={index} className="time-slot">
                {time}
              </div>
            ))}
          </div>

          {/* Calendar grid */}
          <div className="calendar-grid">
            {/* Time grid lines */}
            {timeSlots.map((_, index) => (
              <div key={index} className="time-grid-line"></div>
            ))}

            {/* Day columns */}
            <div className="day-columns">
              {weekDays.map((day, dayIndex) => {
                const dayFormatted = new Date(day.date)
                  .toISOString()
                  .split("T")[0]; // Convert day.date to YYYY-MM-DD
                console.log("dayFormatted", dayFormatted);
                return (
                  <div key={dayIndex} className="day-column">
                    {/* Filter interviews that match the current day's date */}
                    {interviews
                      .filter((interview) => {
                        // const interviewFormatted = new Date(interview.day).toISOString().split("T")[0]; // Convert interview.day to YYYY-MM-DD
                        return interview.day === dayFormatted;
                      })
                      .map((interview) => {
                        const style = calculateInterviewStyle(interview);
                        return (
                          <div
                          onClick={()=>handleOpenDetails(interview)}
                            key={interview.id}
                            className={`interview interview-${interview.type}`}
                            style={{
                              top: style.top,
                              height: style.height,
                              left: style.left,
                              width: style.width,
                              cursor:"pointer"
                            }}
                          >
                            <div className="interview-title">
                              {interview.title}
                            </div>
                            <div className="interview-time">
                              {interview.startTime} - {interview.endTime}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                );
              })}
            </div>

            {/* Current time indicator */}
            <CurrentTimeIndicator />

            {/* Interview blocks */}
          </div>
        </div>
      </div>
      
      
    </div>

    <Modal
        title="Interview Details"
        open={openIntPop}
        onCancel={() => setOpenIntPop(false)}
        footer={[
          // <Button key="close" onClick={() => setOpenIntPop(false)}>
          //   Close
          // </Button>,
        ]}
      >
        {selectedInterview && (
          <CalendarInterviewCard interview={selectedInterview}/>
        )}
      </Modal>
    </>
  );
}

// import React from "react";


const CalendarInterviewCard = ({interview}) => {
  return (
    <div className="interview-card-container">
      <div className="interview-header">
        <span className="interview-title">Field Sales Interview</span>
        <button className="interview-expand-btn">⤢</button>
      </div>
      <div className="interview-content">
        <div className="interview-left-section">
          <div className="interview-info">
            <span className="interview-label">Date</span>
            <span className="interview-value">{interview.day}</span>
          </div>
          <div className="interview-info">
            <span className="interview-label">{interview.title}</span>
            <span className="interview-value">{interview.type}</span>
          </div>
          <div className="interview-info">
            <span className="interview-label">Time</span>
            <span className="interview-value interview-time">
              <span className="interview-duration">30 mins</span> {interview.startTime} - {interview.endTime}
            </span>
          </div>
          <div className="interview-info">
            <span className="interview-label">Online Link</span>
            <a href="https://randomuser.me/api/portraits/men/1.jpg" className="interview-value interview-link">
              link.com/googlemeet
            </a>
          </div>
        </div>
        <div className="interview-right-section">
          <div className="interview-info">
            <span className="interview-label">Candidate</span>
            <span className="interview-value">Ameer</span>
          </div>
          <div className="interview-info interview-interviewer">
            <span className="interview-label">Interviewer</span>
            <div className="interview-interviewer-box">
              <img src="https://randomuser.me/api/portraits/men/1.jpg" alt="Kalki" className="interview-avatar" />
              <span className="interview-value">Kalki</span>
            </div>
          </div>
        </div>
      </div>
      <div className="interview-footer">
        <button className="interview-edit-btn">Edit</button>
        <button className="interview-share-btn">Share</button>
      </div>
    </div>
  );
};


