import React from 'react';
import './ViewInterviewers.css'; // Ensure the correct CSS file is imported

const InterviewersTable = ({ interviewers }) => {
  return (
    <div className="interviewers-container">
      {interviewers && interviewers.length > 0 ? (
        <table className="interviewers-table">
          <thead>
            <tr>
              <th>Round</th>
              <th>Interviewer Name</th>
              <th>Interviewer Email</th>
              <th>Mode of Interview</th>
              <th>Type of Interview</th>
            </tr>
          </thead>
          <tbody>
            {interviewers.map((interviewer, index) => (
              <tr key={index}>
                <td>{interviewer.round_num}</td>
                <td>{interviewer.name.username}</td>
                <td>{interviewer.name.email}</td>
                <td>{interviewer.mode_of_interview}</td>
                <td>{interviewer.type_of_interview}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="no-data">No interviewers data available</p>
      )}
    </div>
  );
};

export default InterviewersTable;
