import React from 'react';
import './AllRecruiters.css';
import { PhoneOutlined, MailOutlined, UserOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { useAuth } from '../../../common/useAuth';
import AddRecruiters from './AddRecruiters'
import { Modal } from 'antd';
import { Link } from 'react-router-dom';

const recruiters = [
    { profile: '', name: 'Kalki', phone: '+234567890', email: 'kalki@gmail.com' },
    { profile: '', name: 'Aryan', phone: '+123456789', email: 'aryan@example.com' },
    { profile: '', name: 'Meera', phone: '+987654321', email: 'meera@example.com' },
    { profile: '', name: 'Rohan', phone: '+567890123', email: 'rohan@example.com' },
    { profile: '', name: 'Sneha', phone: '+890123456', email: 'sneha@example.com' },
    { profile: '', name: 'Aditi', phone: '+345678901', email: 'aditi@example.com' },
    { profile: '', name: 'Vikram', phone: '+678901234', email: 'vikram@example.com' },
    { profile: '', name: 'Pooja', phone: '+901234567', email: 'pooja@example.com' }
];

const Profile = ({ data }) => {
    return (
        <div className="box">
            <div className="image">
                {data.profile ? (
                    <img src={data.profile} alt={data.name} />
                ) : (
                    <UserOutlined className="default-icon" />
                )}
            </div>
            <div className="name">{data.name}</div>
            {data.phone &&
                <span className="phone"><PhoneOutlined /> {data.phone}</span>
            }
            <span className="email"><MailOutlined /> {data.email}</span>
            <button className="view-profile">View Profile</button>
            <Link to={`/agency/recruiters/${data.id}`}>Summary</Link>
        </div>
    );
};

const AllRecruiters = () => {

    const [data, setData] = useState([])
    const { token, apiurl } = useAuth();
    const [isModalVisible, setIsModalVisible] = useState(false)

    const fetchRecruiters = async () => {
        try {
            const response = await fetch(`${apiurl}/manager/all-recruiters/`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                method: 'GET'
            })

            const data = await response.json();
            console.log(data)
            if (data.error) {
                console.log(data.error)
            }
            else {
                setData(data.data)
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (token) {
            fetchRecruiters();
        }
    }, [token])

    return (
        <div className='all-recruiters'>
            <div className="header">
                <div className="search">
                    <SearchOutlined></SearchOutlined><input type="text" placeholder="Search recruiters..." />
                </div>
                <button className="add" onClick={() => setIsModalVisible(true)}>
                    <PlusOutlined /> Add Recruiter
                </button>
            </div>
            <div className="recruiters-grid">
                {data.map((recruiter, index) => (
                    <Profile key={index} data={recruiter} />
                ))}
            </div>

            <Modal
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}>
                <AddRecruiters onclose={fetchRecruiters} />
            </Modal>
        </div>
    );
};

export default AllRecruiters;
