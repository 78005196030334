import { Card, Input, message, Modal, Tooltip } from "antd";
import {
  CalendarOutlined,
  ClockCircleOutlined,
  UserOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { useAuth } from "../../common/useAuth";
import "./CandDashCard.css";
import { useState } from "react";

const CandDashboardCard = ({
  position,
  company,
  locations,
  salary,
  date,
  sc_candidate_id,
}) => {
  const { token, apiurl } = useAuth();
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [rejectFeedback, setRejectFeedback] = useState("");

  const openRejectModal = () => {
    setIsRejectModalOpen(true);
  };

  const handleReject = async () => {
    if (!rejectFeedback.trim()) {
      message.error("Please provide feedback for rejection.");
      return;
    }
    try {
      const response = await fetch(
        `${apiurl}/candidate/handle-rejected/?selected_candidate_id=${sc_candidate_id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type":"application/json"
          },
          body: JSON.stringify({ feedback: rejectFeedback }),
        }
      );
      const result = await response.json();
      if (result.error) {
        message.error(result.error);
        return;
      }
      message.success(result.message);
      setIsRejectModalOpen(false);
      setRejectFeedback("");
      // fetchData();
    } catch (e) {
      console.log(e);
      message.error("Failed to reject the job offer");
    }
  };

  const handleAccept = async () => {
    try {
      const response = await fetch(
        `${apiurl}/candidate/handle-accepted/?selected_candidate_id=${sc_candidate_id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = await response.json();
      if (result.error) {
        message.error(result.error);
        return;
      }
      message.success(result.message);
      // fetchData();
    } catch (e) {
      console.log(e);
      message.error("Failed to accept the job offer");
    }
  };

  return (
    <div className="candidate-card">
      <div className="candidate-card-header">
        <h3 className="candidate-position">{position}</h3>
        <p className="candidate-company">{company}</p>
      </div>

      <div className="candidate-card-content">
        <div className="candidate-info-row">
          <div className="candidate-info-item">
            <CalendarOutlined className="candidate-icon" />
            <span>{date}</span>
          </div>
          <div className="candidate-info-item">
            <ClockCircleOutlined className="candidate-icon" />
            <span>10 days</span>
          </div>
        </div>

        <div className="candidate-info-row">
          <div className="candidate-info-item">
            <UserOutlined className="candidate-icon" />
            <span>{salary}</span>
          </div>
          <div className="candidate-info-item">
            <EnvironmentOutlined className="candidate-icon" />
            <span>{locations}</span>
          </div>
        </div>

        <div className="candidate-actions">
          <button className="candidate-btn accept-btn" onClick={handleAccept}>
            Accept
          </button>
          {/* <button
            className="candidate-btn reject-btn"
            onClick={openRejectModal}
          >
            Reject
          </button> */}
        </div>
      </div>

      <Modal
        title="Reason for Rejection"
        open={isRejectModalOpen}
        onCancel={() => setIsRejectModalOpen(false)}
        onOk={handleReject}
      >
        <Input.TextArea
          value={rejectFeedback}
          onChange={(e) => setRejectFeedback(e.target.value)}
          placeholder="Enter reason for rejection"
        />
      </Modal>
    </div>
  );
};

export default CandDashboardCard;
