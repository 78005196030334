import React, { useState } from "react";
import { Select } from "antd";
import "./PrimarySkills.css";
import skillsList from "../../../common/skills";

const SecondarySkills = ({ setSecondarySkills }) => {
    const [skills, setSkills] = useState([]);

    const options = skillsList.map(skill => ({ value: skill, label: skill }));

    const handleSelectChange = (selectedValues) => {
        console.log("Selected:", selectedValues);

        if (!Array.isArray(selectedValues)) return;

        const updatedSkills = skills.filter(skill => selectedValues.includes(skill.skill_name));

        const newSkills = selectedValues
            .filter(skill => !skills.some(existingSkill => existingSkill.skill_name === skill))
            .map(skill => ({
                skill_name: skill,
                skill_metric: "",
                metric_value: "",
                errors: {},
            }));

        const finalSkills = [...updatedSkills, ...newSkills];
        setSkills(finalSkills);
        setSecondarySkills(finalSkills);  // ✅ Ensure secondary skills update properly
    };

    const updateSkill = (index, field, value) => {
        const updatedSkills = [...skills];
        updatedSkills[index][field] = value;

        if (field === "skill_metric") {
            updatedSkills[index]["metric_value"] = "";
        }

        updatedSkills[index].errors[field] = value.trim() === "" ? "This field is required" : "";

        setSkills(updatedSkills);
        setSecondarySkills(updatedSkills);  // ✅ Keep setSecondarySkills in sync
    };

    const updateMetricValue = (index, value, subField) => {
        const updatedSkills = [...skills];
        const skill = updatedSkills[index];

        if (skill.skill_metric === "rating") {
            skill.metric_value = `${value} / ${skill.max_rating || 100}`;
            skill[subField] = value;
        } else if (skill.skill_metric === "experience") {
            skill.metric_value = `${skill.min_exp || 0} - ${value} years of experience`;
            skill[subField] = value;
        } else {
            skill.metric_value = value;
        }

        skill.errors[subField] = value.trim() === "" ? "This field is required" : "";

        setSkills(updatedSkills);
        setSecondarySkills(updatedSkills);  // ✅ Update secondary skills on metric change
    };

    return (
        <div>
            <span className="form-label">
                Secondary Skills
            </span>
            <Select
                mode="tags" // Enables multi-select & custom input
                style={{ width: "100%" }}
                options={options}
                onChange={handleSelectChange}
                placeholder="Select or type skills..."
            />
            <div className="skills-container">
                {skills.map((skill, index) => (
                    <div key={index} className="skills">
                        <div>
                            <label>Skill Name</label>
                            <input type="text" value={skill.skill_name} disabled />
                        </div>

                        <div>
                            <label>Skill Metric</label>
                            <select
                                value={skill.skill_metric}
                                onChange={(e) => {
                                    const selectedValue = e.target.value;

                                    if (selectedValue === "custom") {
                                        updateSkill(index, "skill_metric", "custom");
                                        updateSkill(index, "custom_metric", "");
                                    } else {
                                        updateSkill(index, "skill_metric", selectedValue);
                                        updateSkill(index, "custom_metric", "");
                                    }
                                }}
                            >
                                <option value="" disabled>Select</option>
                                <option value="rating">Rating</option>
                                <option value="experience">Experience</option>
                                <option value="custom">Custom</option>
                            </select>
                            {skill.errors?.skill_metric && <p className="error">{skill.errors.skill_metric}</p>}
                        </div>

                        {skill.skill_metric === "custom" && skill.custom_metric !== undefined && (
                            <div>
                                <label>Custom Metric Name</label>
                                <input
                                    type="text"
                                    placeholder="Enter metric name "
                                    value={skill.custom_metric || ""}
                                    onChange={(e) => updateSkill(index, "custom_metric", e.target.value)}
                                />
                            </div>
                        )}

                        {skill.skill_metric === "rating" && (
                            <div>
                                <label>Rating</label>
                                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <input
                                        type="number"
                                        min="0"
                                        max={skill.max_rating || 100}
                                        placeholder="Rating"
                                        onChange={(e) => updateMetricValue(index, e.target.value, "rating")}
                                    />
                                    <span>/</span>
                                    <input
                                        type="number"
                                        min="1"
                                        defaultValue="100"
                                        placeholder="Max"
                                        onChange={(e) => updateMetricValue(index, e.target.value, "max_rating")}
                                    />
                                </div>
                            </div>
                        )}

                        {(skill.custom_metric) && (
                            <div>
                                <label>Metric Value</label>
                                <input
                                    type="text"
                                    placeholder="Enter metric value"
                                    value={skill.metric_value}
                                    onChange={(e) => updateSkill(index, "metric_value", e.target.value)}
                                />
                                {skill.errors?.metric_value && <p className="error">{skill.errors.metric_value}</p>}
                            </div>
                        )}

                        {skill.skill_metric === "experience" && (
                            <div>
                                <label>Experience (Years)</label>
                                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <input
                                        type="number"
                                        min="0"
                                        placeholder="Min"
                                        onChange={(e) => updateMetricValue(index, e.target.value, "min_exp")}
                                    />
                                    <span>-</span>
                                    <input
                                        type="number"
                                        min="0"
                                        placeholder="Max"
                                        onChange={(e) => updateMetricValue(index, e.target.value, "max_exp")}
                                    />
                                    <span>years of experience</span>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SecondarySkills;
