import React from 'react'
import "./InterviewerProfile.css"
import Main from '../Layout'
import ProfileCard from '../../../common/CommonCards/ProfileCard/ProfileCard'

const InterviewerProfile = () => {
    return (
        <Main>
            <h1>Hello world</h1>
            <ProfileCard></ProfileCard>
        </Main>
    )
}

export default InterviewerProfile