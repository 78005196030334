import React, { useEffect, useRef, useState } from "react";
import { Button, Table, Modal, Spin, Tag } from "antd";
import { Link } from "react-router-dom";
import Main from "./Layout";
import { useAuth } from "../../common/useAuth";
import moment from "moment";
import "./MyTask.css";

const MyTasks = () => {
  const [jobList, setJobList] = useState([]);
  const [jobResumes, setJobResumes] = useState(null);
  const [loading, setLoading] = useState(false); // To control the loading state for fetching resumes
  const [visible, setVisible] = useState(false); // To control the visibility of the modal
  const { apiurl, token } = useAuth();

  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryCand, setSearchQueryCand] = useState("");

  const [selectedDate, setSelectedDate] = useState("");

  const [selectedDateCand, setSelectedDateCand] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleSearchChangeCand = (e) => {
    setSearchQueryCand(e.target.value);
  };

  const handleDateChangeCand = (e) => {
    setSelectedDateCand(e.target.value);
  };

  const inputRef = useRef(null);
  const CandInputRef = useRef(null);

  const openDatePicker = () => {
    console.log("running");
    if (inputRef.current) {
      inputRef.current.type = "date"; // Ensure type is set before showing picker
      inputRef.current.showPicker(); // Open the date picker
    }
  };

  const openDatePickerCand = () => {
    console.log("running");
    if (CandInputRef.current) {
      CandInputRef.current.type = "date"; // Ensure type is set before showing picker
      CandInputRef.current.showPicker(); // Open the date picker
    }
  };

  useEffect(() => {
    fetchJobPosts();
  }, []);

  const fetchJobPosts = async () => {
    try {
      const response = await fetch(`${apiurl}/rec-job-postings/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setJobList(data);
    } catch (error) {
      console.error("Error fetching job posts:", error);
    }
  };

  const fetchResumesSent = (jobData) => {
    setLoading(true); // Set loading to true when the fetch starts
    setVisible(true); // Show the modal

    if (token) {
      // Use the job_id from the jobData argument
      fetch(`${apiurl}/recruiter/resumesent/?job_id=${jobData.id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token for authorization
        },
      })
        .then((response) => {
          console.log("response", response.ok);
          if (!response.ok) {
            // If the response is not OK, throw an error
            throw new Error("Failed to fetch resumes.");
          }
          return response.json() || [];
        })
        .then((data) => {
          console.log(data, "is the fetched resumes data");
          // Ensure data is always an array
          setJobResumes(
            Array.isArray(data.applications) ? data.applications : []
          );
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching resumes:", error);
          setLoading(false); // Set loading to false if an error occurs
        });
    } else {
      console.log("No token found, user not authenticated.");
    }
  };

  const columns = [
    {
      title: "Client Name",
      dataIndex: ["username", "username"],
      key: "username",
      render: (username) => username || "N/A",
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
      render: (jobTitle) => jobTitle || "N/A",
    },
    // {
    //   title: "Recruiter Assigned",
    //   dataIndex: ["assigned_to", "0", "username"],
    //   key: "assigned_to",
    //   render: (username) => (username ? username : "Not Assigned"),
    // },
    
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        const statusColors = {
          opened: "green",
          closed: "red",
          pending: "orange",
        };

        return (
          <Tag color={statusColors[status?.toLowerCase()] || "default"}>
            {status || "Unknown"}
          </Tag>
        );
      },
    },
    {
      title: "No of postions",
      dataIndex: "num_of_positions",
      key: "num_of_positions",
      render: (num_of_positions) => num_of_positions || "Unknown",
    },

    {
      title: "onHold",
      dataIndex: "onHoldCount",
      key: "onHoldCount",
    },
    {
      title: "rejected",
      dataIndex: "rejectedCount",
      key: "rejectedCount",
    },
    {
      title: "pending",
      dataIndex: "pendingCount",
      key: "pendingCount",
    },
    {
      title: "selected",
      dataIndex: "selectedCandidatesCount",
      key: "selectedCandidatesCount",
      render: (selectedCandidatesCount) => selectedCandidatesCount || "Unknown",
    },
    {
      title: "Dead Line",
      dataIndex: "job_close_duration",
      render: (text) => <span style={{ color: "#FA541C" }}>{text}</span>,
    },
    {
      title: "Resumes Sent",
      key: "actions",
      render: (_, record) => (
        <div
          onClick={() => fetchResumesSent(record)}
          className="view-details-btn"
        >
          Resumes
        </div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) =>
        record.status === "opened" ? (
          <Link to={`${record.id}`} className="view-details-btn">
            View Complete Details
          </Link>
        ) : (
          <p style={{ color: "red" }}>Job Post Closed</p>
        ),
    },
  ];

  const columns2 = [
    {
      title: "Candidate Name",
      dataIndex: "candidate_name",
      key: "candidate_name",
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Contact Number",
      dataIndex: "contact_number",
      key: "contact_number",
    },
    {
      title: "Application Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color = "";
        switch (status) {
          case "processing":
            color = "blue";
            break;
          case "selected":
            color = "green";
            break;
          case "rejected":
            color = "red";
            break;
          default:
            color = "default";
        }
        return <Tag color={color}>{status.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Sent Date",
      dataIndex: "app_sent_date",
      key: "app_sent_date",
      render: (text) => (text ? new Date(text).toLocaleDateString() : "-"),
    },

    // {
    //   title: "Date",
    //   dataIndex: "application_date",
    //   key: "date",
    //   render: (text) => moment(text).format("YYYY-MM-DD"), // Format date here
    // },
    // {
    //   title: "Round",
    //   dataIndex: "round_num",
    //   key: "round",
    // },
    // {
    // 	title: "Skills",
    // 	dataIndex: "skills",
    // 	key: "skills",
    // },
  ];

  const filteredJobs = jobList.filter((job) => {
    // Convert search query to lowercase
    const search = searchQuery.toLowerCase();

    // Check if any field matches the search query
    const matchesSearch = Object.values(job).some(
      (value) =>
        typeof value === "string" && value.toLowerCase().includes(search)
    );
    // Format job date to YYYY-MM-DD for accurate comparison
    const jobDate = job.job_close_duration
      ? moment(job.job_close_duration).format("YYYY-MM-DD")
      : "";

    // Check if the date matches (only if a date is selected)
    const matchesDate = selectedDate ? jobDate === selectedDate : true;

    // Apply both filters
    return matchesSearch && matchesDate;
  });

  const filteredCands = jobResumes?.filter((app) => {
    // Convert search query to lowercase
    const search = searchQueryCand.toLowerCase();

    // Check if any field matches the search query
    const matchesSearch = Object.values(app).some(
      (value) =>
        typeof value === "string" && value.toLowerCase().includes(search)
    );
    // Format job date to YYYY-MM-DD for accurate comparison
    const jobDate = app.app_sent_date
      ? moment(app.app_sent_date).format("YYYY-MM-DD")
      : "";

    // Check if the date matches (only if a date is selected)
    const matchesDate = selectedDateCand ? jobDate === selectedDateCand : true;

    // Apply both filters
    return matchesSearch && matchesDate;
  });

  return (
    <Main defaultSelectedKey="2">
      {jobList && jobList.length > 0 ? (
        <div className="rctr-assined-jobs">
          <div style={{padding:"20px",borderRadius:"10px",width:"95%"}}>
          <div className="search-date-container">
            <div className="search-input-container">
              <svg
                className="search-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
              <input
                type="text"
                className="search-input"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>

            <div className="date-input-container">
              <svg
            
                className="search-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                onClick={() => openDatePicker()}
                style={{ cursor: "pointer",position:"relative",left:"23px" ,top:"9px"}}
              >
                <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                <line x1="16" y1="2" x2="16" y2="6"></line>
                <line x1="8" y1="2" x2="8" y2="6"></line>
                <line x1="3" y1="10" x2="21" y2="10"></line>
              </svg>
              <input
                type="text"
                ref={inputRef}
                className="date-input"
                placeholder="DD-MM-YYYY"
                value={selectedDate}
                onChange={handleDateChange}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => {
                  if (!e.target.value) e.target.type = "text";
                }}
              />
            </div>
          </div>
          {/* Above code is search add date   */}
          <div style={{width:"100%"}}>
          <Table
            columns={columns}
            dataSource={filteredJobs}
            className="styled-table"
            rowKey="id"
            pagination={false}
          />

          </div>
         
          </div>
         

        </div>
      ) : (
        <div className="no-postings">There are no postings</div>
      )}

      <Modal
        title="Resumes Sent"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={1000}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <div
            style={{
              padding: "20px",
              border: "2px solid #A2A1A866",
              borderRadius: "10px",
            }}
          >
            <div className="search-date-container">
              <div className="search-input-container">
                <svg
                  className="search-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
                <input
                  type="text"
                  className="search-input"
                  placeholder="Search"
                  value={searchQueryCand}
                  onChange={handleSearchChangeCand}
                />
              </div>

              <div className="date-input-container">
                <svg
                  className="search-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  onClick={() => openDatePickerCand()}
                style={{ cursor: "pointer",position:"relative",left:"23px" ,top:"9px"}}

                >
                  <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="16" y1="2" x2="16" y2="6"></line>
                  <line x1="8" y1="2" x2="8" y2="6"></line>
                  <line x1="3" y1="10" x2="21" y2="10"></line>
                </svg>
                <input
                  type="text"
                  ref={CandInputRef}
                  className="date-input"
                  placeholder="DD-MM-YYYY"
                  value={selectedDateCand}
                  onChange={handleDateChangeCand}
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => {
                    if (!e.target.value) e.target.type = "text";
                  }}
                />
              </div>
            </div>
            <Table
              columns={columns2}
              dataSource={filteredCands || []}
              rowKey="id"
              className="styled-table"
              pagination={false}
            />
          </div>
        )}
      </Modal>
    </Main>
  );
};

export default MyTasks;
