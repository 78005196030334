import React, { useRef, useState, useEffect } from 'react';
import TopNav from './TopNav';
import BG1 from "../../images/LandingPage/BG1.png";
import BG2 from "../../images/LandingPage/BG2.png";
import "./LandingHome.css"
import { ArrowRightOutlined, CheckOutlined } from '@ant-design/icons';
import person from "../../images/LandingPage/person.png";
import logo1 from "../../images/LandingPage/company-logo1.svg"
import logo2 from "../../images/LandingPage/company-logo12svg.svg"
import logo3 from "../../images/LandingPage/company-logo3.svg"
import logo4 from "../../images/LandingPage/logo4.svg"
import logo5 from "../../images/LandingPage/logo5.svg"
import feature1 from "../../images/LandingPage/feature1.png"
import feature2 from "../../images/LandingPage/feature2.png"
import feature3 from "../../images/LandingPage/feature3.png"
import feature4 from "../../images/LandingPage/feature4.png"
import corefeature from "../../images/LandingPage/core-feature.svg"
import Plans from "./Plans.json"
import Faqs from "./Faqs.json"
import { Switch } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import ReviewVideo from "../../assets/review-video.mp4"
import { motion } from 'framer-motion';
import Footer from './Footer';



const FeatureCard = ({ item }) => {
    return (
        <div className="feature-card">
            <img src={item.image} alt="" />
            <div className="name">{item.name}</div>
            <div className="feature-des">{item.description}</div>
        </div>
    )
}


const PlanCard = ({ item }) => {
    return (
        <div className={`${item.active ? "active" : ""} plan-card`}>
            <span className="name">{item.name}</span>
            <div className="features">

                {item.features && item.features.map((feature, index) => (
                    <span className={`${feature.enabled ? "enabled" : "disabled"} "feature"`}>
                        <CheckOutlined /> {feature.name}
                    </span>
                ))}
            </div>
            <span className="price">
                {item.price}
            </span>
            <span className="btn">
                <button>Choose Plan</button>
            </span>
        </div>
    )
}

const FaqComponent = ({ item }) => {
    const [open, setOpen] = useState(false)
    return (
        <div className="faq">
            <div className="topline" onClick={() => setOpen(!open)}>
                <span className="question">{item.question}</span>
                <span
                    className={`btn transition-transform duration-300 ${open ? "rotate-180" : "rotate-0"}`}
                >
                    <DownOutlined />
                </span>
            </div>
            {open &&
                <div className="answer">
                    {item.answer}
                </div>
            }
        </div>
    )
}

const VideoPlayer = ({ item }) => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState();
    const togglePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying); // Toggle state
        }
    };
    return (
        <div className='video'>
            <video ref={videoRef}>
                <source src={item.video_url} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="buttons">
                <button onClick={togglePlayPause}>
                    {isPlaying ? "⏸️" : "▶️"}
                </button>
                <div className="user">
                    <span className="name">{item.name}</span>
                    <span className="profession">{item.profession}</span>
                </div>
            </div>
        </div>
    )
}

const VideoComment = ({ item }) => {
    const videoRef = useRef();
    const [isPlaying, setIsPlaying] = useState();
    const togglePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying); // Toggle state
        }
    };
    return (
        <div className='video-comment'>
            <div className="video-container">
                <video ref={videoRef}>
                    <source src={item.video_url} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="button-container">
                    <button onClick={togglePlayPause}>
                        {isPlaying ? "⏸️" : "▶️"}
                    </button>
                </div>
            </div>
            <div className="user">
                <span className="name">{item.name}</span>
                <span className="profession">{item.profession}</span>
            </div>
            <div className="comment-des">
                {item.comment}
            </div>
        </div>
    )
}

const Comment = ({ item }) => {
    return (
        <div className="comment">
            <div className="user">
                <span className="name">{item.name}</span>
                <span className="profession">{item.profession}</span>
            </div>
            <div className="comment-des">
                {item.comment}
            </div>
        </div>
    )
}

const LandingHome = () => {

    const [billMonthly, setBillMonthly] = useState(false)

    const coreFeatures = [
        {
            "key": "1",
            "image": corefeature,
            "name": "Feature Name",
            "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit."
        },
        {
            "key": "2",
            "image": corefeature,
            "name": "Feature Name",
            "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit."
        },
        {
            "key": "3",
            "image": corefeature,
            "name": "Feature Name",
            "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit."
        },
        {
            "key": "4",
            "image": corefeature,
            "name": "Feature Name",
            "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit."
        },
        {
            "key": "5",
            "image": corefeature,
            "name": "Feature Name",
            "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit."
        },
        {
            "key": "6",
            "image": corefeature,
            "name": "Feature Name",
            "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit."
        },
        {
            "key": "7",
            "image": corefeature,
            "name": "Feature Name",
            "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit."
        },
        {
            "key": "8",
            "image": corefeature,
            "name": "Feature Name",
            "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit."
        },
        {
            "key": "9",
            "image": corefeature,
            "name": "Feature Name",
            "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit."
        },
        {
            "key": "10",
            "image": corefeature,
            "name": "Feature Name",
            "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit."
        },
    ]


    const reviews = [
        {
            "name": "Kalki",
            "profession": "Software Developer",
            "type": "video",
            "video_url": ReviewVideo
        },
        {
            "name": "Mike John",
            "profession": "Manager consultant",
            "type": "comment",
            "comment": "Lorem ipsum dolor sit amet consectet  dolor sit amet consectetur. Nisl dignissim nunc nec pellentesque. Urna pellentesque sit tincidunt adipiscing quis dignissim. Tellus commodo volutpat aliquam aliquam dui pulvinar ornare ornare habitant. Vitae consectetur ultrices arcu venenatis sollicitudin.tincidunt adipiscing quis dignissim. Tellus commodo volutpat aliquam aliquam dui pulvinar ornare ornare habitant."
        },
        {
            "name": "Ameer Abraham",
            "profession": "Senior Developer",
            "type": "video_comment",
            "comment": "Lorem ipsum dolor sit amet consectetur. Nisl dignissim nunc nec pellentesque. Urna pellentesque sit tincidunt adipiscing quis dignissim. Tellus commodo volutpat aliquam aliquam dui pulvinar ornare ornare habitant.  ultrices arcu venenatis sollicitudin.",
            "video_url": ReviewVideo
        },
        {
            "name": "Laxman",
            "profession": "Senior Developer",
            "type": "comment",
            "comment": "Lorem ipsum dolor sit amet consectetur. Nisl dignissim nunc nec pellentesque. Urna pellentesque sit tiarcu vene Tellus commodo volutpat aliquam aliquam dui pulvinar ornare ornare habitant."
        },
    ]


    const logos = [logo1, logo2, logo5, logo2, logo3, logo4, logo5, logo2, logo3, logo4, logo5, logo5, logo2, logo3, logo4, logo5];

    const [count, setCount] = useState();

    useEffect(() => {
        let start = 0;
        const end = 50;
        const duration = 2000;
        const incrementTime = duration / end;

        const counter = setInterval(() => {
            start += 1;
            setCount(start);

            if (start === end) clearInterval(counter);
        }, incrementTime);

        return () => clearInterval(counter);
    }, []);

    const sentence1 = "Effortless Hiring, Seamless Talent Acquisition"
    const wordSplit = (sentence) => {
        return sentence.split(" ");
    }

    return (
        <div className="landing-home">
            <div
                className="top-section"
                style={{ backgroundImage: `url(${BG1})`, backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '0px 0px 70px 70px' }}
            >
                <div className="navbar">
                    <TopNav />
                </div>
                <div className="intro">
                    <div className="side1">
                        <motion.div
                            className="title"
                            initial="hidden"
                            animate="visible"
                            variants={{
                                hidden: { opacity: 1 },
                                visible: {
                                    transition: { staggerChildren: 0.2 }, // Stagger effect for each word
                                },
                            }}
                        >
                            {wordSplit(sentence1).map((word, index) => (
                                <motion.span
                                    key={index}
                                    className="word"
                                    variants={{
                                        hidden: { opacity: 0, y: 20 },
                                        visible: { opacity: 1, y: 0 },
                                    }}
                                    transition={{ duration: 1, ease: "easeOut" }}
                                >
                                    {word}
                                </motion.span>
                            ))}
                        </motion.div>
                        {/* <motion.span className="title" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1, ease: "easeOut" }}>
                            Effortless Hiring, Seamless Talent Acquisition
                        </motion.span> */}
                        <span className="description">
                            Empower your agency with a smarter way to connect top talent with the right companies. Post jobs, manage applications, and streamline recruitment—all in one place.
                        </span>
                        <span className="btn">
                            <button>
                                Book A Demo <ArrowRightOutlined></ArrowRightOutlined>
                            </button>
                        </span>
                    </div>
                    <div className="side2">
                        <img src={person} alt="" />
                        <div className="message message1">Immigration support</div>
                        <div className="message message2">Expand your team and hire globally</div>
                        <div className="message message3">Pay anyone, anywhere quickly</div>
                        <div className="message message4">Set your team up</div>
                    </div>
                </div>
            </div>
            <div className="content-section">
                <div className="trusted-companies">
                    <div className="matter">
                        Trusted by <motion.span
                            className="fifty"
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 1 }}
                            viewport={{ once: false, amount: 0.2 }}
                        >
                            {count}+
                        </motion.span> companies from startups to enterprisers
                    </div>

                    {/* <div className="logos">
                        <img src={logo4} alt="" />
                        <img src={logo2} alt="" />
                        <img src={logo3} alt="" />
                        <img src={logo4} alt="" />
                        <img src={logo5} alt="" />
                        <img src={logo2} alt="" />
                        <img src={logo4} alt="" />
                        <img src={logo5} alt="" />
                        <img src={logo3} alt="" />
                        <img src={logo5} alt="" />
                        <img src={logo2} alt="" />
                        <img src={logo3} alt="" />
                        <img src={logo3} alt="" />
                        <img src={logo5} alt="" />
                    </div> */}

                    {/* <div className="carousel-container">
                        <div className="carousel-track">
                            {logos.map((logo, index) => (
                                <div key={index} className="logo-slide">
                                    <img src={logo} alt={`Company logo ${index + 1}`} />
                                </div>
                            ))}
                        </div>
                    </div> */}

                    <div className="carousel-container">
                        <motion.div
                            className="carousel-track"
                            animate={{ x: ["0%", "-100%"] }}
                            transition={{ repeat: Infinity, duration: 20, ease: "linear" }}
                        >
                            {[...logos, ...logos].map((logo, index) => (
                                <div key={index} className="logo-slide">
                                    <img src={logo} alt={`Company logo ${index + 1}`} />
                                </div>
                            ))}
                        </motion.div>
                    </div>

                </div>
            </div>
            <div className="section2" style={{ backgroundImage: `url(${BG2})`, backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '0px 0px 0px 0px' }}>
                <div className="matter">
                    You can do it all with <span className="fifty">Hiresync</span>
                </div>
                <div className="features">
                    <div className="features-1">
                        <img src={feature1} alt="" />
                        <img src={feature2} alt="" />
                    </div>
                    <img src={feature3} alt="" />
                    <img src={feature4} alt="" />
                </div>
                <div className="subscribers">
                    <div className="counter">
                        <span className="count">5,000+</span>
                        <span className="about">Loved by 5000+ Team members</span>
                    </div>
                    <div className="counter">
                        <span className="count">25,000+</span>
                        <span className="about">Trusted by 25000+ Customers</span>
                    </div>
                    <div className="counter">
                        <span className="count">48 hrs</span>
                        <span className="about">Onboard talent in as little as 48 hours</span>
                    </div>
                </div>
            </div>
            <div className='core-features'>
                <div className="name">Our Core <span className='pink'>Features</span></div>
                <div className="matter">This is something about features, Lorem ipsum dolor sit amet consectetur. Aliquam urna montes amet laoreet pellentesque elementum nisl accumsan.</div>
                <div className="core-features-list">
                    {coreFeatures.map((item, index) => (
                        <FeatureCard item={item} />
                    ))}
                </div>
            </div>
            <div className="plans" style={{ backgroundImage: `url(${BG2})`, backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '0px 0px 0px 0px' }}>
                <div className="name">Right place for your <span className="pink">business</span></div>
                <div className="matter">We have several powerful plans to showcase your business and get discovered as a creative entrepreneurs. Everything you need.</div>
                <div className="toggler">
                    <span className="planss">Bill Annually</span><Switch onChange={() => setBillMonthly(!billMonthly)}></Switch><span className="planss">Bill Monthly</span>
                </div>
                <div className="plans-list">
                    {billMonthly ? (
                        Plans.monthlyPlans.map((item, index) => (
                            <PlanCard item={item}></PlanCard>
                        )
                        )
                    ) : (
                        Plans.annualPlans.map((item, index) => (
                            <PlanCard item={item}></PlanCard>
                        )
                        )
                    )

                    }
                </div>

            </div>
            <div className="reviews" style={{ backgroundImage: `url(${BG2})`, backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '0px 0px 0px 0px' }}>
                <span className="name">Our Customer <span className="pink">Review</span></span>
                <span className="matter">
                    Lorem ipsum dolor sit amet consectetur. Aliquam urna montes amet laoreet pellentesque elementum nisl accumsan.
                </span>
                <div className="reviews-list" >
                    {reviews.map((item, index) => (
                        <div className="review-card">
                            {item.type === 'video' &&
                                <VideoPlayer item={item} />
                            }
                            {item.type === 'comment' &&
                                <Comment item={item} />
                            }
                            {item.type === 'video_comment' &&
                                <VideoComment item={item} />
                            }
                        </div>
                    ))}
                </div>
            </div>
            <div className="faqs">
                <span className="name">Frequently Asked <span className="pink">Questions</span></span>
                <span className="matter">
                    Lorem ipsum dolor sit amet consectetur. Aliquam urna montes amet laoreet pellentesque elementum nisl accumsan.
                </span>
                <div className="faqs-list">
                    {Faqs.faqs && Faqs.faqs.map((item, index) => (
                        <FaqComponent item={item} />
                    ))}
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
}

export default LandingHome;
