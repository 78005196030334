import React from 'react'
import Main from './Layout'
import ViewTickets from '../../tickets/ViewTickets'
import TicketMessages from '../../tickets/TicketMessages'

const InterviewerTickets = () => {
    return (
        <Main>
            <TicketMessages></TicketMessages>
        </Main>
    )
}

export default InterviewerTickets