import React from "react";
import "./JobResponseCard.css";
import threedots from "../../../../images/agency/job-postings/threedots.svg";
import timeicon from "../../../../images/agency/job-postings/timeicon.svg";


const JobResponseCard = () => {

  return (
    <div className="job-resp-card-container">
      <div className="left-container">
        {false ? ( 
          <img />
        ) : (
          <div
            style={{
              width: "40px",
              height: "40px",
              backgroundColor: "lightblue",
              borderRadius:"50px"
            }}
          ></div>
        )}

        <div className="left-cont-text">
          <h5>Ameer</h5>
          <div style={{display:"flex",gap:"5px",}}>
            <img  src={timeicon} style={{width:'13px'}}/>
            <span style={{color:"#424955",fontSize:"12px"}}>1h ago</span>
          </div>
        </div>
      </div>
      <div className="right-container" style={{visibility:"hidden"}}>
        <img src={threedots} />
      </div>
    </div>
  );
};

export default JobResponseCard;
