import React, { useEffect, useState } from "react";
import InterviewCalendar from "../managercards/InterviewCalendar";
import "./RecruiterSummery.css";
import { PieChart, Pie, Cell } from "recharts";
import { UserOutlined } from "@ant-design/icons";
import { Table } from "antd";
import RctrSummerCards from "./rctr-cards/RctrSummerCards";
import RctrSumInterviewCard from "./rctr-cards/RctrSumInterviewCard";
import Main from "../Layout";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../common/useAuth";

const RecruiterSummery = () => {
  const [tableData, SetTableData] = useState(null);
  const [interviews, SetInterveiws] = useState(null);
  const [cardsData, SetCardsData] = useState(null);


  const { id } = useParams();
  console.log("id", id);
  const { apiurl, token } = useAuth();
  {
    /* 

  DON'T REMOVE THE BELOW CODE 
  ---------------------------

  const totalResumes = 100;
  const hired = 72;
  const rejected = 8;
  const processing = 20;

  const data = [
    { name: "Hired", value: hired, color: "#1E88E5" },
    { name: "Rejected", value: rejected, color: "#F44336" },
    { name: "Processing", value: processing, color: "#BBDEFB" },
  ];
  --------------------------
  */
  }

  // Define the columns for the table
  const columns = [
    {
      title: "JOB",
      dataIndex: "job_title",
      key: "job_title",
    },
    {
      title: "TOTAL CANDIDATES",
      dataIndex: "application_count",
      key: "application_count",
      render: (text) => (
        <span className="candidates-count">
          <UserOutlined /> {text}
        </span>
      ),
    },
    {
      title: "VACANCIES",
      dataIndex: "vacancies",
      key: "vacancies",
    },
    {
      title: "DATE EXPIRED",
      dataIndex: "dead_line",
      key: "dead_line",
    },
    {},
  ];

  // Define the data for the table

  useEffect(() => {
    fetchJobPosts(id);
    fetchInterviews();
    fetchCardData();
  }, [id]);

  const fetchInterviews = async () => {
    try {
      const response = await fetch(`${apiurl}/rctr/interviews/?rctr_id=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch interviews");
      }

      const data = await response.json();
      SetInterveiws(data.interviews || []);
      return data.interviews; // Return data if needed for further processing
    } catch (error) {
      console.error("Error fetching interviews:", error);
    }
  };
  console.log(cardsData)

  const fetchCardData = async () => {
    try {
      const response = await fetch(`${apiurl}/rctr/summary/?rctr_id=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch interviews");
      }

      const data = await response.json();
      console.log("Fetched Interviews:", data);
      SetCardsData(data || []);
      return data; // Return data if needed for further processing
    } catch (error) {
      console.error("Error fetching interviews:", error);
    }
  };

  

  const fetchJobPosts = async (id) => {
    try {
      const response = await fetch(`${apiurl}/rec-job-summary/?rctr_id=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      SetTableData(data);
    } catch (error) {
      console.error("Error fetching job posts:", error);
    }
  };

  // here if the id is comming froomm the useparams() or dropdown have all the recuriters names and ids
  // Recruiters name,Recriters id and that should show in the drop down if that id is fetched from the params then that should visible in the dropdown if in the drop down selects other rctr then need to fetch that recruiters data

  console.log("interviews", interviews);
  console.log("cardsdata",cardsData)

  return (
    <Main defaultSelectedKey="5">
      <div className="rctr-summery-container">
        <div style={{ display: "flex", flexDirection: "column", width: "75%" }}>
          <div style={{ marginBottom: "30px" }}>
            <RctrSummerCards cardsData={cardsData} />
            <div className="job-listing-container">
              <Table
                columns={columns}
                dataSource={tableData?.job_details}
                pagination={false}
                className="job-listing-table"
              />
            </div>
          </div>
          <InterviewCalendar  interviews={interviews}/>
        </div>
        {/* below code is   */}
        {/* <div className="recruiters-score-card">
      <h2 className="title">Total Resumes</h2>
      <div className="chart-container">
        <PieChart width={180} height={90}>
          <Pie
            data={data}
            cx="50%"
            cy="100%"
            startAngle={180}
            endAngle={0}
            innerRadius={50}
            outerRadius={80}
            paddingAngle={3}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
        <div className="total">Total <br /> {totalResumes}</div>
      </div>
      <div className="stats">
        {data.map((entry, index) => (
          <div key={index} className="stat">
            <span className="dot" style={{ backgroundColor: entry.color }}></span>
            <span className="label">{entry.name}</span>
            <span className="percentage">{entry.value}%</span>
          </div>
        ))}
      </div>
    </div> */}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="rctr-onproces-card-container">
            onProcessing
            <div className="rctr-onproces-card">
              <img
                src="https://randomuser.me/api/portraits/men/1.jpg" // Replace with actual image URL
                alt="Arjun"
                className="profile-img"
              />
              <div className="info">
                <h3 className="name">Arjun</h3>
                <p className="role">Sales Executive</p>
              </div>
              <span className="status">On Process</span>
            </div>
            <div className="rctr-onproces-card">
              <img
                src="https://randomuser.me/api/portraits/men/1.jpg" // Replace with actual image URL
                alt="Arjun"
                className="profile-img"
              />
              <div className="info">
                <h3 className="name">Arjun</h3>
                <p className="role">Sales Executive</p>
              </div>
              <span className="status">On Process</span>
            </div>
            {/* <RctrSumInterviewCard />
          <RctrSumInterviewCard /> */}
          </div>

          <div style={{marginTop:"30px"}}>
            <div className="rctr-sum-int-card">
              {interviews?.map((int) => {
                return (
                  <RctrSumInterviewCard
                    key={int.id}
                    cand={int.candidate.candidate_name}
                    interviewer={int.interviewer.name.username}
                    fromtime={int.from_time}
                    totime={int.to_time}
                    round={int.round_num}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default RecruiterSummery;
