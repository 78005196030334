import React from 'react'
import Main from '../Layout'
const Dashboard = () => {
  return (
    <Main defaultSelectedKey={1}>
      <div>
          fetch invoices
      </div>
    </Main>
  )
}

export default Dashboard