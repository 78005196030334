import React, { useEffect, useState } from 'react'
import "./TicketMessages.css"
import { useAuth } from '../common/useAuth';
import { message, Form, Modal, Input, Select, Upload } from 'antd';
import { SearchOutlined, UploadOutlined, PlusCircleOutlined } from '@ant-design/icons';
import SendFiles from "../../images/sendfile.svg"
import Send from "../../images/send.svg"

const { Option } = Select;

const AllTickets = ({ setTicketId }) => {

    const [isSent, setIsSent] = useState(true);
    const [sentList, setSentList] = useState([]);
    const [receivedList, setReceivedList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);

    const { token, apiurl } = useAuth();

    const fetchData = async () => {
        if (!token) return;

        setLoading(true);
        try {
            const response = await fetch(`${apiurl}/view-tickets/?isSent=${isSent}`, {
                method: 'GET',
                headers: { "Authorization": `Bearer ${token}` }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            if (data.error) {
                message.error(data.error);
            } else {
                if (isSent) setSentList(data);
                else setReceivedList(data);
            }
        } catch (e) {
            message.error("Failed to fetch tickets. Please try again.");
            console.error("Error fetching tickets:", e);
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (date) => {
        return new Date(date).toLocaleDateString()
    }

    useEffect(() => {
        fetchData();
    }, [token, isSent]);

    const filteredTickets = (isSent ? sentList : receivedList).filter(ticket =>
        (isSent ? ticket?.assigned_to : ticket?.raised_by)?.toLowerCase().includes(searchTerm.toLowerCase())
    );


    return (
        <div className="all-tickets">
            <div className="toggle">
                <span className={`toggle-item ${isSent ? "active" : ""}`} onClick={() => setIsSent(true)}>Tickets Sent</span>
                <span className={`toggle-item ${!isSent ? "active" : ""}`} onClick={() => setIsSent(false)}>Tickets Received</span>
            </div>

            <div className="search">
                <SearchOutlined />
                <input
                    type="text"
                    placeholder="Search here"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>

            {loading ? <p>Loading tickets...</p> : (
                <div className="tickets-list">
                    {filteredTickets.length > 0 ? (
                        filteredTickets.map((ticket, index) => (
                            <div key={index} className="ticket" onClick={() => setTicketId(ticket.id)}>
                                <div className="line1">
                                    <span className="name">{isSent ? <span>{ticket.assigned_to}</span> : <span>{ticket.raised_by}</span>}</span>
                                    {ticket.status == 'pending' &&
                                        <span className="status">{ticket.status}</span>
                                    }
                                    <span className="date">{formatDate(ticket.created_at)}</span>
                                </div>
                                <div className="line2">
                                    <span className="category">
                                        <span className="name">Category: </span>{ticket.category}
                                    </span>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No tickets found.</p>
                    )}
                </div>
            )}
        </div>
    );
}


const ParticularTicket = ({ ticket_id }) => {
    const [data, setData] = useState(null);
    const [replyText, setReplyText] = useState("");
    const [file, setFile] = useState(null);
    const { apiurl, token } = useAuth();
    console.log("ticket id is", ticket_id)

    const fetchData = async (id) => {
        try {
            const response = await fetch(`${apiurl}/view-tickets/?ticket_id=${id}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (data.error) {
                message.error(data.error);
                return;
            }
            setData(data);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (ticket_id) {
            fetchData(ticket_id);
        }
    }, [ticket_id]);

    const formatDate = (oldDate) => {
        let newDate = new Date(oldDate);
        let today = new Date();

        const isToday =
            newDate.getFullYear() === today.getFullYear() &&
            newDate.getMonth() === today.getMonth() &&
            newDate.getDate() === today.getDate();

        return isToday
            ? newDate.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true
            })
            : newDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
    };

    const sendReply = async () => {
        if (!replyText && !file) {
            message.error("Please enter a message or upload a file.");
            return;
        }

        const formData = new FormData();
        formData.append("ticket_id", ticket_id);
        if (replyText) formData.append("message", replyText);
        if (file) formData.append("attachment", file);

        try {
            const response = await fetch(`${apiurl}/ticket/send-reply/?ticket_id=${ticket_id}`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });
            const data = await response.json();

            if (data.error) {
                message.error(data.error);
            } else {
                message.success(data.message);
                setReplyText("");
                setFile(null);
                fetchData(ticket_id);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const updateStatus = async (ticket_id) => {
        try {
            const response = await fetch(`${apiurl}/ticket/update-status/?ticket_id=${ticket_id}`, {
                method: 'PUT',
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            const data = await response.json();
            if (data.error) {
                message.error(data.error)
            }
            else {
                fetchData(ticket_id);
            }
        }
        catch (e) {
            console.error(e)
        }
    }

    if (!ticket_id) {
        return <div className="empty-ticket">No ticket selected</div>;
    }

    return (
        <div className="particular-ticket">
            {data && (
                <>
                    <div className="line">
                        <div className="left">
                            <div className="name">
                                {data.assigned_to} <span className="id">id: {data.id}</span>
                            </div>
                            <span className="category">{data.category}</span>
                        </div>
                        <div className="right">
                            {data.status !== 'completed' &&
                                <div className="floating-notification">
                                    <p>Issue Resolved?</p>
                                    <button onClick={() => {
                                        updateStatus(data.id);
                                    }}>Yes</button>
                                </div>
                            }
                            <div className="statuss">
                                Status <span className={`${data.status === 'pending' ? "pending" : (data.status === 'completed' ? "completed" : "")} type`}>{data.status}</span>
                            </div>
                        </div>
                    </div>
                    <div className="replies-content">
                        <div className="content">
                            <div className="line1">
                                <span className="subject">
                                    Subject: <span className="value">{data.subject}</span>
                                </span>
                                <span className="date">{data.date}</span>
                            </div>
                            <div className="line2">{data.description}</div>

                            {data.attachments && (

                                <div className="attachments">
                                    <a
                                        href={`${apiurl}/${data.attachments}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    // download={reply.attachment}
                                    >
                                        Click to view
                                    </a>
                                </div>
                            )}
                        </div>
                        <div className="replies">
                            <div className="replies-list">
                                {data?.replies_list && data?.replies_list.map((reply, index) => (
                                    <div key={index} className="reply">
                                        <div className="line1">
                                            <span className="name">{reply.name}</span>
                                            <span className="date">{formatDate(reply.created_at)}</span>
                                        </div>
                                        <div className="line2">{reply.message}</div>
                                        {reply.attachment && (
                                            <div className="attachments">
                                                <a
                                                    href={`${apiurl}/${reply.attachment}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Click to view
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>


                    {data.status !== 'completed' &&

                        <div className="reply-box">
                            <textarea
                                placeholder="Type your reply..."
                                value={replyText}
                                onChange={(e) => setReplyText(e.target.value)}
                            />
                            <input
                                type="file"
                                id="file-upload"
                                style={{ display: "none" }}
                                onChange={(e) => setFile(e.target.files[0])}
                            />

                            <div className="another-div">

                                <img
                                    src={SendFiles}
                                    alt="Attach File"
                                    className="file-upload-icon"
                                    onClick={() => document.getElementById('file-upload').click()}
                                />
                                <button onClick={sendReply}>
                                    <img src={Send} alt="Send Reply" />
                                </button>
                            </div>

                        </div>
                    }

                </>
            )}
        </div>
    );
};


const TicketMessages = () => {
    const { apiurl, token } = useAuth()
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ticketId, setTicketId] = useState(null);
    const [isOtherSelected, setIsOtherSelected] = useState(false);

    const raiseTicket = async (values) => {

        try {
            const formData = new FormData();

            formData.append("category", values.category === "Other" ? values.customCategory : values.category);
            formData.append("description", values.description);

            if (values.attachments && values.attachments.length > 0) {
                formData.append("attachment", values.attachments[0].originFileObj);
            }

            const response = await fetch(`${apiurl}/view-tickets/`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
                body: formData
            });

            const result = await response.json();
            if (result.error) {
                message.error(result.error);
            } else {
                message.success("Ticket raised successfully!");
                setIsModalOpen(false);
                // fetchData(); // Refresh table data
                form.resetFields();
            }
        } catch (error) {
            message.error("Failed to raise ticket.");
        }
    };
    return (
        <div className='tickets'>
            <div className="line">
                <h2>Tickets</h2>
                <button onClick={() => setIsModalOpen(true)}> <PlusCircleOutlined></PlusCircleOutlined> Raise Ticket</button>
            </div>
            <div className="tickets-section">
                <AllTickets setTicketId={setTicketId} />
                <ParticularTicket ticket_id={ticketId} />
            </div>
            <Modal
                title="Raise a Ticket"
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={raiseTicket}
                >
                    <Form.Item
                        label="Category"
                        name="category"
                        rules={[{ required: true, message: "Please select a category" }]}
                    >
                        <Select placeholder="Select Category"
                            onChange={(value) => setIsOtherSelected(value === "Other")}
                        >
                            <Option value="Feature Request">Feature Request - Request for new functionality or improvements</Option>
                            <Option value="Support">Support - Issues with existing features or system errors</Option>
                            <Option value="Bug Report">Bug Report - Report system malfunctions or unexpected behavior</Option>
                            <Option value="Access Request">Access Request - Request permissions or account access</Option>
                            <Option value="Performance Issue">Performance Issue - Report slow loading or inefficiencies</Option>
                            <Option value="Integration Help">Integration Help - Assistance with API or third-party integrations</Option>
                            <Option value="Other">Other - Any issue not listed above</Option>
                        </Select>
                    </Form.Item>
                    {isOtherSelected && (
                        <Form.Item
                            label="Specify Your Request"
                            name="customCategory"
                            rules={[{ required: true, message: "Please specify your request type" }]}
                        >
                            <Input placeholder="Enter request type" />
                        </Form.Item>
                    )}
                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[{ required: true, message: "Please enter a description" }]}
                    >
                        <Input.TextArea rows={4} placeholder="Describe your issue in detail" />
                    </Form.Item>
                    <Form.Item
                        label="Attachments (Optional)"
                        name="attachments"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => e.fileList}
                    >
                        <Upload
                            beforeUpload={() => false}
                            listType="text"
                            maxCount={1}
                        >
                            <button type='button' icon={<UploadOutlined />}>Click to Upload</button>
                        </Upload>
                    </Form.Item>
                    <Form.Item>
                        <button htmlType="submit">Submit</button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default TicketMessages