import { SearchOutlined, SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons";
import { Button, Input, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../common/useAuth";
import Main from "../Layout";
import "./Applications.css";

const apiurl = process.env.REACT_APP_BACKEND_URL;

const Applications = () => {
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [total, setTotal] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize] = useState(10);
	const [sortOrder, setSortOrder] = useState("asc");
	const [searchTerm, setSearchTerm] = useState("");
	const navigate = useNavigate();
	const { token } = useAuth();

	const getData = async (page = 1) => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/client/get-resumes/?page=${page}&page_size=${pageSize}`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			const result = await response.json();
			setData(result.results);
			setFilteredData(result.results); // Initialize filtered data
			setTotal(result.count);
		} catch (error) {
			console.error("Failed to fetch data:", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (token) getData(currentPage);
	}, [token, currentPage]);

	// Navigate to resumes
	const handleResumes = (id) => {
		navigate(`/client/get-resumes/${id}`);
	};

	// Handle pagination
	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	// Handle sort
	const handleSort = () => {
		const sorted = [...filteredData].sort((a, b) => {
			const dateA = new Date(a.last_date);
			const dateB = new Date(b.last_date);
			return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
		});
		setFilteredData(sorted);
		setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"));
	};

	// Handle search
	const handleSearch = (e) => {
		const value = e.target.value.toLowerCase();
		setSearchTerm(value);
		const filtered = data.filter((job) =>
			job.job_title.toLowerCase().includes(value) ||
			(job.organization && job.organization.toLowerCase().includes(value))
		);
		setFilteredData(filtered);
	};

	return (
		<Main defaultSelectedKey="3">
			<div className="received-container">
				<div className="header-actions">
					<div className="search">
						<Input
							placeholder="Search Jobs"
							prefix={<SearchOutlined />}
							value={searchTerm}
							onChange={handleSearch}
							className="search-input"
						/>
					</div>
					<div className="sort">
						<Button
							icon={sortOrder === "asc" ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
							onClick={handleSort}
							className="sort-button"
						>
							Sort by Last Date
						</Button>
					</div>
				</div>

				<div className="table-wrapper">
					{loading ? (
						<div className="loading">Loading data...</div>
					) : filteredData?.length > 0 ? (
						<>
							<table className="received-table">
								<thead>
									<tr>
										<th>Job Title</th>
										<th>Organization</th>
										<th>Num Of Positions</th>
										<th>Last Date to Submit</th>
										<th>Applications Received</th>
										<th>View Resume</th>
									</tr>
								</thead>
								<tbody>
									{filteredData.map((job) => (
										<tr key={job.job_id}>
											<td>{job.job_title}</td>
											<td>{job.organization || "N/A"}</td>
											<td>{job.num_of_postings}</td>
											<td>{job.last_date}</td>
											<td>{job.applications_sent}</td>
											<td>
												<button className="view-resumes" onClick={() => handleResumes(job.job_id)}>
													View Resumes
												</button>
											</td>
										</tr>
									))}
								</tbody>
							</table>

							<Pagination
								current={currentPage}
								pageSize={pageSize}
								total={total}
								onChange={handlePageChange}
								style={{ marginTop: 20, textAlign: "center" }}
							/>
						</>
					) : (
						<div className="no-applications">There are no applications</div>
					)}
				</div>
			</div>
		</Main>
	);
};

export default Applications;
