import React, { useState, useEffect } from "react";
import { Button, Table, Modal, Input, notification } from "antd";
import Main from "../Layout";
import { useAuth } from "../../../common/useAuth";

const SeeNegotiations = () => {
	const [data, setData] = useState([]);
	const [rejectReason, setRejectReason] = useState("");
	const [selectedRecord, setSelectedRecord] = useState(null);
	const { apiurl, token } = useAuth();

	useEffect(() => {
		fetchNegotiations();
	}, []);

	const fetchNegotiations = async () => {
		try {
			const response = await fetch(`${apiurl}/negotiate-terms/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			setData(data);
		} catch (error) {
			console.error("Error fetching negotiation requests:", error);
		}
	};

	const handleAccept = (record) => {
		Modal.confirm({
			title: "Are you sure you want to accept this negotiation?",
			onOk: async () => {
				try {
					const response = await fetch(`${apiurl}/negotiate-terms/`, {
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
						body: JSON.stringify({
							status: "accepted",
							id: record.id,
						}),
					});
					if (!response.ok) {
						throw new Error("Failed to accept negotiation");
					}
					notification.success({
						message: "Negotiation accepted successfully!",
					});
					fetchNegotiations();
				} catch (error) {
					notification.error({ message: "Error accepting negotiation" });
					console.error(error);
				}
			},
		});
	};

	const handleReject = (record) => {
		setSelectedRecord(record);
		Modal.confirm({
			title: "Are you sure you want to reject this negotiation?",
			onOk: () => {
				if (!rejectReason) {
					notification.warning({
						message: "Please provide a reason for rejection.",
					});
					return;
				}

				rejectNegotiation(record);
			},
		});
	};

	const rejectNegotiation = async (record) => {
		try {
			const response = await fetch(`${apiurl}/negotiate-terms/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					status: "rejected",
					id: record.id,
					reason: rejectReason,
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to reject negotiation");
			}
			notification.success({ message: "Negotiation rejected successfully!" });
			setRejectReason("");
			fetchNegotiations();
		} catch (error) {
			notification.error({ message: "Error rejecting negotiation" });
			console.error(error);
		}
	};

	const handleRejectReasonChange = (e) => {
		setRejectReason(e.target.value);
	};

	const columns = [
		{
			title: "Client Name",
			dataIndex: ["client", "username"],
			key: "clientName",
		},
		{
			title: "Client Organization",
			dataIndex: ["client", "name_of_organization"],
			key: "clientOrganization",
		},
		{
			title: "Service Fee",
			dataIndex: "service_fee",
			key: "serviceFee",
		},
		{
			title: "Replacement Clause (days)",
			dataIndex: "replacement_clause",
			key: "replacementClause",
		},
		{
			title: "Invoice After (days)",
			dataIndex: "invoice_after",
			key: "invoiceAfter",
		},
		{
			title: "Payment Within (days)",
			dataIndex: "payment_within",
			key: "paymentWithin",
		},
		{
			title: "Interest Percentage",
			dataIndex: "interest_percentage",
			key: "interestPercentage",
		},
		{
			title: "Requested Date",
			dataIndex: "requested_date",
			key: "requestedDate",
			render: (text) => new Date(text).toLocaleString(),
		},
		{
			title: "Status",
			dataIndex: "is_accepted",
			key: "status",
			render: (isAccepted, record) => {
				if (isAccepted) {
					return "Accepted";
				} else {
					return (
						<span>
							<Button
								type="primary"
								style={{ marginRight: 8 }}
								onClick={() => handleAccept(record)}>
								Accept
							</Button>
							<Button type="danger" onClick={() => handleReject(record)}>
								Reject
							</Button>
						</span>
					);
				}
			},
		},
	];

	return (
		<Main defaultSelectedKey="2">
			<div style={{ width: 1350, display: "inline" }}>
				{data && data.length > 0 ? (
					<Table columns={columns} dataSource={data} rowKey="id" />
				) : (
					<p>There are no Negotiations</p>
				)}
			</div>

			<Modal
				title="Provide a Reason for Rejection"
				open={selectedRecord}
				onCancel={() => setSelectedRecord(null)}
				onOk={() => handleReject(selectedRecord)}
				okText="Submit">
				<Input
					placeholder="Enter reason for rejection"
					value={rejectReason}
					onChange={handleRejectReasonChange}
				/>
			</Modal>
		</Main>
	);
};

export default SeeNegotiations;
