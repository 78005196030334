import React from 'react';
import { Tabs } from 'antd';
import ScheduledInterviews from './ScheduledInterviews';
import CompletedInterviews from './CompletedInterviews';
import MissedInterviews from './MissedInterviews';
import Main from '../Layout';

const { TabPane } = Tabs;

const Interviews = () => {
    return (
        <Main defaultSelectedKey="2" style={{ padding: '20px' }}>
            <Tabs defaultActiveKey="1" centered>
                <TabPane tab="Scheduled" key="1">
                    <ScheduledInterviews />
                </TabPane>
                <TabPane tab="Completed" key="2">
                    <CompletedInterviews />
                </TabPane>
                <TabPane tab="Missed" key="3">
                    <MissedInterviews />
                </TabPane>
            </Tabs>
        </Main>
    );
};

export default Interviews;
