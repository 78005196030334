import React, { useState, useEffect } from "react";
import { useAuth } from "../../../common/useAuth";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import Table from "../../../common/Table";
import "./ScheduledInterviews.css";

const apiurl = process.env.REACT_APP_BACKEND_URL;

const CompletedInterviews = () => {
    const { token } = useAuth();
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);


    const navigate = useNavigate();

    const fetchData = async (page = 1) => {
        try {
            const response = await fetch(
                `${apiurl}/interviewer/completed-interviews/?page=${page}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const data = await response.json();
            if (data.error) {
                message.error(data.error);
                return;
            }
            setData(data.results);
            setTotal(data.count);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (token) {
            fetchData(currentPage);
        }
    }, [token, currentPage]);


    const columns = [
        {
            title: "Job Title",
            dataIndex: "job_title",
            key: "job_title",
            render: (title) => (
                <div style={{ color: "#2C5F99", fontWeight: "600" }}>
                    {title}
                </div>
            )
        },
        {
            title: "Rounds Number",
            dataIndex: "round_num",
            key: "round_num",
        },
        {
            title: "Mode of Interview",
            dataIndex: "mode_of_interview",
            key: "mode_of_interview",
        },
        {
            title: "Candidate Name",
            dataIndex: "candidate_name",
            key: "candidate_name",
        },
        {
            title: "Scheduled Time",
            dataIndex: "scheduled_date",
            key: "scheduled_date",
            render: (text) => new Date(text).toLocaleDateString(),
            sorter: (a, b) => new Date(a.schedule_date) - new Date(b.schedule_date),
            sortDirections: ['ascend', 'descend'],
        },
    ];

    return (
        <div defaultSelectedKey="2">
            {data && (
                <div className="custom-table-scheduled-interviews">
                    <Table
                        columns={columns}
                        data={data}
                        pagination={{
                            current: currentPage,
                            pageSize: pageSize,
                            total: total, // Total interviews count for pagination
                            onChange: (page, pageSize) => {
                                setCurrentPage(page);
                                setPageSize(pageSize);
                            },
                            showSizeChanger: true,
                            pageSizeOptions: ['5', '10', '20', '50'],
                        }}
                        expandable={{
                            expandedRowRender: (record) => (
                                <div className="expandable-review">
                                    <div>
                                        <strong>Primary Skills Rating:</strong> {record.primary_skills_rating}
                                    </div>
                                    <div>
                                        <strong>Secondary Skills Rating:</strong> {record.secondary_skills_rating}
                                    </div>
                                    <div>
                                        <strong>Remarks:</strong> {record?.remarks || "No Remarks"}
                                    </div>

                                </div>
                            ),
                        }}
                    />
                </div>
            )}

        </div>
    );
};

export default CompletedInterviews;
