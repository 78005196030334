import React from 'react'
import { RightOutlined } from '@ant-design/icons'
import Logo from "../../images/GAHIRESYNC-LOGO.svg"
import { XOutlined, GlobalOutlined, InstagramOutlined, FacebookOutlined, LinkedinOutlined } from '@ant-design/icons'
import "./Footer.css"

const Footer = () => {
    return (
        <div className='footer'>
            <div className="line1">
                <span>Work Easy</span>
                <span>Grow Further with <span className="pink">Hire Sync</span></span>
                <div className="button">
                    <button>
                        Request a demo <RightOutlined></RightOutlined>
                    </button>
                </div>
                <div className="small-line" />

            </div>


            <div className="section2">
                <div className="social-media">
                    <img src={Logo} alt="" />
                    <div className="links">
                        <XOutlined />
                        <FacebookOutlined />
                        <InstagramOutlined />
                        <LinkedinOutlined />
                    </div>
                </div>
                <div className="menuu">
                    <div className="menu-items how-it-works">
                        <span className="name">How it works</span>
                        <div className="list">
                            <span>Hire Employees</span>
                            <span>Hire Contractors</span>
                            <span>Run Global Payroll</span>
                            <span>App Store</span>
                            <span>Open API</span>
                        </div>
                    </div>
                    <div className="menu-items solutions">
                        <span className="name">Solutions</span>
                        <div className="list">
                            <span>Compilance</span>
                            <span>Payments</span>
                            <span>For Finance Teams</span>
                            <span>For Legal Teams</span>
                            <span>For Hiring Managers</span>
                            <span>Startup</span>
                            <span>Enterprise</span>
                        </div>
                    </div>
                    <div className="menu-items resources">
                        <span className="name">Resources</span>
                        <div className="list">
                            <span>About</span>
                            <span>Blog</span>
                            <span>Support Hub</span>
                            <span>Global Hiring Guide</span>
                            <span>Partner Program</span>
                            <span>Affiliates</span>
                            <span>Case Studies</span>
                            <span>Careers</span>
                            <span>Glossary</span>
                            <span>Press</span>
                            <span>Service status</span>
                            <span>Worker Community</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section3">
                <div className="lang">
                    <GlobalOutlined />   English
                </div>
                <div className="last">
                    <span className="text">© Copyright 2024. All Rights Reserved.</span>
                    <div className="list">
                        <span>Privacy Policy</span>
                        <span>Terms of Service</span>
                        <span>Whistleblower Policy</span>
                        <span>Cookie Policy</span>
                        <span>Cookie Settings</span>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Footer