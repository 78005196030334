import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, message, Input } from 'antd';
import { useAuth } from '../../../common/useAuth';
import { SearchOutlined } from '@ant-design/icons';

const CandidatesLeft = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [confirmModal, setConfirmModal] = useState({ visible: false, record: null });
    const { apiurl, token } = useAuth();

    const fetchData = async () => {
        try {
            const response = await fetch(`${apiurl}/client/candidate-left/`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const result = await response.json();
            if (result.error) {
                message.error(result.error);
            } else {
                const sortedData = result.sort((a, b) => new Date(a.joining_date) - new Date(b.joining_date));
                setData(sortedData);
                setFilteredData(sortedData);
            }
        } catch (e) {
            message.error("Failed to fetch data.");
        }
    };

    useEffect(() => {
        if (token) {
            fetchData();
        }
    }, [token]);

    const handleSearch = (value) => {
        setSearchText(value);
        const filtered = data.filter(item =>
            item.candidate_name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredData(filtered);
    };

    const handleConfirmReplacement = async () => {
        if (!confirmModal.record) return;

        try {
            const response = await fetch(`${apiurl}/client/request-replacement/`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ candidate_id: confirmModal.record.candidate_id })
            });

            const result = await response.json();
            if (result.error) {
                message.error(result.error);
            } else {
                message.success("Replacement request submitted successfully.");
                fetchData();  // Refresh the data after replacement request
            }
        } catch (e) {
            message.error("Failed to submit replacement request.");
        }

        setConfirmModal({ visible: false, record: null });
    };

    const columns = [
        {
            title: 'Candidate Name',
            dataIndex: 'candidate_name',
            key: 'candidate_name',
        },
        {
            title: 'Job Title',
            dataIndex: 'job_title',
            key: 'job_title',
        },
        {
            title: 'Joining Date',
            dataIndex: 'joining_date',
            key: 'joining_date',
        },
        {
            title: 'Left Reason',
            dataIndex: 'left_reason',
            key: 'left_reason',
        },
        {
            title: 'Left Date',
            dataIndex: 'left_date',
            key: 'left_date',
        },
        {
            title: 'Replacement Eligible',
            dataIndex: 'is_replacement_eligible',
            key: 'is_replacement_eligible',
            render: (eligible) => (eligible ? 'Yes' : 'No'),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (record) =>
                record.is_replacement_eligible ? (
                    record.replacement_status == "no" ? (
                        <Button
                            type="primary"
                            onClick={() => setConfirmModal({ visible: true, record })}
                        >
                            Request Replacement
                        </Button>
                    ) : (
                        <span>{record.replacement_status}</span>
                    )

                ) : (
                    <span style={{ color: 'gray' }}>Not Eligible</span>
                ),
        },
    ];

    return (
        <div>

            <div className="custom-search">
                <SearchOutlined />
                <input
                    placeholder="Search by Candidate Name or Job Title"
                    value={searchText}
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ marginBottom: 16, width: 300 }}
                    className='custom-search'
                />
            </div>

            <Table dataSource={filteredData} columns={columns} rowKey="candidate_name" />

            {/* Confirmation Modal */}
            <Modal
                title="Confirm Replacement Request"
                visible={confirmModal.visible}
                onOk={handleConfirmReplacement}
                onCancel={() => setConfirmModal({ visible: false, record: null })}
            >
                <p>Are you sure you want to request a replacement for {confirmModal.record?.candidate_name}?</p>
            </Modal>
        </div>
    );
};

export default CandidatesLeft;
