import React, { useState, useEffect } from 'react';
import './Table.css';
import NoData from "../../images/empty.svg";

/**
 * Reusable Table Component with Pagination, Sorting, and Expandable Rows
 * @param {Array} columns - Table columns
 * @param {Array} data - Table data
 * @param {Object} pagination - Pagination configuration
 * @param {Boolean} loading - Loading state
 * @param {Object} expandable - { expandedRowRender: Function, rowExpandable: Function }
 */
const Table = ({ columns = [], data = [], pagination = {}, loading = false, expandable = {} }) => {
    const [sortedData, setSortedData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
    const [expandedRowKeys, setExpandedRowKeys] = useState([]); // Track expanded rows

    // Handle sorting
    const handleSort = (col) => {
        if (!col.sorter) return;

        let direction = 'ascend';
        if (sortConfig.key === col.key && sortConfig.direction === 'ascend') {
            direction = 'descend';
        }

        const sortedArray = [...data].sort((a, b) => {
            if (typeof col.sorter === 'function') {
                return col.sorter(a, b);
            } else {
                if (a[col.dataIndex] < b[col.dataIndex]) return direction === 'ascend' ? -1 : 1;
                if (a[col.dataIndex] > b[col.dataIndex]) return direction === 'ascend' ? 1 : -1;
                return 0;
            }
        });

        setSortedData(sortedArray);
        setSortConfig({ key: col.key, direction });
    };

    useEffect(() => {
        setSortedData(data);
    }, [data]);

    // Pagination
    // const { current = 1, pageSize = 5, total = 0, onChange } = pagination;
    // const startIndex = (current - 1) * pageSize;
    // const endIndex = startIndex + pageSize;
    // const paginatedData = sortedData.slice(startIndex, endIndex);

    let paginatedData = sortedData; // Default: no pagination

    if (pagination && pagination !== false) {
        const { current = 1, pageSize = 5 } = pagination;
        const startIndex = (current - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        paginatedData = sortedData.slice(startIndex, endIndex);
    }

    // Handle row expand/collapse
    const handleExpand = (key) => {
        if (expandedRowKeys.includes(key)) {
            setExpandedRowKeys(expandedRowKeys.filter(k => k !== key));
        } else {
            setExpandedRowKeys([...expandedRowKeys, key]); // Only one expanded at a time, change to [...expandedRowKeys, key] for multiple
        }
    };

    return (
        <div className="custom-table-container">
            <table className="custom-table">
                <thead>
                    <tr>
                        {columns.map((col) => (
                            <th key={col.key} onClick={() => handleSort(col)} style={{ cursor: col.sorter ? 'pointer' : 'default' }}>
                                {col.title}
                                {sortConfig.key === col.key && (
                                    <span>{sortConfig.direction === 'ascend' ? ' 🔼' : ' 🔽'}</span>
                                )}
                            </th>
                        ))}
                        {expandable.expandedRowRender && <th></th>} {/* For expand button */}
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan={columns.length + 1} className="no-data">
                                <span className="loader"></span> Loading...
                            </td>
                        </tr>
                    ) : paginatedData.length > 0 ? (
                        paginatedData.map((item, idx) => {
                            const rowKey = item.id || item.key || idx; // You can adjust rowKey as per your data
                            const isExpanded = expandedRowKeys.includes(rowKey);
                            return (
                                <React.Fragment key={rowKey}>
                                    <tr>
                                        {columns.map((col) => (
                                            <td key={col.key}>
                                                {col.render ? col.render(item[col.dataIndex], item) : item[col.dataIndex]}
                                            </td>
                                        ))}
                                        {expandable.expandedRowRender && (
                                            <td>
                                                <button
                                                    onClick={() => handleExpand(rowKey)}
                                                    className="expand-btn"
                                                >
                                                    {isExpanded ? 'Hide' : 'View Remarks'}
                                                </button>
                                            </td>
                                        )}
                                    </tr>
                                    {isExpanded && (
                                        <tr className="expanded-row">
                                            <td colSpan={columns.length + 1}>
                                                {expandable.expandedRowRender(item)}
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={columns.length + 1} className="no-data">
                                <img src={NoData} alt="No data" />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* Pagination Controls */}
            {pagination && pagination !== false && (
                <div className="pagination-container">
                    <button
                        style={{ color: "black" }}
                        onClick={() => pagination.onChange(pagination.current - 1, pagination.pageSize)}
                        disabled={pagination.current === 1}
                    >
                        Previous
                    </button>
                    <span>
                        Page {pagination.current} of {Math.ceil(pagination.total / pagination.pageSize)}
                    </span>
                    <button
                        onClick={() => pagination.onChange(pagination.current + 1, pagination.pageSize)}
                        style={{ color: "black" }}
                        disabled={pagination.current >= Math.ceil(pagination.total / pagination.pageSize)}
                    >
                        Next
                    </button>
                </div>
            )}
        </div>
    );
};

export default Table;
