// import React, { useState } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import { Layout, Menu } from "antd";
// import {
//     LogoutOutlined,
//     ProfileOutlined,
//     AppstoreOutlined,
//     UnorderedListOutlined
// } from "@ant-design/icons";
// import "./Layout.css";
// import logo from "./../../../images/GAHIRESYNC-LOGO.svg"
// import { useAuth } from "../../common/useAuth";

// const { Header, Sider } = Layout;

// const Main = ({ defaultSelectedKey, children }) => {
//     const location = useLocation();
//     const navigate = useNavigate();
//     const { handleLogout } = useAuth();

//     const [openKeys, setOpenKeys] = useState([]);

//     const defaultSelectedKeys = () => {
//         const pathname = location.pathname;

//         const menuItems = [
//             "/",
//             "/candidate/applications",
//             "/candidate/upcoming_interviews",
//             "/candidate/profile",
//         ];

//         const index = menuItems.findIndex((item) => item === pathname);
//         if (index === -1) return ["1"];
//         return [`${index + 1}`];
//     };

//     const handleMenuItemClick = ({ key, action }) => {
//         if (action === "logout") {
//             Logout();
//         }
//         setOpenKeys(openKeys.includes(key) ? [] : [key]);
//     };

//     const Logout = () => {
//         console.log("logout");
//         handleLogout();
//         sessionStorage.removeItem("authToken");
//         navigate("/login");
//     };

//     const menuItems = [
//         {
//             key: "1",
//             icon: <AppstoreOutlined />,
//             label: <Link to="/">Dashboard</Link>,
//         },
//         {
//             key: "2",
//             icon: <AppstoreOutlined />,
//             label: <Link to="/candidate/applications">Applications</Link>,
//         },
//         {
//             key: "3",
//             icon: <UnorderedListOutlined />,
//             label: <Link to="/candidate/upcoming_interviews">Interviews</Link>,
//         },
//         {
//             key: "4",
//             icon: <ProfileOutlined />,
//             label: <Link to='/candidate/profile'>Profile</Link>
//         },
//         {
//             key: "5",
//             icon: <ProfileOutlined />,
//             label: <Link to='/candidate/selected_jobs'>Selected Jobs</Link>
//         },
//         {
//             key: "5",
//             icon: <LogoutOutlined />,
//             label: <div onClick={Logout}>Logout</div>,
//         },
//     ];

//     return (
//         <Layout>
//             <Sider
//                 className="side"
//                 breakpoint="md"
//                 collapsedWidth="0"
//                 width={"225px"}
//                 style={{
//                     height: "calc(100vh - 100px)",
//                     position: "fixed",
//                     left: "0",
//                     top: "100px",
//                     bottom: 0,
//                     zIndex: 1,
//                     background: "#fff",
//                 }}>
//                 <Menu
//                     theme="light"
//                     defaultSelectedKeys={[defaultSelectedKey || defaultSelectedKeys()]}
//                     openKeys={openKeys}
//                     onOpenChange={(keys) => setOpenKeys(keys)}
//                     onClick={handleMenuItemClick}
//                     className="menu"
//                     mode="inline"
//                     items={menuItems}></Menu>
//             </Sider>

//             <Layout>
//                 <Header className="head" style={{ padding: 0 }}>
//                     <div className="header-logo">
//                         <a href="/home">
//                             <img alt="logo" src={logo}></img>
//                         </a>
//                     </div>
//                     <div className="header-buttons"></div>
//                 </Header>
//                 <div className="content">{children}</div>
//             </Layout>
//         </Layout>
//     );
// };

// export default Main;



import React from 'react'
import MainLayout from '../../common/Layout/MainLayout'
import dashboard from "./../../../images/Dashboard.svg"
import Interviews from "./../../../images/Interviews.svg"
import HalfFile from "./../../../images/HalfFile.svg"
import File3 from "./../../../images/file3.svg"

const Layout = ({ children, defaultSelectedKey }) => {
    const cilentoptions = [
        {
            "key": 1,
            "label": "Dashboard",
            "logo": dashboard,
            "path": '/'
        },
        {
            "key": 2,
            "label": "Applications",
            "logo": HalfFile,
            "path": '/candidate/applications'
        },
        {
            "key": 3,
            "label": "Interviews",
            "logo": Interviews,
            "path": '/candidate/upcoming_interviews'
        },
        {
            "key": 4,
            "label": "Selected Applications",
            "logo": File3,
            "path": '/candidate/selected_jobs',
        },
    ]

    return (
        <>
            <MainLayout children={children} defaultSelectedKey={defaultSelectedKey} options={cilentoptions}></MainLayout>
        </>
    )
}

export default Layout
