import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Modal, Spin } from "antd";
import { useAuth } from "../../../common/useAuth";
import Main from "../Layout";
import "./TermsAndConditions.css";

const OrganizationTerms = () => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [termsData, setTermsData] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const [htmlContent, setHtmlContent] = useState(""); // Store HTML content
	const { apiurl, token } = useAuth();

	useEffect(() => {
		if (token) {
			fetchTerms();
		}
	}, [token]);

	const fetchTerms = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/organization-terms/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});

			if (!response.ok) {
				throw new Error("Failed to fetch organization terms");
			}

			const contentType = response.headers.get("Content-Type");
			if (contentType.includes("text/html")) {
				const html = await response.text();
				setHtmlContent(html);
			} else {
				const data = await response.json();
				setTermsData(data);
			}
		} catch (error) {
			message.error(error.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (termsData) {
			form.setFieldsValue(termsData); // Set form values after updating `termsData`
		}
	}, [termsData]);

	const fetchTermsValues = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/organization-terms/?values=${true}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});

			if (!response.ok) {
				throw new Error("Failed to fetch organization terms");
			}

			const data = await response.json();
			setTermsData(data.data);
		} catch (error) {
			message.error(error.message);
		} finally {
			setLoading(false);
		}
	}

	const handleUpdate = async (values) => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/organization-terms/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});

			if (!response.ok) {
				throw new Error("Failed to update organization terms");
			}

			message.success("Organization terms updated successfully!");
			await fetchTerms();
			setIsEditing(false);
		} catch (error) {
			message.error(error.message);
		} finally {
			setLoading(false);
		}

	};

	return (
		<Main>
			<div className="organization-terms-container">
				<h1 className="organization-terms-heading">Organization Terms</h1>

				{loading ? (
					<Spin size="large" />
				) : (
					<div>
						<div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
						<Button
							type="primary"
							className="edit-terms-button"
							onClick={async () => {
								await fetchTermsValues(); // Ensure latest data is fetched
								setIsEditing(true); // Open modal only after fetching
							}}>
							Edit Terms
						</Button>

					</div>
				)}

				{termsData && (
					<>
						<Modal
							title="Edit Organization Terms"
							open={isEditing}
							onCancel={() => { setIsEditing(false); }}
							footer={null}
							width={600}>
							<Form form={form} layout="vertical" onFinish={handleUpdate}>
								<Form.Item
									label="Service Fee"
									name="service_fee"
									rules={[{ required: true, message: "Please input the service fee!" }]}>
									<Input type="number" />
								</Form.Item>

								<Form.Item
									label="Replacement Clause"
									name="replacement_clause"
									rules={[{ required: true, message: "Please input the replacement clause!" }]}>
									<Input.TextArea rows={4} />
								</Form.Item>

								<Form.Item
									label="Invoice After"
									name="invoice_after"
									rules={[{ required: true, message: "Please input the invoice period!" }]}>
									<Input />
								</Form.Item>

								<Form.Item
									label="Payment Within"
									name="payment_within"
									rules={[{ required: true, message: "Please input the payment period!" }]}>
									<Input />
								</Form.Item>

								<Form.Item
									label="Interest Percentage"
									name="interest_percentage"
									rules={[{ required: true, message: "Please input the interest percentage!" }]}>
									<Input type="number" />
								</Form.Item>

								<div style={{ textAlign: "right" }}>
									<Button type="primary" htmlType="submit" loading={loading}>
										Update Terms
									</Button>
								</div>
							</Form>
						</Modal>
					</>
				)}
			</div>
		</Main>
	);
};

export default OrganizationTerms;
