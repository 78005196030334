import React, { useEffect, useState } from 'react';
import "./ViewInterviewer.css";
import { useAuth } from '../../../common/useAuth';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import Main from "../Layout";
import Profile from "../../../../images/Client/profile.png";
import Note from "../../../../images/Client/note.svg";
import Table from '../../../common/Table';
import { SearchOutlined } from '@ant-design/icons';

const ViewInterviewer = () => {
    const { apiurl, token } = useAuth();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [interviewerDetails, setInterviewerDetails] = useState();
    const { id } = useParams();
    const [searchTerm, setSearchTerm] = useState('');

    const fetchData = async () => {
        const response = await fetch(`${apiurl}/client/get-interviewers/?interviewer_id=${id}`, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        const data = await response.json();
        if (data.error) {
            message.error(data.error);
        } else {
            setData(data.interviews);
            setFilteredData(data.interviews);
            setInterviewerDetails(data);
        }
    };

    const columns = [
        { title: "Scheduled Date", dataIndex: "scheduled_date", key: "scheduled_date" },
        { title: "Job Title", dataIndex: "job_title", key: "job_title" },
        { title: "Candidate Name", dataIndex: "candidate_name", key: "candidate_name" },
        { title: "Agency Name", dataIndex: "agency_name", key: "agency_name" },
        { title: "Type of Interview", dataIndex: "type_of_interview", key: "type_of_interview" },
        { title: "Mode of Interview", dataIndex: "mode_of_interview", key: "mode_of_interview" },
        { title: "Round No", dataIndex: "round_num", key: "round_num" },
        {
            title: "Status",
            dataIndex: "interview_status",
            key: "interview_status",
            render: (status) => {
                const statusColors = {
                    "pending": "orange",
                    "completed": "#1681FF0D",
                    "Rejected": "red",
                    "Selected": "blue"
                };

                const textColor = {
                    "pending": "white",
                    "completed": "#1681FF"
                };

                const backgroundColor = statusColors[status] || "white";
                const color = textColor[status] || "#16151C";

                return (
                    <div
                        style={{
                            backgroundColor,
                            color,
                            padding: "4px 8px",
                            borderRadius: "12px",
                            textAlign: "center",
                            minWidth: "80px",
                            fontSize: "12px",
                            fontWeight: 500,
                            display: "inline-block"
                        }}
                    >
                        {status}
                    </div>
                );
            }
        },
    ];

    useEffect(() => {
        if (token) {
            fetchData();
        }
    }, [token]);

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchTerm(value);

        const filtered = data.filter(item =>
            item.job_title?.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredData(filtered);
    };

    return (
        <Main defaultSelectedKey="6">
            {interviewerDetails &&
                <div className="interviewer-details">
                    <div className="heading">{interviewerDetails.interviewer_name}</div>
                    <div className="profile">
                        <img src={Profile} alt="" />
                        <div className="name-email">
                            <span className='name'>{interviewerDetails.interviewer_name}</span>
                            <span className="email">{interviewerDetails.interviewer_email}</span>
                        </div>
                        <div className="pending note"><img src={Note} alt="" />{interviewerDetails.alloted} Rounds Alloted</div>
                        <div className="scheduled note"><img src={Note} alt="" />{interviewerDetails.scheduled} Scheduled</div>
                        <div className="completed note"><img src={Note} alt="" /> {interviewerDetails.completed} Completed</div>
                    </div>
                </div>
            }
            {filteredData &&
                <div className="table-component">
                    <div className="search-option">
                        <SearchOutlined />
                        <input
                            type="text"
                            placeholder='Search by Job Title'
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div>
                    <div className="table">
                        <Table columns={columns} data={filteredData} pagination={false} />
                    </div>
                </div>
            }
        </Main>
    );
};

export default ViewInterviewer;
