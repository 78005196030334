import React, { useEffect, useState } from 'react';
import { Table, message, Modal } from 'antd';
import { useAuth } from '../../../common/useAuth';
import { SearchOutlined } from '@ant-design/icons';
import "./ShortlistedCandidates.css"
import { render } from '@testing-library/react';
import { useNavigate } from 'react-router-dom';

const ShortlistedCandidates = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [confirmModal, setConfirmModal] = useState({ visible: false, record: null });
    const { apiurl, token } = useAuth();
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const response = await fetch(`${apiurl}/client/shortlisted-candidates/`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const result = await response.json();
            if (result.error) {
                message.error(result.error);
            } else {
                const sortedData = result.sort((a, b) => new Date(a.joining_date) - new Date(b.joining_date));
                setData(sortedData);
                setFilteredData(sortedData);
            }
        } catch (e) {
            message.error("Failed to fetch data.");
        }
    };

    useEffect(() => {
        if (token) {
            fetchData();
        }
    }, [token]);

    const handleSearch = (value) => {
        setSearchText(value);
        const filtered = data.filter(item =>
            item.candidate_name.toLowerCase().includes(value.toLowerCase()) ||
            item.job_title.toString().toLowerCase().includes(value.toLowerCase())
        );
        setFilteredData(filtered);
    };

    const confirmJoining = async (record) => {
        try {
            const response = await fetch(`${apiurl}/client/candidate-joined/?candidate_id=${record.selected_candidate_id}`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ joining_status: "joined" })
            });
            const result = await response.json();
            if (result.error) {
                message.error(result.error);
            } else {
                message.success("Candidate confirmed as joined.");
                fetchData();
            }
        } catch (e) {
            message.error("Failed to update joining status.");
        }
    };

    const columns = [

        {
            title: 'Candidate Name',
            dataIndex: 'candidate_name',
            key: 'candidate_name',
            render: (candidate, record) => (
                <span style={{ cursor: 'pointer', color: '#171A1F', fontWeight: '600' }} className="name" onClick={() => (navigate(`/client/candidates/${record.application_id}`))}>{candidate}</span>
            )
        },
        {
            title: 'Job Title',
            dataIndex: 'job_title',
            key: 'job_title',
        },
        {
            title: 'Agency',
            dataIndex: 'agency',
            key: 'agency',
        },
        {
            title: 'Current Round ',
            dataIndex: 'current_status',
            key: 'current_status',
        },
        {
            title: 'Next Interview',
            dataIndex: 'next_interview',
            key: 'next_interview',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <div className="actions">
                    <button className='failure' style={{ padding: '5px 10px' }} onClick={() => setConfirmModal({ visible: true, record })}>
                        Reject
                    </button>
                    <button
                        style={{ padding: '5px 10px' }}
                        className='success'
                    >Promote
                    </button>

                </div>
            )
        }
    ];

    return (
        <div>
            <div className="custom-search">
                <SearchOutlined />
                <input
                    placeholder="Search by Candidate Name or Job Title"
                    value={searchText}
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ marginBottom: 16, width: 300 }}
                    className='custom-search'
                />
            </div>
            <Table dataSource={filteredData} columns={columns} rowKey="selected_candidate_id" />
            <Modal
                title="Confirm Joining"
                visible={confirmModal.visible}
                onOk={() => confirmJoining(confirmModal.record)}
                onCancel={() => setConfirmModal({ visible: false, record: null })}
            >
                <p>Are you sure this candidate has joined?</p>
            </Modal>
        </div>
    );
};

export default ShortlistedCandidates;
