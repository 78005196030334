import React, { useState, useEffect } from 'react';
import { message, Modal, Upload, List, Button, Typography } from 'antd';
import { UploadOutlined, EyeOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../common/useAuth';
import ViewJobPost from '../../../common/ViewJobPost';
import Main from "../Layout";
import ResumeScoreCard from '../Scorecard/ResumeScoreCard';
import UploadData from '../AddingCandidates/UploadData';
import "./SendApplication.css"


const SendApplication = () => {
    const [jobModalVisible, setJobModalVisible] = useState(false);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const { apiurl, token } = useAuth();
    const [fileList, setFileList] = useState([]);

    // ✅ Added missing state variables
    const [job, setJob] = useState(null);
    const [interviewers, setInterviewers] = useState([]);
    const [summary, setSummary] = useState(null);
    const [resumes, setResumes] = useState(0);
    const [openQandA, setOpenQandA] = useState(false);
    const [QandALoading, setQandALoading] = useState(false);
    const [openResumeScore, setOpenResumeScore] = useState(false);
    const [resumeScore, setResumeScore] = useState(0);
    const [resume, setResume] = useState(null);
    const [scoreLoading, setScoreLoading] = useState(false);
    // const [score, setScore] = useState();
    const [addApplication, setAddApplication] = useState(false)
    const [QandA, setQandA] = useState(null);
    const [uploading, setUploading] = useState(false);

    const fetchJobDetails = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiurl}/job-details/recruiter/${id}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setJob(data.jd);
            setInterviewers(data.jd.interview_details || []);
            setSummary(data.summary || null);
            setResumes(data.count || 0);
        } catch (error) {
            console.error("Error fetching job details:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            fetchJobDetails();
        }
    }, [id]);

    useEffect(() => {
        setResumeScore(null);
    }, [resume])

    const generateResumeScore = async () => {
        if (!resume) {
            message.error("Please upload the resume first!");
            return;
        }

        setScoreLoading(true);
        const formData = new FormData();
        formData.append("resume", resume);

        if (resumeScore) {
            setScoreLoading(false)
            setOpenResumeScore(true);
            return;
        }

        try {
            const response = await fetch(`${apiurl}/analyse-resume/${id}`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                body: formData
            });

            const data = await response.json();

            if (data.error) {
                message.error(data.error);
            } else {
                let jsonData = JSON.parse(data);
                setResumeScore(jsonData);
                setOpenResumeScore(true)
            }
        } catch (error) {
            console.error("Error generating resume score:", error);
        } finally {
            setScoreLoading(false);
        }
    };


    const generateQandA = async () => {
        if (QandA !== null) {
            setOpenQandA(true)
            return;
        }
        try {
            setQandALoading(true);
            const response = await fetch(`${apiurl}/generatequestionary/${id}`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const data = await response.json();
            if (data.error) {
                message.error(data.error);
            } else {
                setQandA(data);
                setOpenQandA(true)
            }
        } catch (e) {
            console.log(e);
        } finally {
            setQandALoading(false);
        }
    };

    const handleUploadChange = (info) => {
        let newFileList = [...info.fileList];

        // Keep only the latest file
        if (newFileList.length > 1) {
            newFileList = [newFileList.pop()];
        }

        setFileList(newFileList);

        if (info.file.status === "done") {
            message.success(`${info.file.name} uploaded successfully`);
            setResume(info.file);
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} upload failed.`);
        }
    };

    return (
        <Main defaultSelectedKey="2">
            <div style={{ display: "flex", gap: "10px", alignItems: "center", justifyContent: "center", fontWeight: "600", color: "#546873" }}>
                <span>Job Title: {job?.job_title || "N/A"}</span>
                <button style={{ color: "#3182ce", backgroundColor: 'transparent', border: "none", padding: "8px", cursor: "pointer" }} type="primary" onClick={() => setJobModalVisible(true)}>
                    <EyeOutlined />
                </button>
            </div>

            <div className="content" style={{ marginTop: '10vh', display: "flex", gap: '30px', alignItems: 'center', justifyContent: 'space-around' }}>
                <div style={{ margin: "20px 0", textAlign: "center" }}>
                    <Upload
                        accept=".pdf,.doc,.docx"
                        fileList={fileList}
                        beforeUpload={(file) => {
                            const isAcceptedFile =
                                file.type === "application/pdf" ||
                                file.type === "application/msword" ||
                                file.type ===
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

                            if (!isAcceptedFile) {
                                message.error("Only PDF, DOC, and DOCX files are allowed!");
                                return Upload.LIST_IGNORE;
                            }
                            return true;
                        }}
                        customRequest={({ file, onSuccess }) => {
                            setUploading(true);
                            setTimeout(() => {
                                setUploading(false);
                                onSuccess("ok");
                                setResume(file);
                            }, 1000);
                        }}
                        onChange={handleUploadChange}
                        draggable
                        maxCount={1} // Ensures only one file is uploaded
                    >
                        <div className="upload-container">
                            <Button icon={<UploadOutlined />} disabled={uploading} className="upload-button">
                                {uploading ? "Uploading..." : "Upload / Drop Resume"}
                            </Button>
                        </div>
                    </Upload>
                    {/* {resume && <p>Uploaded: {resume.name}</p>} */}
                </div>

                {/* Action Buttons */}
                <div className="buttons" style={{ textAlign: "center", marginTop: "10px", display: "flex", flexDirection: 'column' }}>
                    <button disabled={!resume} onClick={generateResumeScore} style={{ margin: "5px", padding: "8px 12px", background: resume ? "#28a745" : "#ccc", border: "none", color: "white", cursor: resume ? "pointer" : "not-allowed" }}>
                        {scoreLoading ? "Generating..." : "Generate Resume Score"}
                    </button>
                    <button onClick={generateQandA} style={{ margin: "5px", padding: "8px 12px", background: "#007bff", border: "none", color: "white", cursor: "pointer" }}>
                        {QandALoading ? "Generating...." : "Generate Question and Answers"}
                    </button>
                    <button
                        onClick={() => setAddApplication(true)}
                        disabled={!resume} style={{ margin: "5px", padding: "8px 12px", background: resume ? "#ffc107" : "#ccc", border: "none", color: "white", cursor: resume ? "pointer" : "not-allowed" }}>
                        Share Resume
                    </button>
                </div>
            </div>

            <Modal
                title="Job Details"
                open={jobModalVisible}
                onCancel={() => setJobModalVisible(false)}
                footer={null}
                width={900}
            >
                <ViewJobPost job={job} interviewers={interviewers} />
            </Modal>
            <Modal
                title="Generated Questions and Answers"
                open={openQandA}
                onCancel={() => setOpenQandA(false)}
                footer={null}
            >
                {QandA && QandA.length > 0 ? (
                    <ul>
                        {QandA.map((item, index) => (
                            <li key={index}>
                                <strong>Q{index + 1}:</strong> {item.question_text}
                                <br />
                                <strong>Answer:</strong> {item.correct_answer}
                                <hr />
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No questions available.</p>
                )}
            </Modal>
            <Modal
                title="Resume Analysis Results"
                open={openResumeScore}
                onCancel={() => setOpenResumeScore(false)}
                footer={[
                    <Button key="close" type="primary" onClick={() => setOpenResumeScore(false)}>
                        Close
                    </Button>
                ]}
                width={700}
            >
                <Typography.Title level={4}>Skills Evaluation</Typography.Title>
                <ResumeScoreCard score={resumeScore?.overall_resume_score?.score?.split("/")[0] || 0} />
                <List
                    dataSource={resumeScore?.skills || []}
                    renderItem={(item) => (
                        <List.Item>
                            <Typography.Text strong>{item.field_name}:</Typography.Text>
                            <Typography.Text> {item.score}</Typography.Text>
                            <br />
                            <Typography.Text type="secondary">{item.reason}</Typography.Text>
                        </List.Item>
                    )}
                />

                <Typography.Title level={4}>Overall Resume Score</Typography.Title>
                <Typography.Text strong>Score: </Typography.Text>
                <Typography.Text>{resumeScore?.overall_resume_score?.score || "Not provided"}</Typography.Text>
                <br />
                <Typography.Text strong>Reason: </Typography.Text>
                <Typography.Text>{resumeScore?.overall_resume_score?.reason || "No detailed reason provided."}</Typography.Text>
            </Modal>

            <Modal
                open={addApplication}
                width="800px"
                onCancel={() => setAddApplication(false)}
                footer={null}
            >
                <UploadData
                    primary_skills={job?.primary_skills}
                    secondary_skills={job?.secondary_skills}
                // draggedId={draggedId}
                />
            </Modal>

        </Main>
    );
};

export default SendApplication;
