import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "./useAuth";
import Loader from "../Loader/Loader";
import { message } from "antd";

export const AuthRoute = () => {
	const { token, apiurl } = useAuth();
	const [isLoading, setIsLoading] = useState(true);
	const [isValidToken, setIsValidToken] = useState(false);
	const location = useLocation();

	const verifyToken = async (token) => {
		try {
			const response = await fetch(`${apiurl}/verify-token/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ token }),
			});
			if (!response.ok) {
				throw new Error("Failed to verify token");
			}
			const data = await response.json();
			return data.valid === true;
		} catch (error) {
			message.error("Token expired! Please Login again.");
			return false;
		}
	};

	useEffect(() => {
		const checkTokenValidity = async () => {
			if (token && token !== "undefined" && token !== "null") {
				const isValid = await verifyToken(token);
				setIsValidToken(isValid);
			} else {
				setIsValidToken(false);
			}
			setIsLoading(false);
		};

		checkTokenValidity();
	}, [token]);

	if (isLoading) {
		return <Loader />;
	}

	// If there's no valid token, redirect to landing page first
	if (!token || token === "undefined" || token === "null") {
		return <Navigate to="/welcome" replace />;
	}

	// If token is invalid, redirect to login page
	if (!isValidToken) {
		return (
			<Navigate
				to="/login"
				state={{ error: "Token expired. Please login again." }}
				replace
			/>
		);
	}

	return <Outlet />;
};
