import React, { useEffect, useState, useRef } from "react";
import "./ResumeScoreCard.css";
import { Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import scoreimage from "../../../../images/agencyresumecard/scoreimg.svg";
import polygone from "../../../../images/agencyresumecard/Polygon.png";

const ResumeScoreCard = ({ score = 0 }) => {
  const [marks, setMarks] = useState(0);
  const [qualitative, setQualitative] = useState("Scoring...");
  const intervalRef = useRef(null);

  const degreePerMark = 1.8;
  const angleInDegrees = 90 - marks * degreePerMark;
  const radius = 105;

  useEffect(() => {
    if (score >= 90) {
      setQualitative("Excellent");
    } else if (score >= 75) {
      setQualitative("Good");
    } else {
      setQualitative("Bad");
    }
  }, [score]);

  useEffect(() => {
    // Reset animation if score changes
    setMarks(0);

    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setMarks((prevMarks) => {
        if (prevMarks >= score) {
          clearInterval(intervalRef.current);
          return score;
        }
        return prevMarks + 2;
      });
    }, 30);

    return () => clearInterval(intervalRef.current);
  }, [score]);

  const angleInRadians = (angleInDegrees + 90) * (Math.PI / 180);
  const x = radius * Math.cos(angleInRadians);
  const y = radius * Math.sin(angleInRadians);

  return (
    <div className="resume-score-card">
      <div className="score-image-text">
        <div className="score-text">
          <span>{marks}</span>
          <span>{qualitative}</span>
        </div>
        <img src={scoreimage} className="score-img" alt="Score background" />

        <img
          src={polygone}
          className="polygone"
          style={{
            left: `${124 + x}px`,
            top: `${174 - y}px`,
            transform: `rotate(${-angleInDegrees}deg)`,
            transition: "all 0.1s linear",
          }}
          alt="Indicator"
        />
      </div>

      {/* <Upload beforeUpload={() => false} showUploadList={false}>
        <Button icon={<UploadOutlined />}>Upload Resume</Button>
      </Upload> */}
    </div>
  );
};

export default ResumeScoreCard;
