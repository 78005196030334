import React, { useEffect, useState } from "react";
import "./Client.css";
import Main from "./Layout";
import { useNavigate } from "react-router-dom";
import Bag from "./../../../images/Client/Bag.svg"
import Closed from "./../../../images/Client/Closed.svg"
import Experience from "./../../../images/Client/Experience.svg"
import Location from "./../../../images/Client/Location.svg"
import Vacancies from "./../../../images/Client/Vacancies.svg"
import Calendar2 from "./../../../images/Client/Calendar.svg"
import Group from "./../../../images/Client/group.svg"
import Empty from "./../../../images/Client/empty.svg"
import Send from "./../../../images/Client/send.svg"
import Table from "../../common/Table";
import { useAuth } from "../../common/useAuth"

const Component1 = ({ item }) => {
	const navigate = useNavigate();
	return (
		<div>
			<div className="box">
				<div className="line1">
					<div className="job-title">{item.job_title}</div>
					<div className="logo" onClick={() => { navigate(`client/complete_job_post/${item.id}`) }}>
						<img onClick={() => { }} src={Send} alt="" />
					</div>
				</div>
				<span className="posted">Posted {item.posted}</span>
				<div className="line2">
					<div className="option">
						<img src={Location} alt="" />
						<span>{item.location}</span>
					</div>
					<div className="option">
						<img src={Experience} alt="" />
						<span>{item.years_of_experience} years exp</span>
					</div>
				</div>
				<div className="line3">
					<div className="value">
						<span className="value">{item.applications}</span>
						<span className="name">Applications</span>

					</div>
					<span className="last-week">{item.applications_last_week} in last week</span>

				</div>
			</div>
		</div>
	)
}

const Component2 = ({ item }) => {
	return (
		<div className="box">
			<div className="line1">
				<div className="logo">
					<img src={item.logo} alt="" />
				</div>
				<span>{item.label}</span>
			</div>
			<div className="line2">
				<span className="value">{item.value}</span>
			</div>
			<div className="values">
			</div>
			<span className="text">Update: {item.last_update}</span>
		</div>
	)
}

const Client = () => {
	const navigate = useNavigate();
	const [sec2Details, setSec2Details] = useState([
		{ label: "No of Roles", logo: Group, value: 0, last_update: "" },
		{ label: "Resumes Received", logo: Bag, value: 0, last_update: "" },
		{ label: "On Process", logo: Calendar2, value: 0, last_update: "" },
		{ label: "Total Vacancies", logo: Vacancies, value: 0, last_update: "" },
		{ label: "Closed", logo: Closed, value: 0, last_update: "" }
	]);
	const columns = [
		{ title: "Interviewer Name", dataIndex: "interviewer_name", key: "interviewer_name" },
		{ title: "Email", dataIndex: "interviewer_email", key: "interviewer_email" },
		{ title: "Jobs Allotted", dataIndex: "jobs_alloted", key: "jobs_alloted" },
		{ title: "Rounds Allotted", dataIndex: "rounds_alloted", key: "rounds_alloted" },
		{ title: "Pending Rounds", dataIndex: "pending", key: "pending" },
		{ title: "Completed Rounds", dataIndex: "completed", key: "completed" },
	];


	const [data, setData] = useState()
	const { apiurl, token } = useAuth()
	const [jobs, setJobs] = useState()
	const [interviewers, setInterviewers] = useState([])
	const [todayInterviews, setTodayInterviews] = useState([])
	const fetchData = async () => {
		try {
			const response = await fetch(`${apiurl}/client/dashboard`, {
				method: 'GET',
				headers: {
					"Authorization": `Bearer ${token}`
				}
			})
			const data = await response.json()
			if (data.error) {
				console.log(data.error)
			}
			console.log(data)
			setJobs(data.job_posts)
			let applications_data = data.data;
			const updatedDetails = [
				{ label: "No of Roles", logo: Group, value: applications_data.no_of_roles, last_update: new Date().toLocaleDateString('en-US', { month: 'long', day: '2-digit', year: 'numeric' }) },
				{ label: "Resumes Received", logo: Bag, value: applications_data.resumes_received, last_update: new Date().toLocaleDateString('en-US', { month: 'long', day: '2-digit', year: 'numeric' }) },
				{ label: "On Process", logo: Calendar2, value: applications_data.on_process, last_update: new Date().toLocaleDateString('en-US', { month: 'long', day: '2-digit', year: 'numeric' }) },
				{ label: "Total Vacancies", logo: Vacancies, value: applications_data.vacancies, last_update: new Date().toLocaleDateString('en-US', { month: 'long', day: '2-digit', year: 'numeric' }) },
				{ label: "Closed", logo: Closed, value: applications_data.closed, last_update: new Date().toLocaleDateString('en-US', { month: 'long', day: '2-digit', year: 'numeric' }) }
			];
			setSec2Details(updatedDetails);
			setInterviewers(data.interviewers_data);
			setTodayInterviews(data.today_interviews);
		}
		catch (e) {
			console.log(e)
		}
	}
	useEffect(() => {
		if (token) {
			fetchData()
		}
	}, [token])

	return (
		<Main defaultSelectedKey='1'>

			<div className="client-dashboard">
				<div className="sec-1">
					{jobs && jobs.map((item, index) => (
						<Component1 item={item}></Component1>
					))}
				</div>
				<div className="sec-2">
					{sec2Details.map((item, index) => (
						<Component2 item={item}></Component2>
					))}
				</div>
				<div className="interviewers">
					{interviewers &&
						<Table columns={columns} data={interviewers} pagination={false} />
					}
					{todayInterviews &&
						<div className="today-interviews">
							<span className="heading">Today's Interviews</span>
							<div className="item">
								{todayInterviews.length > 0 ? (
									todayInterviews.map((item, index) => (
										<div className="interview_item" key={index}>
											<span className="time">{item.from_time}</span>
											<span className="line"></span>
											<div className="interview_details">
												<div className="line1">{item.candidate_name} - {item.job_title}</div>
												<div className="line2">Round-{item.round_num} and {item.interviewer_name}</div>
											</div>
										</div>
									))
								) : (
									<div className="no_data_found">
										<img src={Empty} alt="No Data" className="no_data_icon" />
										<p>No Interviews Found</p>
									</div>
								)}
							</div>
						</div>
					}
				</div>
			</div>
		</Main>
	);
};

export default Client;