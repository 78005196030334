import React, { useState } from 'react'
import "./ClientProfile.css"
import Main from '../Layout'
import ProfileCard from '../../../common/CommonCards/ProfileCard/ProfileCard'
import { useAuth } from '../../../common/useAuth'
import { message } from 'antd'

const ClientProfile = () => {
    const { apiurl, token } = useAuth();

    return (
        <Main>

            <ProfileCard ></ProfileCard>

        </Main>
    )
}

export default ClientProfile