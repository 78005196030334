import React from "react";
import ClientProfile from "../dashboard/client/Profile/ClientProfile";
import CandidateProfile from "../dashboard/Candidate/profile/CandidateProfile";
import InterviewerProfile from "../dashboard/Interviewer/Profile/InterviewerProfile";
import RecruiterProfile from "../dashboard/Recruiter/Profile/RecruiterProfile";
import ManagerProfile from "../dashboard/Manager/Profile/ManagerProfile";
import { useAuth } from "./useAuth";

const Profile = () => {
    const { userData } = useAuth();
    let user = typeof userData === "string" ? JSON.parse(userData) : userData;

    const roleComponentMap = {
        manager: ManagerProfile,
        client: ClientProfile,
        candidate: CandidateProfile,
        recruiter: RecruiterProfile,
        interviewer: InterviewerProfile,
    };


    const ProfileComponent = roleComponentMap[user?.role] || null;

    return (
        <div>
            {/* <pre>{JSON.stringify(user, null, 2)}</pre> Debugging */}
            {ProfileComponent && <ProfileComponent />}
        </div>
    );
};

export default Profile;
