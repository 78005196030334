import React, { useEffect, useState } from "react";
import { Table, Button, message, Modal, Form, Input } from "antd";
import html2pdf from "html2pdf.js"; // Import the html2pdf library
import Main from "../Layout";
import { useAuth } from "../../../common/useAuth";
import downloadinvoicebut from '../../../../images/invoice/downloadinvoicebut.svg'

const AllInvoices = () => {
  const { token, apiurl } = useAuth();
  const [invoices, setInvoices] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [accountants, setAccountants] = useState(null);
  const [form] = Form.useForm();

  const fetchInvoices = () => {
    fetch(`${apiurl}/get_invoices/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.invoices) {
          setInvoices(data.invoices);
        } else {
          message.error("No invoices found or error fetching data.");
        }
      })
      .catch((error) => {
        message.error("Error fetching invoices.");
        console.error("Error fetching invoices:", error);
      });
  };

  // Function to download the invoice as PDF
  const downloadInvoice = (htmlContent, invoiceId) => {
    const invoiceElement = document.createElement("div");
    invoiceElement.innerHTML = htmlContent;

    const options = {
      margin: 0.5,
      filename: `invoice_${invoiceId}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().from(invoiceElement).set(options).save(); // Trigger PDF download
  };

  // View the invoice HTML in a new window
  const viewInvoice = (htmlContent) => {
    const newWindow = window.open();
    newWindow.document.write(htmlContent);
    newWindow.document.close();
  };

  useEffect(() => {
    fetchInvoices();
    handleFetchAccountants();
  }, []); // Fetch invoices when the component mounts

  // Handle Modal visibility toggle
  const showModal = () => {
    setIsModalVisible(true); // Show modal when button is clicked
  };

  const handleCancel = () => {
    setIsModalVisible(false); // Close modal on cancel
  };

  const handleCreateAccountant = async (values) => {
    // Handle accountant creation here. You can send `values` (email and username) to your backend.
    try {
      const response = await fetch(`${apiurl}/manager/create_accountant/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values), // Send form data as JSON
      });
      const data = await response.json();
      if (data.success) {
        message.success("Accountant created successfully!");
        setIsModalVisible(false); // Close modal after successful creation
        form.resetFields(); // Reset form fields
      } else {
        message.error(data.error || "Failed to create accountant.");
      }
    } catch (error) {
      console.error("Error creating accountant:", error);
      message.error("Error creating accountant.");
    }
  };

  const handleFetchAccountants = async () => {
    try {
      const response = await fetch(`${apiurl}/manager/accountants/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data) {
        setAccountants(data);
        message.success("Accountants fetched successfully!");
      } else {
        message.error(data.error || "Failed to fetch accountants");
      }
    } catch (error) {
      console.error("Error fetching accountants", error);
      message.error("Error fetching accountants");
    }
  };

  // Columns for Ant Design Table
  const columns = [
    {
      title: "Invoice ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Organization Email",
      dataIndex: "org_email",
      key: "org_email",
    },
    {
      title: "Client Email",
      dataIndex: "client_email",
      key: "client_email",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        // <div>
        //   <Button
        //     type="primary"
        //     size="small"
        //     style={{ marginRight: 8 }}
        //     onClick={() => viewInvoice(record.html)}
        //   >
        //     View
        //   </Button>
        //   <Button
        //     type="default"
        //     size="small"
        //     onClick={() => downloadInvoice(record.html, record.id)}
        //   >
        //     Download as PDF
        //   </Button>
        // </div>

        <div>
          {/* <Button
                    type="primary"
                    size="small"
                    style={{ marginRight: 8 }}
                    onClick={() => viewInvoice(record.html)}
                  >
                    View
                  </Button> */}
          {/* Download Invoice Button */}

          <img
            style={{ width: "150px", cursor: "pointer" }}
            src={downloadinvoicebut}
            onClick={() => downloadInvoice(record.html, record.id)}
          />
        </div>
      ),
    },
  ];

  const acccolumns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => new Date(text).toLocaleDateString(), // Format the date
    },
    {
      title: "Organization",
      dataIndex: "organization",
      key: "organization",
      render: (organization) => (organization ? `Org #${organization}` : "N/A"), // Assuming 'organization' is an ID or a name
    },
  ];

  return (
    <Main defaultSelectedKey="6">
      <div className="invoices-table" style={{ border: "none" }}>
        {invoices.length > 0 ? (
          <Table
            dataSource={invoices}
            columns={columns}
            rowKey="id"
            pagination={false} // You can enable pagination if necessary
          />
        ) : (
          <p>No invoices found.</p>
        )}
      </div>
      {/* Add Accountant Button */}
      <button type="primary" onClick={showModal} style={{backgroundColor:"lightblue",border:"none",margin:"none", color:'#2886F2',backgroundColor:"#E3E8EF",padding:"10px",outline:"none",cursor:"pointer",borderRadius:"8px",fontSize:"16px"}}>
        Add Accountant
      </button>
   
      <Table
        dataSource={accountants}
        columns={acccolumns}
        rowKey="id" // Assuming 'id' is the unique identifier for accountants
        pagination={false} // Pagination example
      />
      {/* Modal for creating Accountant */}
      <Modal
        title="Create Accountant"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} onFinish={handleCreateAccountant}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please enter a username!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Create Accountant
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Main>
  );
};

export default AllInvoices;
