import React from "react";
import Main from "./Layout";
import "./Recruiter.css"


const SmallCard = ({ item }) => {
	return (
		<div>

		</div>
	)
}


const Recruiter = () => {
	return <Main>Recruiter</Main>;
};

export default Recruiter;
