import React, { useState } from 'react'
import MainLayout from '../../common/Layout/MainLayout'
import dashboard from "./../../../images/Dashboard.svg"
import files from "./../../../images/Files.svg"
import candidates from "./../../../images/CandidateProfile.svg"
import jobs from "./../../../images/Jobs.svg"
import interviewers from "./../../../images/InterviewersProfile.svg"
import secure from "./../../../images/Secure.svg"
import Invoice from "./../../../images/Client/Invoices.svg"
import Replace from "./../../../images/Replace.svg"
import { useAuth } from '../../common/useAuth'


const Layout = ({ children, defaultSelectedKey }) => {
	const { apiurl, token } = useAuth();
	const [userData, setUserData] = useState();

	const fetchData = async () => {
		try {
			const response = await fetch(`${apiurl}/get-profile/`, {
				method: 'GET',
				headers: {
					"Authorization": `Bearer ${token}`
				}
			})
			const data = await response.json();
			if (data.error) {
				console.log(data.error)
			}
			else {
				setUserData(data)
			}
		}
		catch (e) {
			console.log(e)
		}
	}
	const cilentoptions = [
		{
			"key": 1,
			"label": "Dashboard",
			"logo": dashboard,
			"path": '/'
		},
		{
			"key": 2,
			"label": "Jobs",
			"logo": jobs,
			"path": '/client/mypostings'
		},
		{
			"key": 3,
			"label": "Applications",
			"logo": files,
			"path": '/client/applications'
		},
		{
			"key": 4,
			"label": "Candidates",
			"logo": candidates,
			'path': '/client/candidates',
		},
		{
			"key": 5,
			"label": "Approvals",
			"logo": secure,
			"path": "/client/approvals/"
		},
		{
			"key": 6,
			"label": "Interviews",
			"logo": interviewers,
			"path": '/client/interviewers'
		},
		{
			"key": 7,
			"label": "Replacements",
			"logo": Replace,
			"path": '/client/replacements'
		},
		{
			"key": 8,
			"label": "Invoices",
			"logo": Invoice,
			"path": '/client/invoices'
		},

	]

	return (
		<>
			<MainLayout children={children} defaultSelectedKey={defaultSelectedKey} options={cilentoptions}></MainLayout>
		</>
	)
}

export default Layout