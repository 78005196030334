import React, { useState, useEffect } from 'react';
import Main from './Layout';
import illustration1 from "../../../images/Interviewer/Illustration-1.svg";
import illustration2 from "../../../images/Interviewer/Illustration-2.svg";
import bag from "../../../images/Client/Bag.svg";
import group from "../../../images/Client/group.svg";
import "./Interviewer.css";
import Calendar from './Calendar';
import { useAuth } from '../../common/useAuth';
import { message } from 'antd';

// Card Component
const InterviewerCard = ({ item }) => {
    return (
        <div className="interviewer-dashboard-card">
            <div className="line1">
                <img src={item.icon} className="img" alt="" />
                <img src={item.illustration} className='illustration' alt="" />
            </div>
            <div className="value">{item.value}</div>
            <span className="query">{item.query}</span>
        </div>
    );
};

// Today's Interviews Component
const TodayUpcomingInterviews = ({ item }) => {
    return (
        <div className="interview-updates">
            <div className="details">
                <span className="text">{item.candidate_name} - {item.job_title} - Round {item.round_num}</span>
                <span className="time">{item.from_time} - {item.to_time}</span>
            </div>
            <button disabled={item.status === 'completed'}>
                Update Review
            </button>
        </div>
    );
};

// Missed Interviews Component
const MissedInterviews = ({ item }) => {
    return (
        <div className="interview-updates">
            <div className="details">
                <span className="text">{item.candidate_name} - {item.job_title} - Round {item.round_num}</span>
                <span className="time">{item.from_time} - {item.to_time}</span>
            </div>
            <button>
                Enter Remarks
            </button>
        </div>
    );
};

// Main Interviewer Dashboard Component
const Interviewer = () => {

    const { apiurl, token } = useAuth();
    const [events, setEvents] = useState([]);
    const [todayInterviews, setTodayInterviews] = useState([]);
    const [missedInterviews, setMissedInterviews] = useState([]);
    const [cardData, setCardData] = useState([
        {
            "icon": group,
            "query": "Total No. of interviews assigned",
            "value": "0",  // default value, will be updated dynamically
            "illustration": illustration1
        },
        {
            "icon": bag,
            "query": "No. of interviews conducted",
            "value": "0",  // default value, will be updated dynamically
            "illustration": illustration2
        }
    ]);

    // Fetch dashboard data
    const fetchData = async () => {
        try {
            const response = await fetch(`${apiurl}/interviewer/interviewer-dashboard/`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const data = await response.json();

            if (data.error) {
                message.error(data.error);
            } else {
                setEvents(data.today_events || []);
                setTodayInterviews(data.today_interviews || []);
                setMissedInterviews(data.missed_interviews || []);

                // Update card data dynamically
                setCardData([
                    {
                        "icon": group,
                        "query": "Total No. of interviews assigned",
                        "value": data.data.assigned || "0",
                        "illustration": illustration1
                    },
                    {
                        "icon": bag,
                        "query": "No. of interviews conducted",
                        "value": data.data.completed || "0",
                        "illustration": illustration2
                    }
                ]);
            }
        } catch (e) {
            console.error("Error fetching dashboard data:", e);
            message.error("Failed to load dashboard data.");
        }
    };

    // Fetch data on component mount
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Main defaultSelectedKey="1">
            <div className="interviewer-dashboard">
                {/* Left Section */}
                <div className="left-content">
                    {/* Dynamic Cards */}
                    <div className="card-items">
                        {cardData && cardData.length > 0 && cardData.map((item, index) => (
                            <InterviewerCard key={index} item={item} />
                        ))}
                    </div>

                    {/* Calendar Component */}
                    <div className="calendar">
                        <Calendar events={events} />
                    </div>
                </div>

                {/* Right Section */}
                <div className="right-content">
                    {/* Today's Interviews */}
                    <div className="upcoming-interviews">
                        <div className="line1">
                            <div className="title">Today's Interviews</div>
                            <div className="view-more">View More</div>
                        </div>
                        <div className="cards">
                            {todayInterviews.length > 0 ? todayInterviews.map((item, index) => (
                                <TodayUpcomingInterviews key={index} item={item} />
                            )) : <p className="no-data">No interviews scheduled for today.</p>}
                        </div>
                    </div>

                    {/* Missed Interviews */}
                    <div className="missed-interviews">
                        <div className="line1">
                            <div className="title">Remarks Pending</div>
                            <div className="view-more">View More</div>
                        </div>
                        <div className="cards">
                            {missedInterviews.length > 0 ? missedInterviews.map((item, index) => (
                                <MissedInterviews key={index} item={item} />
                            )) : <p className="no-data">No missed interviews.</p>}
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    );
};

export default Interviewer;
