import React, { useState } from "react";
import "./InterviewCalandar.css";
import {
  LeftOutlined,
  RightOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

const InterviewCalendar = ({ interviews }) => {
  const [currentWeekStart, setCurrentWeekStart] = useState(
    dayjs().startOf("week")
  );

  // Function to get dates for the current week (7 days starting from currentWeekStart)
  const getWeekDates = () => {
    return Array.from({ length: 7 }, (_, index) =>
      currentWeekStart.add(index, "day")
    );
  };

  // Move forward by 1 day (shift calendar right)
  const nextDay = () => {
    setCurrentWeekStart(currentWeekStart.add(1, "day"));
  };

  // Move backward by 1 day (shift calendar left)
  const prevDay = () => {
    setCurrentWeekStart(currentWeekStart.subtract(1, "day"));
  };

  // Reset to the current week's start
  const resetToToday = () => {
    setCurrentWeekStart(dayjs().startOf("week"));
  };

  return (
    <div className="events-container">
      <div className="events-header">
        {/* <CalendarOutlined className="calendar-icon" /> */}
        <span className="events-title">Upcoming Events</span>
        <div className="navigation">
          <button className="nav-btn" onClick={prevDay}>
            <LeftOutlined />
          </button>
          <button className="nav-btn" onClick={nextDay}>
            <RightOutlined />
          </button>
          <button className="today-btn" onClick={resetToToday}>
            Today
          </button>
        </div>
      </div>

      <div className="week-calendar">
        {getWeekDates().map((date, index) => {
          const dayName = date.format("ddd").toUpperCase(); // MON, TUE, etc.
          const dayNumber = date.format("DD"); // Date number
          const isToday = date.isSame(dayjs(), "day");
          const currentMonthYear = date.format("YYYY-MM-DD"); // Get the month and year
          const crtDate = date.format("YYYY-MM-DD");
          console.log("crtDate", crtDate);

          return (
            <div key={index} className={`day-cell ${isToday ? "active" : ""}`}>
              <span className="day-text">{dayName}</span>
              <span className="date-text">{dayNumber}</span>

              {/* Find a matching interview for the current date */}
              {(() => {
                const matchingInterview = interviews?.find(
                  (obj) => obj.scheduled_date === crtDate
                );
                return matchingInterview ? (
                  <div className="event">
                    <div className="avatars">
                      <img
                        src="https://randomuser.me/api/portraits/men/1.jpg"
                        alt="avatar"
                      />
                      <img
                        src="https://randomuser.me/api/portraits/women/2.jpg"
                        alt="avatar"
                      />
                      <img
                        src="https://randomuser.me/api/portraits/men/3.jpg"
                        alt="avatar"
                      />
                    </div>
                    <p className="event-text">
                      {matchingInterview?.job_id?.job_title}
                    </p>
                  </div>
                ) : null;
              })()}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InterviewCalendar;
