import React, { useEffect, useState } from 'react';
import { Table, Dropdown, Menu, Input, Select, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import "./ClientJobs.css";
import Main from './../Layout';
import { useAuth } from '../../../common/useAuth';
import CandidateFolder from "./../../../../images/Client/Candidates_folder.svg";
import PlusOutlined from "./../../../../images/Client/plusicon.svg";
import Options from "./../../../../images/Client/options.svg";
import { useNavigate } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';

const { Option } = Select;

const ClientJobs = () => {
    const [jobsData, setJobsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [totalItems, setTotalItems] = useState(0); // for total items count
    const [currentPage, setCurrentPage] = useState(1); // current page number

    const { token, apiurl } = useAuth();

    const [searchText, setSearchText] = useState("");
    const [selectedCompany, setSelectedCompany] = useState("All Companies");
    const [selectedStatus, setSelectedStatus] = useState("All Status");
    const [selectedDateFilter, setSelectedDateFilter] = useState("All Dates");
    const navigate = useNavigate();

    // Fetch Jobs with pagination and filters
    const fetchJobs = async (page = 1) => {
        setLoading(true);
        try {
            let url = `${apiurl}/client/job-postings/?page=${page}`;

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (response.ok) {
                const data = await response.json();
                setJobsData(data.results);
                setTotalItems(data.count);
                setCurrentPage(page);
                setFilteredData(data.results);
            } else {
                console.error("Failed to fetch jobs");
            }
        } catch (error) {
            console.error("Error fetching jobs:", error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchJobs(currentPage);
    }, []);


    const handleDeleteJob = async (id) => {
        try {
            const response = await fetch(`${apiurl}/client/delete-job/`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            const data = await response.json();
            if (data.error) {
                message.error(data.error)
            }
            else {
                message.success(data.message)
            }
        }
        catch (e) {
            message.error(e)
        }
    }

    useEffect(() => {
        if (jobsData) {
            let filtered = [...jobsData];

            if (searchText) {
                filtered = filtered.filter(item => item.job_title.toLowerCase().includes(searchText.toLowerCase()));
            }

            if (selectedCompany !== "All Companies") {
                filtered = filtered.filter(item => item.company === selectedCompany);
            }

            if (selectedStatus !== "All Status") {
                filtered = filtered.filter(item => item.status === selectedStatus);
            }

            if (selectedDateFilter !== "All Dates") {
                const now = new Date();
                filtered = filtered.filter(item => {
                    const closeDate = new Date(item.job_close_duration);
                    const diffDays = Math.ceil((now - closeDate) / (1000 * 60 * 60 * 24));

                    switch (selectedDateFilter) {
                        case 'Within 5 days':
                            return diffDays <= 5;
                        case '5-10 days':
                            return diffDays > 5 && diffDays <= 10;
                        case '10-20 days':
                            return diffDays > 10 && diffDays <= 20;
                        case '1 Month':
                            return diffDays > 20 && diffDays <= 30;
                        case 'Above 1 Month':
                            return diffDays > 30;
                        default:
                            return true;
                    }
                });
            }

            setFilteredData(filtered);
        }

    }, [searchText, selectedCompany, selectedStatus, selectedDateFilter, jobsData]);


    // Menu for Action Column
    const menu = (
        <Menu>
            <Menu.Item key="1">Edit</Menu.Item>
            <Menu.Item key="2">Delete</Menu.Item>
        </Menu>
    );

    // Dynamic company names and statuses for filter dropdowns
    const companyOptions = ['All Companies', ...Array.from(new Set(jobsData.map(item => item.company)))];
    const statusOptions = ['All Status', ...Array.from(new Set(jobsData.map(item => item.status)))];

    const columns = [

        {
            title: 'JOB', dataIndex: 'job_title', key: 'job_title',

            render: (text, record) => (
                <div>
                    <span style={{ cursor: "pointer" }} onClick={() => navigate(`/client/complete_job_post/${record.id}`)}>{text}</span>
                </div>
            )
        },
        {
            title: 'Total Candidates',
            dataIndex: 'total_candidates',
            key: 'total_candidates',
            render: (text) => (
                <div>
                    <img src={CandidateFolder} alt="" style={{ marginRight: '6px' }} /> {text}
                </div>
            )
        },
        { title: 'Company', dataIndex: 'company', key: 'company' },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => (
                <div style={{
                    color: text.toLowerCase() === 'opened' ? 'green' : 'red',
                }}>
                    {text}
                </div>
            )
        },
        {
            title: 'Positions Closed',
            dataIndex: 'positions_closed',
            key: 'positions_closed',
            render: (text) => (
                <div style={{
                    backgroundColor: '#F1F8FD',
                    color: '#379AE6',
                    padding: '4px 10px',
                    borderRadius: '14px',
                    textAlign: 'center',
                    display: 'inline-block',
                    minWidth: '50px',
                }}>
                    {text}
                </div>
            )
        },
        { title: 'CTC', dataIndex: 'ctc', key: 'ctc' },
        { title: 'Job Closed by', dataIndex: 'job_close_duration', key: 'job_close_duration' },
        {
            title: 'Action',
            key: 'action',
            render: () => (
                <Dropdown overlay={menu} trigger={['click']}>
                    {/* <MoreOutlined style={{ fontSize: '20px', cursor: 'pointer' }} /> */}
                    <img src={Options} style={{ fontSize: '15px', cursor: 'pointer' }} alt="" />
                </Dropdown>
            )
        },
    ];

    // Handle Pagination Change
    const handleTableChange = (pagination) => {
        fetchJobs(pagination.current);
    };

    return (
        <Main defaultSelectedKey={2}>
            <div className="sec-1" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span style={{ fontSize: '20px', fontWeight: 'bold' }}>My Job Postings</span>
                <div className="buttons">
                    <div className="button"
                        onClick={() => { navigate('/client/postjob') }}
                        style={{ padding: '8px 24px', backgroundColor: '#1890ff', color: 'white', borderRadius: '4px', cursor: 'pointer', display: "flex", alignItems: "center", justifyContent: "center", gap: "5px" }}>
                        <img src={PlusOutlined} alt="" />Add New Job
                    </div>
                    <div className="button"
                        onClick={() => { navigate('/client/edit-requests') }}
                        style={{ padding: '8px 24px', backgroundColor: '#1890ff', color: 'white', borderRadius: '4px', cursor: 'pointer', display: "flex", alignItems: "center", justifyContent: "center", gap: "5px" }}>
                        <EditOutlined /> Edit Requests
                    </div>
                </div>
            </div>

            {/* Search & Filters Section */}
            <div className="filters" style={{ margin: '16px 0', display: 'flex', gap: '12px', alignItems: 'center' }}>
                <Input
                    placeholder="Search Job Title"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    style={{ width: '30%', marginTop: '15px' }}
                    className='search'
                    prefix={<SearchOutlined style={{ color: '#171A1F' }} />}
                />
                <Select value={selectedCompany} onChange={setSelectedCompany} style={{ marginLeft: "20%", width: '15%', backgroundColor: '#F3F4F6' }}>
                    {companyOptions.map(company => <Option key={company} value={company}>{company}</Option>)}
                </Select>
                <Select value={selectedStatus} onChange={setSelectedStatus} style={{ width: '15%', backgroundColor: '#F3F4F6' }}>
                    {statusOptions.map(status => <Option key={status} value={status}>{status}</Option>)}
                </Select>
                <Select value={selectedDateFilter} onChange={setSelectedDateFilter} style={{ width: '15%' }}>
                    <Option value="All Dates">All Dates</Option>
                    <Option value="Within 5 days">Within 5 days</Option>
                    <Option value="5-10 days">5-10 days</Option>
                    <Option value="10-20 days">10-20 days</Option>
                    <Option value="1 Month">1 Month</Option>
                    <Option value="Above 1 Month">Above 1 Month</Option>
                </Select>
            </div>

            {/* Table Section */}
            <div className="table">
                <Table
                    columns={columns}
                    dataSource={filteredData}
                    loading={loading}
                    className="custom-table"
                    rowKey="id"
                    pagination={{
                        current: currentPage,
                        total: totalItems,
                        pageSize: 10,
                        showSizeChanger: false,
                    }}
                    onChange={handleTableChange} // handle pagination change
                />
            </div>
        </Main>
    );
};

export default ClientJobs;
