import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../common/useAuth';
import { message, Button, Modal, Input } from 'antd';
import Main from '../Layout';
import './ReconfirmationCandidates.css';

const ReconfirmationCandidates = () => {
    const { token, apiurl } = useAuth();
    const [data, setData] = useState([]);
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
    const [rejectFeedback, setRejectFeedback] = useState('');
    const [selectedCandidateId, setSelectedCandidateId] = useState(null);

    const fetchData = async () => {
        try {
            const response = await fetch(`${apiurl}/recruiter/candidate-selected-jobs/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const result = await response.json();
            if (result.error) {
                message.error(result.error);
                return;
            }
            setData(result);
        } catch (e) {
            console.log(e);
            message.error("Failed to fetch selected jobs");
        }
    };

    useEffect(() => {
        if (token) {
            fetchData();
        }
    }, [token]);

    const handleAccept = async (id) => {
        try {
            const response = await fetch(`${apiurl}/recruiter/reconfirmation-accept/?selected_candidate_id=${id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const result = await response.json();
            if (result.error) {
                message.error(result.error);
                return;
            }
            message.success(result.message);
            fetchData();
        } catch (e) {
            console.log(e);
            message.error("Failed to accept the job offer");
        }
    };

    const openRejectModal = (id) => {
        setSelectedCandidateId(id);
        setIsRejectModalOpen(true);
    };

    const handleReject = async () => {
        if (!rejectFeedback.trim()) {
            message.error("Please provide feedback for rejection.");
            return;
        }
        try {
            const response = await fetch(`${apiurl}recruiter/reconfirmation-reject/?selected_candidate_id=${selectedCandidateId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ feedback: rejectFeedback })
            });
            const result = await response.json();
            if (result.error) {
                message.error(result.error);
                return;
            }
            message.success(result.message);
            setIsRejectModalOpen(false);
            setRejectFeedback('');
            fetchData();
        } catch (e) {
            console.log(e);
            message.error("Failed to reject the job offer");
        }
    };

    return (
        <Main defaultSelectedKey={5}>   
            <h1>List of Accepted Jobs</h1>
            {data.length > 0 ? (
                data.map((item) => (
                    <div className="job-card" key={item.selected_candidate_id}>
                        <h2>{item.job_title}</h2>
                        <p><strong>Job Description:</strong> {item.job_description}</p>
                        <p><strong>Offered CTC:</strong> {item.actual_ctc}</p>
                        <p><strong>Agreed CTC:</strong> {item.accepted_ctc}</p>
                        <p><strong>Candidate Name</strong> {item.candidate_name}</p>
                        <p><strong>Client Name</strong> {item.client_name}</p>
                        <p><strong>Joining Date:</strong> {item.joining_date}</p>
                        {item.recruiter_acceptance === true ? (
                            <div className="accepted green">
                                Accepted
                            </div>
                        ) : (
                            <div className="actions">
                                <Button type="primary" onClick={() => handleAccept(item.selected_candidate_id)}>
                                    Accept Offer
                                </Button>
                                <Button type="danger" onClick={() => openRejectModal(item.selected_candidate_id)}>
                                    Reject Offer
                                </Button>
                            </div>
                        )}
                    </div>
                ))
            ) : (
                <p>No selected jobs available.</p>
            )}

            {/* Reject Modal */}
            <Modal
                title="Reason for Rejection"
                open={isRejectModalOpen}
                onCancel={() => setIsRejectModalOpen(false)}
                onOk={handleReject}
            >
                <Input.TextArea
                    value={rejectFeedback}
                    onChange={(e) => setRejectFeedback(e.target.value)}
                    placeholder="Enter reason for rejection"
                />
            </Modal>
        </Main>
    );
};

export default ReconfirmationCandidates;
