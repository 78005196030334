import React, { useState, useEffect } from "react";
import { useAuth } from "../../common/useAuth";
import { Button, message, Table, Tag } from "antd";
import Main from "./Layout";
import './MyApplications.css'
import { render } from "@testing-library/react";


const CandidateApplications = () => {
  const [data, setData] = useState([]);
  const { token, apiurl } = useAuth();

  const fetchData = async () => {
    
    try {
      const response = await fetch(`${apiurl}/candidate/applications`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      if (result.error) {
        return message.error(result.error);
      }
      setData(result.data);
    } catch (e) {
      message.error("Failed to fetch applications");
      console.error(e);
    }
  };

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: "Job Title",
      dataIndex: ["job_id", "job_title"],
      key: "job_id",
      render: (jobTitle) => (
        <div style={{ color: "#2C5F99", textAlign: "center" ,fontSize:"16px"}}>
          {jobTitle}
        </div>
      ),
    },
    {
      title: "Application Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        const color = status === "selected" ? "green" : status === "rejected" ? "red" : "blue";
        return <Tag color={color}>{status.toUpperCase()}</Tag>;
      },
    },
  
    {
      title: "Sender",
      dataIndex: ["sender", "username"],
      key: "sender",
    },
    {
      title: "Receiver",
      dataIndex: ["receiver", "username"],
      key: "receiver",
    },
    // {
    //   title: "Message",
    //   dataIndex: "message",
    //   key: "message",
    //   render: (text) => (text ? text : "No message"),
    // },
    {
      title: "Next Interview",
      dataIndex: ["next_interview", "schedule_date"],
      key: "next_interview",
      render: (date) => (date ? new Date(date).toLocaleString() : "-"),
    },
    {
      title: "Round Number",
      dataIndex: "round_num",
      key: "round_num",
      render:(round_num)=>{
       const round=round_num==0?"-":round_num
        return <div style={{color:"#2C5F99"}}>{round}</div>
      }
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: (text, record) => (
    //     <Button
    //       onClick={() => viewApplication(record)}
    //       // className="view-button"
    //       style={{
    //         backgroundColor: "#1681FF",
    //         color: "#fff",
    //         border: "none",
    //         font:"5px",
    //         // padding: "10px",
    //         cursor: "pointer",
    //         width:"150px"
    //       }}
    //     >
    //       View Application
    //     </Button>
    //   ),
    // },
  ];
  
  const viewApplication=()=>{
    console.log("view applications")
  }

  return (
    <Main defaultSelectedKey="2">
      <div className="cand-applications-conatiner">
      <h2 style={{color:"#565E6C"}}>Candidate Applications</h2>
      <Table columns={columns} dataSource={data} rowKey="id" pagination={{ pageSize: 5 }} />
      </div>
     </Main>
  );
};

export default CandidateApplications;
