import React, { useState } from 'react';
import logo from "../../images/GAHIRESYNC-LOGO.svg";
import { Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import "./TopNav.css";
import { useNavigate } from 'react-router-dom';

const menuItems = [
    {
        label: "Features",
        key: "1",
        children: [
            { label: "Feature 1", key: "1-1", url: "/feature1" },
            { label: "Feature 2", key: "1-2", url: "/feature2" },
            { label: "Feature 3", key: "1-3", url: "/feature3" }
        ]
    },
    {
        label: "Solutions",
        key: "2",
        children: [
            { label: "Solution 1", key: "2-1", url: "/solution1" },
            { label: "Solution 2", key: "2-2", url: "/solution2" },
            { label: "Solution 3", key: "2-3", url: "/solution3" }
        ]
    },
    { label: "Pricing", key: "3", url: "/pricing" },
    { label: "About Us", key: "4", url: "/about-us" },
    { label: "Contact Us", key: "5", url: "/contact-us" }
];

const TopNav = ({ color }) => {
    const navigate = useNavigate();
    const [activeDropdown, setActiveDropdown] = useState(null);

    return (
        <div className={`${color === 'blue' ? 'blue' : ""}  top-nav`}>
            <span className="logo">
                <img src={logo} alt="Logo" style={{ cursor: 'pointer' }} onClick={() => navigate('/')} />
            </span>
            <div className="menu-items">
                {menuItems.map((item) =>
                    item.children ? (
                        <div
                            key={item.key}
                            className="dropdown-container"
                            onMouseEnter={() => setActiveDropdown(item.key)}
                            onMouseLeave={() => setActiveDropdown(null)}
                        >
                            <span className="nav-label">
                                {item.label} <DownOutlined className={`dropdown-icon ${activeDropdown === item.key ? 'rotated' : ''}`} />
                            </span>
                            {activeDropdown === item.key && (
                                <div className="dropdown-content">
                                    {item.children.map((child) => (
                                        <div
                                            key={child.key}
                                            className="dropdown-item"
                                            onClick={() => navigate(child.url)}
                                        >
                                            {child.label}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ) : (
                        <span
                            key={item.key}
                            className="nav-label"
                            onClick={() => navigate(item.url)}
                        >
                            {item.label}
                        </span>
                    )
                )}
            </div>
            <div className="signup">
                <button
                    style={{}}
                    onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "white";
                        e.target.style.color = "#000";
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "transparent";
                        e.target.style.color = "white";
                    }}
                    onClick={() => navigate('/login')}>Login / Signup</button>
            </div>
        </div>
    );
};

export default TopNav;
