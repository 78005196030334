
import React from 'react'
import Layout from "antd/es/layout/layout";
import logo from "./../../../images/GAHIRESYNC-LOGO.svg"
import MainLayout from '../../common/Layout/MainLayout';
import dashboard from '../../../images/rctr/rctrdashboardicon.svg'
import inoviceicon from '../../../images/Client/Invoices.svg'
const { Header, Sider } = Layout


const Main = ({ children , defaultSelectedKey}) => {


    const accountantoptions = [
      {
        "key": 1,
        "label": "Dashboard",
        "logo": dashboard,
        "path": '/'
      },
      {
        "key": 2,
        "label": "Invoices",
        "logo": inoviceicon,
        "path": '/accountant/invoice'
      },

      // {
      //   "key": 2,
      //   "label": "Jobs",
      //   "logo": jobs,
      //   "path": '/client/mypostings'
      // },
      // {
      //   "key": 3,
      //   "label": "Applications",
      //   "logo": files,
      //   "path": '/client/applications'
      // },
      // {
      //   "key": 4,
      //   "label": "Candidates",
      //   "logo": candidates,
      //   'path': '/client/candidates',
      // },
      // {
      //   "key": 5,
      //   "label": "Approvals",
      //   "logo": secure,
      //   "path": "/client/approvals/"
      // },
      // {
      //   "key": 6,
      //   "label": "Interviews",
      //   "logo": interviewers,
      //   "path": '/client/interviewers'
      // },
      // {
      //   "key": 7,
      //   "label": "Replacements",
      //   "logo": Replace,
      //   "path": '/client/replacements'
      // },
      // {
      //   "key": 8,
      //   "label": "Invoices",
      //   "logo": Invoice,
      //   "path": '/client/invoices'
      // },
  
    ]

  return (
    <MainLayout children={children} defaultSelectedKey={defaultSelectedKey} options={accountantoptions}></MainLayout>
  )
}

export default Main
