import React from 'react'
import { Tabs } from 'antd'
import AllBlogs from './AllBlogs'
import ApproveBlogs from './ApproveBlogs'
import Main from "../Layout"

const AdminBlogs = () => {
    const items = [
        {
            key: "1",
            label: 'All Blogs',
            children: <AllBlogs />
        },
        {
            key: "2",
            label: 'Blogs to be Approved',
            children: <ApproveBlogs />
        },

    ]
    return (
        <Main>
            <Tabs items={items} defaultActiveKey='1'></Tabs>
        </Main>
    )
}

export default AdminBlogs