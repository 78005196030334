import React, { useState, useEffect } from 'react'
import './Layout.css'
import logo from "./../../../images/GAHIRESYNC-LOGO.svg";
import { useNavigate } from 'react-router-dom';
import { CaretDownOutlined } from '@ant-design/icons';
import { useAuth } from '../useAuth';
import Ticket from "../../../images/ticket.svg"

const MainLayout = ({ children, defaultSelectedKey, options }) => {
    const navigate = useNavigate()
    const [selected, setSelected] = useState(defaultSelectedKey || 1);
    const [user, setUser] = useState();
    const { userData } = useAuth();

    useEffect(() => {
        if (userData) {
            try {
                const parsedUser = typeof userData === "string" ? JSON.parse(userData) : userData;
                setUser(parsedUser);
                console.log(parsedUser, "is the parsed user")
            } catch (error) {
                console.error("Error parsing userData:", error);
                setUser(null);
            }
        }
    }, [userData])


    const handleNavigation = (item) => {
        if (item.Logout) {
            item.Logout(); // call the logout function
        } else {
            // navigate(item.path); // normal navigation
            setSelected(item.key);
            navigate(item.path);
        }
        // setSelected(item.key); // keep your selection logic
    };

    return (
        <div className='layout'>
            <div className="top">
                <div className="ticket" onClick={() => { navigate("/tickets") }}>
                    <img src={Ticket} alt="Ticket " />
                </div>
                <div className="profile" onClick={() => { navigate("/profile") }}>
                    <span className='profile-section'> {user ? user.username : "Loading..."} <CaretDownOutlined /> </span>
                </div>
            </div>
            <div className="sider">
                <a href="/home" className='logo'>
                    <img alt="logo" src={logo}></img>
                </a>
                {options.map((item, index) => (
                    <div
                        className={`${selected == item.key ? 'selected-option' : ""} section `}
                        onClick={() => handleNavigation(item)}>
                        <img src={item.logo} alt={item.label} className='side-logo' />
                        <span className="name">{item.label}</span>
                    </div>
                ))}
            </div>

            <div className="center">
                {children}
            </div>
        </div>
    )
}

export default MainLayout