import React, { Children } from 'react'
import "./ManagerProfile.css"
import ProfileCard from '../../../common/CommonCards/ProfileCard/ProfileCard'
import Main from "../Layout"
import OrganizationTerms from '../TermsAndConditions/TermsAndConditions'
import { Tabs } from 'antd'



const ManagerProfile = () => {

    const items = [
        {
            label: "Terms and Conditions",
            key: "1",
            children: <OrganizationTerms />
        }
    ]
    return (
        <Main>
            <ProfileCard></ProfileCard>
            <Tabs style={{ marginBottom: "30px" }} items={items} defaultActiveKey='1'></Tabs>
        </Main>
    )
}

export default ManagerProfile