import React, { useEffect, useState } from 'react';
import Main from '../Layout';
import { useAuth } from '../../../common/useAuth';
import { message } from 'antd';
import './ReopenJobsList.css';
import { useNavigate } from 'react-router-dom';

const ReopenJobsList = () => {
    const { token, apiurl } = useAuth();
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const response = await fetch(`${apiurl}/client/closed-jobslist/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const result = await response.json();
            if (result.error) {
                message.error(result.error);
            } else {
                setData(result);
            }
        } catch (e) {
            console.log(e);
            message.error("An error occurred while fetching data.");
        }
    };

    useEffect(() => {
        if (token) {
            fetchData();
        }
    }, [token]);

    return (
        <Main defaultSelectedKey='1'>
            <div className="reopen-jobs-list">
                <h2 className="reopen-heading">Reopen Job Posts</h2>
                {data.length > 0 ? (
                    <div className="job-list">
                        {data.map((item, index) => (
                            <div className="job-card" key={index}>
                                <h3>{item.job_title}</h3>
                                <p><strong>Job Department:</strong> {item.job_department}</p>
                                <p><strong>Organization:</strong> {item.organization}</p>
                                <button className="renew-btn" onClick={() => {
                                    navigate(`/client/reopen-job/${item.job_id}`)
                                }}>Renew Job</button>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className="no-data">No closed job posts available.</p>
                )}
            </div>
        </Main>
    );
};

export default ReopenJobsList;
