import React, { useEffect, useState } from "react";
import { useAuth } from "../../../common/useAuth";
import { UploadOutlined } from "@ant-design/icons";
import { message, Form, Input, Button, DatePicker, Upload, Image } from "antd";
import dayjs from "dayjs";
import "./CandidateDetails.css";
import { a } from "framer-motion/client";

const CandidateDetails = () => {
  const { token, apiurl } = useAuth();
  const [data, setData] = useState();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState();

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiurl}/candidate/profile/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      });

      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      const data = await response.json();
      if (data.error) {
        message.error(data.error);
      } else {
        if (data.date_of_birth) {
          data.date_of_birth = dayjs(data.date_of_birth, "YYYY-MM-DD");
        }
        setData(data);
      }
    } catch (e) {
      message.error(`Failed to fetch profile: ${e.message}`);
    }
  };

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();

      for (const key in values) {
        if (key === "resume" && values[key]?.file) {
          formData.append(key, values[key].file);
        } else if (key === "date_of_birth" && values[key] != null) {
          formData.append(key, dayjs(values[key]).format("YYYY-MM-DD"));
        } else if (values[key] != null) {
          formData.append(key, values[key]);
        }
      }
      const response = await fetch(`${apiurl}/candidate/profile/`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const data = await response.json();

      if (data.error) {
        message.error("Error while submitting the file");
      } else if (data.message) {
        message.success(data.message); // Fixed incorrect key
      }
    } catch (e) {
      console.log(e);
      message.error("error occured"); // Improved error handling
    }
  };

  return (
    <div>
      {message && message.success && (
        <div className="success">{message.success}</div>
      )}
      {message && message.error && <div className="error">{message.error}</div>}
      {/* <h2>Basic Details</h2> */}
      {data && data.name && (
        <div
          style={{
            display: "block",
            width: "95%",
            margin: "0px auto",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)"
          }}
        >
          <Form
            form={form}
            onFinish={handleSubmit}
            initialValues={{
              ...data,
              name: data?.name?.username,
            }}
            layout="vertical"
          >
            {!data?.resume && (
              <Form.Item label="Resume" name="resume">
                <Upload
                  fileList={fileList}
                  onChange={() => {
                    setFileList();
                  }}
                  beforeUpload={() => false}
                  maxCount={1}
                >
                  <Button icon={<UploadOutlined />}>
                    Upload Resume
                  </Button>
                </Upload>
              </Form.Item>
            )
            }
            {data?.resume && (
              <a style={{ border: "2px solid black", padding: '5px', borderRadius: '5px', marginBottom: '10px' }} href={`${apiurl}/${data.resume}`} target="_blank">
                Current Resume
              </a>
            )}
            {
              data?.resume && (
                <Form.Item label="Update Resume" name="resume">
                  <Upload
                    fileList={fileList}
                    onChange={() => {
                      setFileList();
                    }}
                    beforeUpload={() => false} // Prevent automatic upload
                    accept="image/*"
                    maxCount={1} // Allow only a single file
                  >
                    <Button icon={<UploadOutlined />}>Update Resume</Button>
                  </Upload>
                </Form.Item>
              )
            }
            {/* {data?.profile && (
              <Image
                src={`${apiurl}${data.profile}`}
                alt="Candidate picture here"
                style={{ width: "150px", borderRadius: "50% 50%" }}
              />
            )} */}
            {/* {data?.profile && (
              <Form.Item label="Profile Image" name="profile">
                <Upload
                  fileList={fileList}
                  onChange={() => {
                    setFileList();
                  }}
                  beforeUpload={() => false} // Prevent automatic upload
                  accept="image/*"
                  maxCount={1} // Allow only a single file
                >
                  <Button icon={<UploadOutlined />}>Update profile</Button>
                </Upload>
              </Form.Item>
            )} */}
            {/* {!data.profile && (
              <Form.Item label="Profile Image" name="profile">
                <Upload
                  fileList={fileList}
                  onChange={() => {
                    setFileList();
                  }}
                  beforeUpload={() => false} // Prevent automatic upload
                  accept="image/*"
                  maxCount={1} // Allow only a single file
                >
                  <Button icon={<UploadOutlined />}>
                    Upload Profile Image
                  </Button>
                </Upload>
              </Form.Item>
            )} */}
            <div
              className="first-middle-last-names"
            >
              {/* First Name */}
              <Form.Item shouldUpdate>
                {() => (
                  <div className="form-element-style">
                    <label >First Name :</label>
                    <Form.Item
                      name="first_name"
                      noStyle
                      rules={[
                        { required: true, message: "First name is required" },
                      ]}
                    >
                      <Input style={{ flex: 1 }} className="form-input-style" />
                    </Form.Item>
                  </div>
                )}
              </Form.Item>
              {/* Middle Name */}
              <Form.Item shouldUpdate>
                {() => (
                  <div className="form-element-style">
                    <label >Middle Name :</label>
                    <Form.Item
                      name="middle_name"
                      noStyle
                      rules={[
                        { required: true, message: "Middle name is required" },
                      ]}
                    >
                      <Input style={{ flex: 1 }} className="form-input-style" />
                    </Form.Item>
                  </div>
                )}
              </Form.Item>
              {/* Last Name */}
              <Form.Item shouldUpdate>
                {() => (
                  <div className="form-element-style">
                    <label >Last Name :</label>
                    <Form.Item
                      name="last_name"
                      noStyle
                      rules={[
                        { required: true, message: "Last name is required" },
                      ]}
                    >
                      <Input style={{ flex: 1 }} className="form-input-style" />
                    </Form.Item>
                  </div>
                )}
              </Form.Item>
            </div>
            <div className="name-email-div">
              {/* Name */}
              <Form.Item shouldUpdate>
                {() => (
                  <div className="form-element-style">
                    <label>Name:</label>
                    <Form.Item name="name" noStyle>
                      <Input disabled className="form-input-style" />
                    </Form.Item>
                  </div>
                )}
              </Form.Item>

              {/* Email */}
              <Form.Item shouldUpdate>
                {() => (
                  <div className="form-element-style">
                    <label>Email:</label>
                    <Form.Item name="email" noStyle>
                      <Input disabled className="form-input-style" />
                    </Form.Item>
                  </div>
                )}
              </Form.Item>
            </div>
            {/* Communication Address */}
            <Form.Item shouldUpdate>
              {() => (
                <div className="form-element-style">
                  <label style={{ width: "180px" }}>
                    Communication Address
                  </label>
                  <Form.Item
                    name="communication_address"
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: "Communication address is required",
                      },
                    ]}
                  >
                    <Input.TextArea
                      rows={2}
                      style={{ flex: 1 }}
                      className="form-input-style"
                    />
                  </Form.Item>
                </div>
              )}
            </Form.Item>
            {/* Permanent Address */}
            <Button
              style={{ marginBottom: "20px" }}
              onClick={() => {
                const communicationAddress = form.getFieldValue(
                  "communication_address"
                );
                if (communicationAddress) {
                  form.setFieldsValue({
                    permanent_address: communicationAddress,
                  });
                } else {
                  message.error("Please fill the communication address first.");
                }
              }}
            >
              Same as communication address
            </Button>
            <Form.Item shouldUpdate>
              {() => (
                <div className="form-element-style">
                  <label style={{ width: "180px" }}>Permanent Address</label>
                  <Form.Item
                    name="permanent_address"
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: "Perminant address is required",
                      },
                    ]}
                  >
                    <Input.TextArea
                      rows={2}
                      style={{ flex: 1 }}
                      className="form-input-style"
                    />
                  </Form.Item>
                </div>
              )}
            </Form.Item>
            {/* Phone Number */}
            <div className="name-email-div">
              <Form.Item shouldUpdate>
                {() => (
                  <div
                    //   style={{ display: "flex", alignItems: "center", gap: "10px" }}
                    className="form-element-style"
                  >
                    <label style={{ width: "180px" }}>Phone Number</label>
                    <Form.Item
                      name="phone_num"
                      noStyle
                      rules={[
                        { required: true, message: "Phone number is required" },
                      ]}
                    >
                      <Input style={{ flex: 1 }} className="form-input-style" />
                    </Form.Item>
                  </div>
                )}
              </Form.Item>
              {/* Date of Birth */}
              <Form.Item shouldUpdate>
                {() => (
                  <div
                    //   style={{ display: "flex", alignItems: "center", gap: "10px" }}
                    className="form-element-style"
                  >
                    <label style={{ width: "180px" }}>Date of Birth</label>
                    <Form.Item name="date_of_birth" noStyle>
                      <DatePicker
                        format="YYYY-MM-DD"
                        // style={{ flex: 1, width: "100%" }}
                        className="form-input-style"
                      />
                    </Form.Item>
                  </div>
                )}
              </Form.Item>
            </div>
            <div className="name-email-div">
              <Form.Item shouldUpdate>
                {() => (
                  <div
                    //   style={{ display: "flex", alignItems: "center", gap: "10px" }}
                    className="form-element-style"
                  >
                    <label style={{ width: "180px" }}>Blood Group</label>
                    <Form.Item name="blood_group" noStyle>
                      <Input style={{ flex: 1 }} className="form-input-style" />
                    </Form.Item>
                  </div>
                )}
              </Form.Item>
              {/* Years of Experience */}
              <Form.Item shouldUpdate>
                {() => (
                  <div
                    //   style={{ display: "flex", alignItems: "center", gap: "10px" }}
                    className="form-element-style"
                  >
                    <label style={{ width: "180px" }}>Years of Experience</label>
                    <Form.Item name="experience_years" noStyle>
                      <Input style={{ flex: 1 }} className="form-input-style" />
                    </Form.Item>
                  </div>
                )}
              </Form.Item>
            </div>
            {/* Designation */}
            <Form.Item shouldUpdate>
              {() => (
                <div
                  //   style={{ display: "flex", alignItems: "center", gap: "10px" }}
                  className="form-element-style"
                >
                  <label style={{ width: "180px" }}>Designation</label>
                  <Form.Item name="designation" noStyle>
                    <Input style={{ flex: 1 }} className="form-input-style" />
                  </Form.Item>
                </div>
              )}
            </Form.Item>
            {/* Social Media Links */}
            <Form.Item shouldUpdate>
              {() => (
                <div
                  //   style={{ display: "flex", alignItems: "center", gap: "10px" }}
                  className="form-element-style"
                >
                  <label style={{ width: "180px" }}>LinkedIn Profile</label>
                  <Form.Item
                    name="linked_in"
                    noStyle
                    rules={[
                      {
                        type: "url",
                        message: "Please enter a valid URL",
                      },
                      {
                        pattern:
                          /^(https?:\/\/)?(www\.)?linkedin\.com\/[a-zA-Z0-9_]+$/,
                        message:
                          "Please enter a valid LinkedIn URL (e.g., https://linkedin.com/in/username)",
                      },
                    ]}
                  >
                    <Input
                      placeholder="https://linkedin.com/in/..."
                      style={{ flex: 1 }}
                      className="form-input-style"
                    />
                  </Form.Item>
                </div>
              )}
            </Form.Item>
            {/* Skills */}
            <Form.Item label="Skills" name="skills">
              <Input.TextArea
                rows={3}
                placeholder="e.g., React, Django, Python"
              />
            </Form.Item>
            {/* Submit Button */}
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
};

export default CandidateDetails;
