import React from "react";
import "./CandidatesCard.css";
import dummyprofile from "../../../../images/agency/job-postings/dummyprofile.svg";
import Eye from "../../../../images/agency/job-postings/Eye.svg";
import bookicon from "../../../../images/agency/job-postings/bookicon.svg";
import { useNavigate } from "react-router-dom";

const CandidatesCard = ({candidateName,resumeId,applicationStatus,jobTitle,jobDescription}) => {

  const navigate=useNavigate()
console.log("this is running")
console.log("candidateName",candidateName)


console.log("by this resume id fetch the show the candidate profile if agency clicked on the details button ",resumeId)

  return (
    <div className="cand-card-container">
      <div className="profile-content-container">
        <div
          className="agency-cand-profile-div"
        >
          <img src={dummyprofile} style={{ width: "35px" }}  />
        </div>
        <div className="profile-content">
          <div
            className="profile-content-row1"
            style={{ display: "flex", gap: "100px", alignItems: "center" }}
          >
            {candidateName}
            <button
            className="cand-job-status"
            
            >
              {applicationStatus}
            </button>
          </div>
          <div
            className="profile-content-row2"
            style={{ display: "flex", gap: "1px" }}
          >
            <img src={bookicon}></img>
            <p style={{ margin: "6px" }}>{jobTitle}</p>
          </div>
          <div
            className="profile-content-row3"
          >
            {/* Scoped Class Names: I added a custom prefix (e.g.,
            .job-responses-container-custom, .search-button, etc.) to ensure
            that these styles apply only to elements with the corresponding
            classes within the specific component. This will reduce the risk of
            these styles affecting other parts of your project. */}
            {jobDescription}
          </div>
        </div>
      </div>
      <div
      className="cand-details-button"
       
      >
        <img src={Eye} style={{ width: "15px" }} />
        <h3
         onClick={() => navigate('/candidate/profile')}
        >
          Detail
        </h3>
      </div>
    </div>
  );
};

export default CandidatesCard;
