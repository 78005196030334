import React, { useState, useEffect } from 'react'
import Main from "../Layout"
import "./Resumes.css"
import JobDescription from "../../../../images/Client/CreateJob/Jobdescription.svg"
import Note from "../../../../images/Client/note.svg"
import Experience from "../../../../images/Client/Experience.svg"
import Notice from "../../../../images/Client/Vacancies.svg"
import Bag from "../../../../images/Client/Bag.svg"
import { useAuth } from '../../../common/useAuth'
import { useNavigate, useParams } from 'react-router-dom'
import { Modal } from 'antd'
import ViewResume from './ViewResume'


const ApplicationCard = ({ item, handleCompareToggle, isCompared }) => {
    const [viewResume, setViewResume] = useState(false);
    const [id, setId] = useState()
    const handleViewDetails = (applicationId) => {
        setId(applicationId);
        setViewResume(true);
    };
    return (
        <div className="application-card">
            <div className="line1">
                <div className="name">
                    <span className="candidate-name">{item.candidate_name}</span>
                    <span className="email">{item.date_of_birth}</span>
                </div>
                <div className="status">
                    {item?.job_application?.status === "processing" || item?.job_application?.status === "selected" ? (
                        <span className="status-green">
                            {item?.job_application?.status === "processing" ? "Shortlisted" : "Selected"}
                        </span>
                    ) : item?.job_application?.status === "rejected" || item?.job_application?.status === "left" ? (
                        <span className="status-red">{item?.job_application.status}</span>
                    ) : (
                        <span>{item?.job_application?.status}</span>
                    )}
                </div>
            </div>
            <div className="line2">
                <span className="notice line2-option"><img className='notice' src={Notice} alt="" />{item.notice_period} Days</span>
                <span className="experience line2-option"><img src={Experience} alt="" />{item.experience} yrs experience</span>
            </div>
            <div className="line3">
                <span className="current line3-option">{item.current_ctc}LPA Current CTC</span>
                <span className="experience line3-option">{item.expected_ctc}LPA Expected CTC</span>
            </div>
            <div className="last">
                <button
                    className='view-details'
                    onClick={() => handleViewDetails(item?.job_application.id)}
                >
                    View Details
                </button>
                <button
                    className={'add-to-compare'}
                    onClick={() => handleCompareToggle(item.job_application.id)}
                >
                    {isCompared ? 'Remove from Compare' : 'Add to Compare'}
                </button>
            </div>
            <Modal open={viewResume} onCancel={() => setViewResume(false)} footer={null}>
                <ViewResume id={id} onCancel={() => setViewResume(false)} />
            </Modal>
        </div>
    )
}

const Resumes = () => {
    const { apiurl, token } = useAuth();
    const [job, setJob] = useState();
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [compareList, setCompareList] = useState([]);
    const navigate = useNavigate();

    // Load compare list from sessionStorage on mount
    useEffect(() => {
        const storedData = JSON.parse(sessionStorage.getItem('compareList')) || {};
        const existingList = storedData[id] || [];
        setCompareList(existingList);
    }, [id]);

    // Fetch data
    const fetchData = async () => {
        try {
            const response = await fetch(
                `${apiurl}/client/get-resumes/?jobid=${id}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            setJob(data.job_data);
            setData(data.data);
            console.log(data);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (token) {
            fetchData();
        }
    }, [token, id]);

    // Handle Add/Remove from Compare
    const handleCompareToggle = (applicationId) => {
        const storedData = JSON.parse(sessionStorage.getItem('compareList')) || {};
        const currentList = storedData[id] || [];

        // If already present, remove
        if (currentList.includes(applicationId)) {
            const updatedList = currentList.filter(id => id !== applicationId);
            storedData[id] = updatedList;
            sessionStorage.setItem('compareList', JSON.stringify(storedData));
            setCompareList(updatedList);
        } else {
            // Add if less than 3
            if (currentList.length >= 3) {
                alert('You can only compare up to 3 candidates!');
                return;
            }
            const updatedList = [...currentList, applicationId];
            storedData[id] = updatedList;
            sessionStorage.setItem('compareList', JSON.stringify(storedData));
            setCompareList(updatedList);
        }
    };

    return (
        <Main defaultSelectedKey="3">
            <div className="received-applications-client">
                {job &&
                    <div className="job-post-header">
                        <div className="job-post-details">
                            <div className="left-side">
                                <img src={JobDescription} alt="" />
                                <span className="job-details-heading">Job Details</span>
                            </div>
                            <div className="cart" style={{ position: 'relative' }}>
                                <button
                                    onClick={() => navigate(`/client/get-resumes/compare/${id}`)}
                                    disabled={compareList.length < 2}
                                    title={compareList.length < 2 ? 'Select at least 2 applications to compare' : 'Click to compare selected applications'}
                                    style={{
                                        position: 'relative',
                                        cursor: compareList.length < 2 ? 'not-allowed' : 'pointer',
                                        opacity: compareList.length < 2 ? 0.6 : 1, // make it look disabled
                                    }}

                                >
                                    Compare List
                                    {compareList.length > 0 && (
                                        <span
                                            style={{
                                                position: 'absolute',
                                                top: '-5px',
                                                right: '-5px',
                                                background: 'red',
                                                color: 'white',
                                                borderRadius: '50%',
                                                width: '20px',
                                                height: '20px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                fontSize: '12px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {compareList.length}
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className="ctc-details">
                            <span className="ctc">
                                <img src={Note} alt="" /> {job.ctc}
                            </span>
                            <span className="job_title">
                                <img src={Bag} alt="" /> {job.job_title}
                            </span>
                        </div>
                        <div className="job-description">
                            <span>{job.job_description}</span>
                        </div>
                    </div>
                }

                <div style={{ color: '#1681FF', fontSize: '25px', fontWeight: '600', marginTop: "15px" }}>
                    Applications Received
                </div>

                <div className="applications">
                    {data &&
                        data.map((item, index) => (
                            <ApplicationCard
                                key={index}
                                item={item}
                                handleCompareToggle={handleCompareToggle}
                                isCompared={compareList.includes(item.job_application.id)} // Check if in compare list
                            />
                        ))
                    }
                </div>
            </div>
        </Main>
    )
}

export default Resumes;
