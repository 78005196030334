import React, { useState, useEffect } from "react";
import { useAuth } from "../../../common/useAuth";
import { Button, message, Modal, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import Table from "../../../common/Table";
import "./ScheduledInterviews.css";
import { render } from "@testing-library/react";

const apiurl = process.env.REACT_APP_BACKEND_URL;

const ScheduledInterviews = () => {
  const { token } = useAuth();
  const [data, setData] = useState([]);
  const [resumeData, setResumeData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const navigate = useNavigate();

  const rowStyle = {
    display: 'flex',
    padding: '10px',
    borderBottom: '1px solid #e8e8e8',
    alignItems: 'center',
  };

  const labelStyle = {
    width: '160px', // Fixed width for all labels for alignment
    fontWeight: 'bold',
  };

  const valueStyle = {
    flex: 1, // Take remaining space
    wordBreak: 'break-word',
  };


  const fetchData = async (page = 1) => {
    try {
      const response = await fetch(
        `${apiurl}/interviewer/scheduled-interviews/?page=${page}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      if (data.error) {
        message.error(data.error);
        return;
      }
      setData(data.results);
      setTotal(data.count);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (token) {
      fetchData(currentPage);
    }
  }, [token, currentPage]);

  const columns = [
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
      render: (title) => (
        <div style={{ color: "#2C5F99", fontWeight: "600" }}>
          {title}
        </div>
      )
    },
    {
      title: "Rounds of Interview",
      dataIndex: "round_of_interview",
      key: "round_of_interview",
    },
    {
      title: "Candidate Name",
      dataIndex: "candidate_name",
      key: "candidate_name",
      render: (candidate_name, record) => (
        <div className="candidate-name" style={{ cursor: 'pointer' }} onClick={() => handleViewProfile(record.application_id)}>
          {candidate_name}
        </div>
      )
    },
    {
      title: "Scheduled Time",
      dataIndex: "scheduled_date",
      key: "scheduled_date",
      render: (text) => new Date(text).toLocaleDateString(),
      sorter: (a, b) => new Date(a.schedule_date) - new Date(b.schedule_date),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: "Timings",
      dataIndex: "timings",
      key: "timings",
      render: (text) => {
        if (!text) return '-';
        return text
          .split(' - ') // split start and end time
          .map(time => time.split(':').slice(0, 2).join(':')) // remove seconds
          .join(' - '); // join back as range
      }
    },
    {
      title: "Conduct Interview",
      dataIndex: "interview_id",
      key: "interview_id",
      render: (interview_id, record) => {
        if (record.status === "completed") {
          return <p>Completed</p>;
        } else if (record.status === "selected") {
          return <p>Shortlisted</p>;
        } else if (record.status === "rejected") {
          return <p>Rejected</p>;
        }
        return (
          <Button
            onClick={() =>
              navigate(`/interviewer/conduct-interview/${interview_id}`)
            }
          >
            Conduct Interview
          </Button>
        );
      },
    },
  ];

  const handleViewProfile = async (id) => {
    setModalVisible(true);
    setLoading(true);

    try {
      const response = await fetch(`${apiurl}/basic-application-details/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (data.error) {
        message.error(data.error);
        return;
      } else if (data.message) {
        message.success(data.message);
        return;
      }
      setResumeData(data);
      console.log(data, "is the resume data");
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  return (
    <div defaultSelectedKey="2">
      {data && (
        <div className="custom-table-scheduled-interviews">
          <Table
            columns={columns}
            data={data}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: total, // Total interviews count for pagination
              onChange: (page, pageSize) => {
                setCurrentPage(page);
                setPageSize(pageSize);
              },
              showSizeChanger: true,
              pageSizeOptions: ['5', '10', '20', '50'],
            }}
          />
        </div>
      )}

      {/* Modal for showing resume data */}
      <Modal
        title="Candidate Profile"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={600}
      >
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <Spin size="large" />
            <p>Loading...</p>
          </div>
        ) : (
          resumeData && (
            <div style={{ border: "1px solid #f0f0f0", borderRadius: "8px", overflow: "hidden", marginTop: "10px" }}>
              {/* Row Wrapper */}
              <div style={rowStyle}>
                <div style={labelStyle}><strong>Name:</strong></div>
                <div style={valueStyle}>{resumeData.candidate_name}</div>
              </div>

              <div style={rowStyle}>
                <div style={labelStyle}><strong>Experience:</strong></div>
                <div style={valueStyle}>{resumeData.experience}</div>
              </div>

              <div style={rowStyle}>
                <div style={labelStyle}><strong>Current CTC:</strong></div>
                <div style={valueStyle}>{resumeData.current_ctc}</div>
              </div>

              <div style={rowStyle}>
                <div style={labelStyle}><strong>Current Job Type:</strong></div>
                <div style={valueStyle}>{resumeData.current_job_type}</div>
              </div>

              <div style={rowStyle}>
                <div style={labelStyle}><strong>Expected CTC:</strong></div>
                <div style={valueStyle}>{resumeData.expected_ctc}</div>
              </div>

              <div style={rowStyle}>
                <div style={labelStyle}><strong>Notice Period:</strong></div>
                <div style={valueStyle}>{resumeData.notice_period}</div>
              </div>

              <div style={rowStyle} >
                <div style={labelStyle}><strong>Resume:</strong></div>
                <div style={valueStyle}>
                  <a
                    href={`${apiurl}${resumeData.resume}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#1890ff", textDecoration: "none" }}
                  >
                    View Resume
                  </a>
                </div>
              </div>
            </div>
          )
        )}
      </Modal>

    </div>
  );
};

export default ScheduledInterviews;
