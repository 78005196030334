import React, { useEffect, useState } from 'react'
import ProfileCard from '../../../common/CommonCards/ProfileCard/ProfileCard';
import Main from '../Layout';
import "./RecruiterProfile.css"

const RecruiterProfile = () => {
    return (
        <Main >
            <ProfileCard></ProfileCard>
            <h1>profile card called</h1>
        </Main>
    )
}

export default RecruiterProfile