import React, { useEffect, useState } from 'react';
import Main from './Layout';
import { useAuth } from '../../common/useAuth';
import { message, Table, Tag } from 'antd';
import { render } from '@testing-library/react';
import Interviewer from '../Interviewer/Interviewer';
import './UpcomingInterviews.css'

const UpcomingInterviews = () => {
    
    const { token, apiurl } = useAuth();
    const [data, setData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await fetch(`${apiurl}/candidate/upcoming-interviews/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const result = await response.json();
            if (result.error) {
                message.error(result.error);
            } else {
                // Map data to include unique keys for each row
                const formattedData = result.map((item, index) => ({
                    ...item,
                    key: index, // Ant Design Table requires a unique 'key' for each row
                }));
                setData(formattedData);
            }
        } catch (e) {
            message.error(e.message || 'Something went wrong.');
        }
    };

    useEffect(() => {
        if (token) {
            fetchData();
        }
    }, [token]);

    const columns = [
        // {
        //     title: 'Job ID',
        //     dataIndex: 'job_id',
        //     key: 'job_id',
        //     render: (job_id) => {
        //         return <p>{job_id.id}</p>
        //     }
        // },
        {
            title: 'Company Name',
            dataIndex: 'job_id',
            key: 'job_id',
            render: (job_id) => {
                return <p style={{color:"#565E6C"}}>{job_id.company_name}</p>
            }
        },
        {
            title: 'Job Title',
            dataIndex: 'job_id',
            key: 'job_id',
            render: (job_id) => {
                return <p style={{color:"#2C5F99"}}>{job_id.job_title}</p>
            }
        },
      
        {
            title: 'Round Number',
            dataIndex: 'round_num',
            key: 'round_num',
        },
        {
            title: 'Interviewer Name',
            dataIndex: 'interviewer_name',
            key: 'interviewer_name',
        },
       
        {
            title: 'Type of Interview',
            dataIndex: ["scheduled_date_and_time", "interviewer", "type_of_interview"],
            key: 'type_of_interview',
            render: (text, record) => 
                record?.scheduled_date_and_time?.interviewer?.type_of_interview || "N/A", // Safe fallback
        },
        {
            title: 'Mode of Interview',
            dataIndex: ["scheduled_date_and_time", "interviewer", "mode_of_interview"],
            key: 'mode_of_interview',
            render: (text, record) => 
                record?.scheduled_date_and_time?.interviewer?.mode_of_interview || "N/A", // Safe fallback
        },
        {
            title: 'Date of Interview',
            dataIndex: ["scheduled_date_and_time", "scheduled_date"],
            key: 'scheduled_date',
            render: (text, record) => 
                record?.scheduled_date_and_time?.scheduled_date || "N/A", // Safe fallback
        },
        {
            title: 'Time of Interview',
            dataIndex: ["scheduled_date_and_time", "from_time"],
            key: 'from_time',
            render: (text, record) => 
                record?.scheduled_date_and_time?.from_time || "N/A", // Safe fallback
        },

        {
            title: 'status of Interview',
            dataIndex: ["scheduled_date_and_time", "status"],
            key: 'status',
            render: (text, record) => {
                const status = record?.scheduled_date_and_time?.status || "N/A"; // Safe fallback
        
                // Define status colors
                const statusColors = {
                    scheduled: "blue",
                    completed: "green",
                    // cancelled: "red",
                    pending: "red",
                };
        
                return (
                    <Tag color={statusColors[status.toLowerCase()] || "default"}>
                        {status.toUpperCase()}
                    </Tag>
                );
            },
        }
        

        // status 


    ];

    return (
        <Main defaultSelectedKey="3">
            <div className='cand-upcoming-interviews'>
          
            <h1> Interviews</h1>
            {data.length >0 ? (
                <Table columns={columns} dataSource={data} />
            ) : (
                <p>No upcoming interviews found.</p>
            )}
              </div>
        </Main>
    );
};

export default UpcomingInterviews;
