import React from 'react';
import './RctrSumInterviewCard.css';

const RctrSumInterviewCard = ({ cand, interviewer, fromtime, totime, round }) => {
  // Function to calculate duration in minutes
  const getDurationInMinutes = () => {
    if (!fromtime || !totime) return "N/A"; // Handle missing values

    const from = fromtime.split(":").map(Number);
    const to = totime.split(":").map(Number);

    if (from.length < 2 || to.length < 2) return "N/A"; // Handle incorrect formats

    const fromMinutes = from[0] * 60 + from[1];
    const toMinutes = to[0] * 60 + to[1];

    const duration = toMinutes - fromMinutes;
    return duration > 0 ? duration : "N/A"; // Ensure valid duration
  };

  const duration = getDurationInMinutes(); // Compute once

  return (
    <div className="Rctr-sum-interview-card">
      <div className="time-section">
        <p className="time" style={{ margin: "0px", padding: "0px" }}>{fromtime.slice(0, 5) || "N/A"}</p>
        <p className="interview" style={{ margin: "0px", padding: "0px" }}>{round ? `${round} Interview` : "N/A"}</p>
      </div>
      <div className="divider"></div>
      <div className="details-section">
        <p className="name">{cand || "N/A"}</p>
        <div className="info">
          <p className="detail"><span>👤</span> {interviewer || "N/A"}</p>
          <p className="detail"><span>⏳</span> {duration} mins</p>
          {/* <p className="detail"><span>📍</span> Room 01</p> */}
        </div>
      </div>
    </div>
  );
};

export default RctrSumInterviewCard;
