import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Input, Select, Button, message, DatePicker } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons"; // Icons for buttons
import { useAuth } from "../../../common/useAuth";
import "./ReopenJob.css";
import Main from "../Layout";
import dayjs from "dayjs";

const { Option } = Select;

const ReopenJob = () => {
    const { id } = useParams();
    const { token, apiurl } = useAuth();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [interviewers, setInterviewers] = useState([]);
    const [companyInterviewers, setCompanyInterviewers] = useState([]);
    const interviewModes = [
        { value: "face_to_face", label: "Face-to-Face" },
        { value: "online", label: "Online" },
        { value: "telephone", label: "Telephone" },
    ];

    const interviewTypes = [
        { value: 'non-technical', label: 'Non Tech' },
        { value: 'technical', label: 'Tech' },
        { value: 'assignment', label: 'Assignment' }
    ]

    useEffect(() => {
        const fetchJobDetails = async () => {
            try {
                const response = await fetch(`${apiurl}/client/reopen-job/?id=${id}`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                if (data.error) {
                    message.error(data.error);
                } else {
                    form.setFieldsValue(data);
                    setInterviewers(data.interviewer_details || []);
                    setCompanyInterviewers(data.company_interviewers || [])
                }
            } catch (e) {
                message.error("Failed to fetch job details.");
            }
        };


        if (id && token) {
            fetchJobDetails();
        }
    }, [id, token, form]);

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            const formattedDate = dayjs(values.job_closing_duration).format("YYYY-MM-DD");
            const updatedData = {
                ...values,
                interviewer_details: interviewers,
                job_close_duration: formattedDate
            };
            console.log(updatedData);

            const response = await fetch(`${apiurl}/client/reopen-job/?job_id=${id}`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updatedData),
            });

            const data = await response.json();
            if (data.error) {
                message.error(data.error);
            } else {
                message.success(data.message);
                // navigate("/client/closed-jobs");
            }
        } catch (e) {
            message.error("Failed to update job post.");
            navigate(-1);
        } finally {
            setLoading(false);
        }
    };

    const handleInterviewerChange = (index, field, value) => {
        const updatedInterviewers = [...interviewers];

        if (field === "id") {
            console.log("entered here")
            const selectedInterviewer = companyInterviewers.find((item) => item.id === value);
            updatedInterviewers[index].interviewer_name = selectedInterviewer.interviewer_name; // Store name for UI display
            updatedInterviewers[index].interviewer_id = selectedInterviewer.id; // Store ID for backend
        } else {
            updatedInterviewers[index][field] = value;
        }

        setInterviewers(updatedInterviewers);
    };

    const addInterviewer = () => {
        setInterviewers([
            ...interviewers,
            {
                round_num: interviewers.length + 1,
                interviewer_id: null,
                mode_of_interview: "",
                type_of_interview: ""
            }
        ]);
    };

    const removeInterviewer = (index) => {
        const updatedInterviewers = interviewers.filter((_, i) => i !== index);
        setInterviewers(updatedInterviewers);
    };

    return (
        <Main defaultSelectedKey='1' className="container">
            <h2 className="heading">Reopen Job Post</h2>
            <Form form={form} layout="vertical" onFinish={handleSubmit} className="job-form">
                <Form.Item label="Job Title" name="job_title">
                    <Input disabled />
                </Form.Item>

                <Form.Item label="Department" name="job_department">
                    <Input disabled />
                </Form.Item>

                <Form.Item label="Description" name="job_description">
                    <Input.TextArea rows={4} disabled />
                </Form.Item>

                {/* 
                <Form.Item label="Primary Skills" name="primary_skills">
                    <Input />
                </Form.Item>

                <Form.Item label="Secondary Skills" name="secondary_skills">
                    <Input />
                </Form.Item> */}

                <Form.Item label="CTC Range" name="ctc">
                    <Input />
                </Form.Item>

                <Form.Item label="Number of Positions" name="num_of_positions">
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Job Close Duration"
                    name="job_close_duration"
                    rules={[{ required: true, message: "Please select the date when the job should close" }]}
                >
                    <DatePicker
                        disabledDate={(current) => current && current.isBefore(dayjs(), "day")}
                    />
                </Form.Item>

                <Form.Item label="Rounds of Interview" name="rounds_of_interview">
                    <Input type="number" />
                </Form.Item>

                <h3 className="interviewer-title">Interviewer Details</h3>
                <div className="interviewer-container">
                    {interviewers.map((interviewer, index) => (
                        <div key={index} className="interviewer-card">
                            <h3>Round Num: {interviewer.round_num}</h3>
                            <Form.Item label="Interviewer Name" name={['interviewer_details', index, 'id']}>
                                <Select
                                    value={interviewer.id}
                                    onChange={(value) => handleInterviewerChange(index, "id", value)}
                                >
                                    {companyInterviewers.map((item) => (
                                        <Option key={item.id} value={item.id}>
                                            {item.interviewer_name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item label="Mode of Interview" name={['interviewer_details', index, 'mode_of_interview']}>
                                <Select
                                    value={interviewer.mode_of_interview}
                                    onChange={(value) => handleInterviewerChange(index, "mode_of_interview", value)}
                                >
                                    {interviewModes.map((mode) => (
                                        <Option key={mode.value} value={mode.value}>
                                            {mode.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item label="Type of Interview" name={['interviewer_details', index, 'type_of_interview']}>
                                <Select
                                    value={interviewer.type_of_interview}
                                    onChange={(value) => handleInterviewerChange(index, "type_of_interview", value)}
                                >
                                    {interviewTypes.map((mode) => (
                                        <Option key={mode.value} value={mode.value}>
                                            {mode.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Button
                                type="primary"
                                danger
                                onClick={() => removeInterviewer(index)}
                                icon={<DeleteOutlined />}
                                className="remove-btn"
                            >
                                Remove
                            </Button>
                        </div>
                    ))}

                </div>
                <Button type="dashed" onClick={addInterviewer} icon={<PlusOutlined />} className="add-interviewer-btn" style={{ backgroundColor: 'green' }}>
                    Add Interviewer
                </Button>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Main>
    );
};

export default ReopenJob;
