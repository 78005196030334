import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Typography, message } from "antd";
import "./login.css";
import { useAuth } from "../common/useAuth";

const { Title, Paragraph } = Typography;

const apiurl = process.env.REACT_APP_BACKEND_URL;

export const LoginForm = ({ onToggle }) => {
	const [user, setUser] = useState({
		email: "",
		password: "",
	});

	const { handleLogin } = useAuth();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [notVerified, setNotVerified] = useState(false);
	const [showVerifyForm, setShowVerifyForm] = useState(false);
	const [verifyEmail, setVerifyEmail] = useState("");
	const [resendTimer, setResendTimer] = useState(0);

	// Handle input change for login form
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setUser((prevUser) => ({
			...prevUser,
			[name]: value,
		}));
	};

	// Handle input change for verify email form
	const handleVerifyInputChange = (e) => {
		setVerifyEmail(e.target.value);
	};

	const handleSubmit = async () => {
		try {
			setLoading(true);

			const response = await fetch(`${apiurl}/login/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(user),
			});

			const data = await response.json();

			if (data.not_verified) {
				setNotVerified(true);
				setLoading(false);
				return;
			}

			if (data.user_details == null) {
				message.error("User data not found");
				return;
			}

			if (response.ok) {
				handleLogin(data.access_token, data.user_details);
				message.success("Login successful");
				navigate("/");
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error("Network error. Please try again.");
		} finally {
			setLoading(false);
		}
	};

	const handleVerifyNow = () => {
		setShowVerifyForm(true);
	};


	useEffect(() => {
		if (resendTimer > 0) {
			const timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
			return () => clearTimeout(timer);
		}
	}, [resendTimer]);

	const handleVerifySubmit = async () => {
		try {
			setLoading(true);
			const response = await fetch(`${apiurl}/send-verification-email/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ email: verifyEmail }),
			});

			const data = await response.json();
			if (response.ok) {
				message.success(data.message);
				setResendTimer(60);
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error("Network error. Please try again.");
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="login-container left_form">
			{/* If user is not verified, show verify button */}


			{/* Email verification form */}
			{showVerifyForm && (
				<Form name="verify_form" onFinish={handleVerifySubmit}>
					<Title level={3} style={{ textAlign: "center" }}>
						Verify Your Email
					</Title>
					<Form.Item
						name="email"
						rules={[
							{ required: true, message: "Please input your Email!" },
							{ type: "email", message: "Please enter a valid Email!" },
						]}
					>
						<Input
							name="email"
							placeholder="Enter your email"
							value={verifyEmail}
							onChange={handleVerifyInputChange}
							className="login-input"
						/>
					</Form.Item>
					<Form.Item>
						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								loading={loading}
								block
								className="login-button"
								disabled={resendTimer > 0}
							>
								{resendTimer > 0 ? `Resend in ${resendTimer}s` : "Send Verification Email"}
							</Button>
						</Form.Item>
					</Form.Item>
				</Form>
			)}

			{/* Login Form */}
			{!showVerifyForm && (
				<Form name="login_form" onFinish={handleSubmit}>
					<Title level={2} style={{ textAlign: "center" }}>Login</Title>

					<Form.Item
						name="email"
						rules={[
							{ required: true, message: "Please input your Email!" },
							{ type: "email", message: "Please enter a valid Email!" },
						]}
					>
						<Input
							name="email"
							placeholder="Email"
							value={user.email}
							onChange={handleInputChange}
							className="login-input"
						/>
					</Form.Item>

					<Form.Item
						name="password"
						rules={[{ required: true, message: "Please input your Password!" }]}
					>
						<Input.Password
							name="password"
							placeholder="Password"
							value={user.password}
							onChange={handleInputChange}
							className="login-input"
						/>
					</Form.Item>

					<div className="flex">
						<Button
							type="link"
							onClick={() => navigate("/forgot_password")}
							disabled={loading}
							style={{
								marginTop: 10,
								marginLeft: 10,
								marginBottom: 10,
							}}
						>
							Forgot password?

						</Button>
						{notVerified && !showVerifyForm && (
							<div className="not-verified-container" >
								<Button type="primary" onClick={handleVerifyNow}>
									Verify Now
								</Button>
							</div>
						)}
					</div>

					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							loading={loading}
							block
							className="login-button"
						>
							Login
						</Button>
					</Form.Item>

					<div style={{ display: "flex", justifyContent: "center" }}>
						<Paragraph>
							Don't have an account?
							<Button
								type="link"
								onClick={onToggle}
								disabled={loading}
								className="signup-text"
							>
								Sign Up
							</Button>
						</Paragraph>
					</div>
				</Form>
			)}
		</div>
	);
};

export default LoginForm;
