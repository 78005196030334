import React from "react";
import dashboard from "./../../../images/Dashboard.svg"
import interviews from "./../../../images/Interviews.svg"
import MainLayout from '../../common/Layout/MainLayout'


const Main = ({ defaultSelectedKey, children }) => {

    const interviewerOptions = [
        {
            "key": "1",
            "label": "Dashboard",
            "logo": dashboard,
            "path": '/'
        },
        {
            "key": "2",
            "label": "Interviews",
            "logo": interviews,
            "path": '/interviewer/interviews'
        },
    ];

    return (
        <>
            <MainLayout children={children} defaultSelectedKey={defaultSelectedKey} options={interviewerOptions}></MainLayout>
        </>
    );
};

export default Main;

