import React, { useState, useEffect } from "react";
import { useAuth } from "../../../common/useAuth";
import { message } from "antd";
import { ClockCircleOutlined, CalendarOutlined } from "@ant-design/icons";
import Table from "../../../common/Table"

const InterviewCard = ({ item }) => {
  return (
    <div className="interview-card-client">
      <div className="line1">{item.interviewer_name}</div>
      <div className="line2">
        <span className="time">
          <ClockCircleOutlined /> {item.scheduled_time}
        </span>
        <span className="date">
          <CalendarOutlined /> {item.scheduled_date}
        </span>
      </div>
      <div className="line3">
        <span className="candidate-name">
          {item.candidate_name} (Round - {item.round_num})
        </span>
      </div>
      <div className="line4">
        <span className="mode">{item.mode_of_interview}</span>
        <span className="type">{item.type_of_interview}</span>
      </div>
    </div>
  );
};

const ScheduledInterviewsClient = () => {
  const { apiurl, token } = useAuth();
  const [jobList, setJobList] = useState([]);
  const [selectedJobId, setSelectedJobId] = useState("");
  const [interviewRounds, setInterviewRounds] = useState([])
  const [interviews, setInterviews] = useState([]);
  const columns = [
    {
      title: "Interviewer Name",
      dataIndex: "interviewer_name",
      key: "interviewer_name",
    },
    {
      title: "Interviewer Email",
      dataIndex: "interviewer_email",
      key: "interviewer_email",
    },
    {
      title: "Round",
      dataIndex: "round_num",
      key: "round_num",
    },
    {
      title: "Mode",
      dataIndex: "mode_of_interview",
      key: "mode_of_interview",
    },
    {
      title: "Type",
      dataIndex: "type_of_interview",
      key: "type_of_interview",
    },
  ];

  // Fetch job posts
  const fetchJobPosts = async () => {
    try {
      const response = await fetch(`${apiurl}/client/job-postings/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error("Failed to fetch job posts");
      const data = await response.json();
      setJobList(data.results || []);
    } catch (error) {
      message.error("Failed to fetch job posts. Please try again.");
    }
  };

  useEffect(() => {
    if (jobList.length > 0) {
      setSelectedJobId(jobList[0].id);
      fetchScheduledInterviews(jobList[0].id)
    }
  }, [jobList])

  // Fetch interviews for selected job
  const fetchScheduledInterviews = async (id) => {
    try {
      const response = await fetch(
        `${apiurl}/client/job-post/interviews/?job_id=${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      if (data.error) {
        message.error(data.error);
        setInterviews([]);
        return;
      } else if (data.message) {
        message.success(data.message);
        setInterviews([]);
        return;
      }
      setInterviews(data.scheduled_interviews || []);
      setInterviewRounds(data.interviewers || []);
    } catch (error) {
      message.error("Failed to fetch interviews. Please try again.");
      setInterviews([]);
    }
  };

  // Handle job selection
  const handleJobChange = (e) => {
    const selectedId = e.target.value;
    setSelectedJobId(selectedId);
    if (selectedId) {
      fetchScheduledInterviews(selectedId);
    } else {
      setInterviews([]);
    }
  };

  // Initial fetch of job posts
  useEffect(() => {
    if (token) fetchJobPosts();
  }, [token]);

  return (
    <div className="scheduled-interviews-client">
      <div className="select" style={{ marginBottom: "20px" }}>
        <label className="label" htmlFor="jobSelect" style={{ marginRight: "10px" }}>
          Select Job Title:
        </label>
        <select
          id="jobSelect"
          value={selectedJobId}
          onChange={handleJobChange}
          style={{
            padding: "5px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            minWidth: "200px",
            fontSize: '12px',
            borderColor: '#1681FF',
            color: '#1681FF'
          }}
        >
          <option value="">-- Select Job --</option>
          {jobList.map((job) => (
            <option key={job.id} value={job.id}>
              {job.job_title}
            </option>
          ))}
        </select>
      </div>
      {/* <div className="scheduled-heading">Interview Rounds</div> */}
      {interviewRounds.length > 0 ? (
        <div className="interview-rounds">
          <Table columns={columns} data={interviewRounds} pagination={false} ></Table>
        </div>
      ) : (
        selectedJobId && (

          <p style={{ textAlign: "center", marginTop: "20px", color: "#888" }}>
            No interview rounds for this job.
          </p>

        )
      )}

      <div className="scheduled-heading">Scheduled  Interviews</div>

      {interviews.length > 0 ? (
        <div className="interview-card-container" style={{ display: "grid", gap: "16px", gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))" }}>
          {interviews.map((item, idx) => (
            <InterviewCard key={idx} item={item} />
          ))}
        </div>
      ) : (
        selectedJobId && (
          <p style={{ textAlign: "center", marginTop: "20px", color: "#888" }}>
            No interviews scheduled for this job.
          </p>
        )
      )}
    </div>
  );
};

export default ScheduledInterviewsClient;
