import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../common/useAuth';
import { message, Card, Button, Modal, Form, Input, Upload } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
// import './Blogs.css';

const ApproveBlogs = () => {
    const { apiurl, token } = useAuth();
    const [data, setData] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [blogData, setBlogData] = useState()

    const fetchData = async () => {
        try {
            const response = await fetch(`${apiurl}/superadmin/approve-blogs/`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const result = await response.json();
            if (result.error) {
                message.error(result.error);
            } else {
                setData(result);
            }
        } catch (e) {
            message.error('Failed to fetch blogs');
        }
    };

    const fetchParticularBlog = async (id) => {
        try {
            const response = await fetch(`${apiurl}/superadmin/approve-blogs/?blog_id=${id}`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const result = await response.json();
            if (result.error) {
                message.error(result.error);
            } else {
                setModalVisible(true);
                setBlogData(result);
            }
        }
        catch (e) {
            message.error('Failed to fetch blogs');
        }
    }

    useEffect(() => {
        if (token) {
            fetchData();
        }
    }, [token]);


    const handleApproveBlog = async (id) => {
        try {

            const response = await fetch(`${apiurl}/superadmin/approve-blogs/?blog_id=${id}`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${token}`
                },
            });

            const result = await response.json();
            if (result.error) {
                message.error(result.error);
            } else {
                message.success('Blog approved successfully');
                fetchData();
                setModalVisible(false);
                form.resetFields();
            }
        } catch (e) {
            message.error('Failed to add blog');
        }
    };

    return (
        <div className="blogs-container">
            <div className="blogs-header">

                {/* <Button type="primary" icon={<PlusOutlined />} onClick={() => setModalVisible(true)}>
                    Add New Blog
                </Button> */}
            </div>

            <div className="blogs-list">
                {data.length > 0 ? (
                    data.map((item) => (
                        <Card
                            key={item.id}
                            hoverable
                            className="blog-card"
                            cover={<img alt="thumbnail" src={`${apiurl}${item.thumbnail}`} />}
                            onClick={() => {
                                fetchParticularBlog(item.id)
                            }}
                        >
                            <Card.Meta title={item.title} description={item.description} />
                        </Card>
                    ))
                ) : (
                    <p>No blogs available</p>
                )}
            </div>

            <Modal
                title="Blog Details"
                open={modalVisible}
                onCancel={() => {
                    setModalVisible(false)

                    setBlogData(null)
                }}
                footer={false}
            >
                {blogData ? (
                    <div className="blog-details">
                        <h3>{blogData.title}</h3>
                        <p>{blogData.content}</p>
                        {blogData.thumbnail && (
                            <img
                                src={`${apiurl}${blogData.thumbnail}`}
                                alt="Blog Thumbnail"
                                className="blog-thumbnail"
                                style={{ height: '200px', width: "450px" }}
                            />
                        )}
                        <Button
                            type="primary"
                            onClick={() => handleApproveBlog(blogData.id)}
                        >
                            Approve
                        </Button>
                    </div>
                ) : (
                    <p>No blog selected</p>
                )}
            </Modal>

        </div>
    )
}

export default ApproveBlogs