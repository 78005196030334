import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Input, DatePicker, message } from 'antd';
import { useAuth } from '../../../../common/useAuth';
import dayjs from 'dayjs';
import Table from '../../../../common/Table';
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import "../SelectedCandidates.css"

const Onhold = () => {
    const { token, apiurl } = useAuth();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedCandidate, setSelectedCandidate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const response = await fetch(`${apiurl}/client/on-hold/`, {
                method: "GET",
                headers: { "Authorization": `Bearer ${token}` }
            });
            const result = await response.json();
            if (result.error) {
                message.error(result.error);
                return;
            }
            setData(result);
            setFilteredData(result);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (token) {
            fetchData();
        }
    }, [token]);

    // Search Handler
    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);
        const filtered = data.filter((item) =>
            item.candidate_name.toLowerCase().includes(value) ||
            item.organization_name.toLowerCase().includes(value) ||
            item.job_title.toLowerCase().includes(value) ||
            (item.job_department && item.job_department.toLowerCase().includes(value))
        );
        setFilteredData(filtered);
    };

    // Select candidate
    const handleSelect = (candidate) => {
        setSelectedCandidate(candidate);
        setIsModalOpen(true);
    };

    const handleSubmitSelection = async (values) => {
        try {
            const formattedDate = values.joining_date.format("YYYY-MM-DD");
            const payload = {
                ...values,
                joining_date: formattedDate
            };

            const response = await fetch(`${apiurl}/client/select-candidate/?id=${selectedCandidate.application_id}`, {
                method: "POST",
                headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" },
                body: JSON.stringify(payload)
            });
            const result = await response.json();
            if (result.message) {
                message.success(result.message);
                fetchData();
            } else {
                message.error(result.error);
            }
        } catch (e) {
            console.log(e);
        }
        setIsModalOpen(false);
    };

    // Table columns
    const columns = [
        {
            title: 'Candidate Name',
            dataIndex: 'candidate_name',
            key: 'candidate_name',
            render: (name, application) => (
                <div className="name" style={{ cursor: "pointer" }} onClick={() => { navigate(`/client/candidates/${application.application_id}`) }}>{name}</div>
            )
        },
        {
            title: 'Agency',
            dataIndex: 'organization_name',
            key: 'organization_name',
        },
        {
            title: 'Job Title',
            dataIndex: 'job_title',
            key: 'job_title',
        },
        {
            title: 'Job Department',
            dataIndex: 'job_department',
            key: 'job_department',
            render: (text) => text || "N/A"
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Button type="primary" onClick={() => handleSelect(record)}>Select</Button>
            )
        }
    ];

    return (
        <div >
            {/* <h1>On Hold Candidates</h1> */}

            <div className="custom-search">
                <SearchOutlined></SearchOutlined>
                <input type="text" placeholder="Search candidates, agency, job title..."
                    value={searchTerm}
                    onChange={handleSearch}
                />
            </div>

            <div className="custom-table-container onhold-table-wrapper">
                <Table
                    className="custom-table onhold-table"
                    columns={columns}
                    data={filteredData}
                    rowKey={(record) => record.id}
                    pagination={false}
                />
            </div>


            {/* Modal for selection */}
            <Modal
                title={`Select Candidate: ${selectedCandidate?.candidate_name}`}
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
            >
                <Form form={form} layout="vertical" onFinish={handleSubmitSelection}>
                    <Form.Item label="Agreed CTC" name="ctc" rules={[{ required: true, message: 'Please enter agreed CTC' }]}>
                        <Input type="number" step="0.01" />
                    </Form.Item>
                    <Form.Item
                        label="Agreed Joining Date"
                        name="joining_date"
                        rules={[{ required: true, message: 'Please select joining date' }]}
                    >
                        <DatePicker
                            style={{ width: "100%" }}
                            disabledDate={(current) => current && current < dayjs().startOf('day')}
                        />
                    </Form.Item>
                    <Form.Item label="Remarks" name="remarks">
                        <Input.TextArea placeholder="Any additional remarks" />
                    </Form.Item>
                    <Button type="primary" htmlType="submit" block>
                        Submit
                    </Button>
                </Form>
            </Modal>
        </div>
    );
};

export default Onhold;
