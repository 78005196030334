import React, { useState, useEffect } from "react";
import { useAuth } from "../../../common/useAuth";
import { Button, message, Modal, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import Table from "../../../common/Table";
import "./ScheduledInterviews.css";

const apiurl = process.env.REACT_APP_BACKEND_URL;

const MissedInterviews = () => {
    const { token } = useAuth();
    const [data, setData] = useState([]);
    const [resumeData, setResumeData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const navigate = useNavigate();

    const fetchData = async (page = 1) => {
        try {
            const response = await fetch(
                `${apiurl}/interviewer/missed-interviews/?page=${page}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const data = await response.json();
            if (data.error) {
                message.error(data.error);
                return;
            }
            setData(data.results);
            setTotal(data.count);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (token) {
            fetchData(currentPage);
        }
    }, [token, currentPage]);

    const columns = [
        {
            title: "Job Title",
            dataIndex: "job_title",
            key: "job_title",
            render: (title) => (
                <div style={{ color: "#2C5F99", fontWeight: "600" }}>
                    {title}
                </div>
            )
        },
        {
            title: "Rounds of Interview",
            dataIndex: "round_of_interview",
            key: "round_of_interview",
        },
        {
            title: "Candidate Name",
            dataIndex: "candidate_name",
            key: "candidate_name",
            render: (text) => (
                <div className="candidate-name" style={{ cursor: 'pointer' }}>
                    {text}
                </div>
            )
        },
        {
            title: "Scheduled Time",
            dataIndex: "scheduled_date",
            key: "scheduled_date",
            render: (text) => new Date(text).toLocaleDateString(),
            sorter: (a, b) => new Date(a.schedule_date) - new Date(b.schedule_date),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: "Timings",
            dataIndex: "timings",
            key: "timings",
            render: (text) => {
                if (!text) return '-';
                return text
                    .split(' - ') // split start and end time
                    .map(time => time.split(':').slice(0, 2).join(':')) // remove seconds
                    .join(' - '); // join back as range
            }
        },
        {
            title: "Update Results",
            dataIndex: "interview_id",
            key: "interview_id",
            render: (interview_id, record) => {
                return (
                    <Button
                        onClick={() =>
                            navigate(`/interviewer/conduct-interview/${interview_id}`)
                        }
                    >
                        Conduct Interview
                    </Button>
                );
            },
        },
    ];

    const handleViewProfile = async (id) => {
        setModalVisible(true); // Show the modal
        setLoading(true); // Start loading spinner

        try {
            const response = await fetch(`${apiurl}/get-resume/${id}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const data = await response.json();
            if (data.error) {
                message.error(data.error);
                return;
            } else if (data.message) {
                message.success(data.message);
                return;
            }
            setResumeData(data);
            console.log(data, "is the resume data");
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false); // Stop loading spinner
        }
    };

    return (
        <div defaultSelectedKey="2">
            {data && (
                <div className="custom-table-scheduled-interviews">
                    <Table
                        columns={columns}
                        data={data}
                        pagination={{
                            current: currentPage,
                            pageSize: pageSize,
                            total: total, // Total interviews count for pagination
                            onChange: (page, pageSize) => {
                                setCurrentPage(page);
                                setPageSize(pageSize);
                            },
                            showSizeChanger: true,
                            pageSizeOptions: ['5', '10', '20', '50'],
                        }}
                    />
                </div>
            )}

            {/* Modal for showing resume data */}
            <Modal
                title="Candidate Profile"
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
                width={600}
            >
                {loading ? (
                    <div style={{ textAlign: "center" }}>
                        <Spin size="large" />
                        <p>Loading...</p>
                    </div>
                ) : (
                    resumeData && (
                        <>
                            <div style={{ marginBottom: "10px" }}>
                                <p><strong>Name:</strong> {resumeData.candidate_name}</p>
                            </div>
                            <div style={{ marginBottom: "10px" }}>
                                <p><strong>Experience:</strong> {resumeData.experience}</p>
                            </div>
                            <div style={{ marginBottom: "10px" }}>
                                <p><strong>Current CTC:</strong> {resumeData.current_ctc}</p>
                            </div>
                            <div style={{ marginBottom: "10px" }}>
                                <p><strong>Current Job Type:</strong> {resumeData.current_job_type}</p>
                            </div>
                            <div style={{ marginBottom: "10px" }}>
                                <p><strong>Expected CTC:</strong> {resumeData.expected_ctc}</p>
                            </div>
                            <div style={{ marginBottom: "10px" }}>
                                <p><strong>Notice Period:</strong> {resumeData.notice_period}</p>
                            </div>
                            <div style={{ marginBottom: "10px" }}>
                                <p>
                                    <strong>Resume: </strong>
                                    <a
                                        href={`${apiurl}${resumeData.resume}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: "#1890ff", textDecoration: "none" }}
                                    >
                                        View Resume
                                    </a>
                                </p>
                            </div>
                        </>
                    )
                )}
            </Modal>
        </div>
    );
};

export default MissedInterviews;
