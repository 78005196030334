import React, { useEffect, useState } from 'react';
import { useAuth } from '../common/useAuth';
import { message, Card, Button, Modal, Form, Input, Upload, Badge } from 'antd';
import { PlusOutlined, UploadOutlined, ContainerOutlined } from '@ant-design/icons';
import './Blogs.css';


const MyBlogs = () => {
    const { apiurl, token } = useAuth();
    const [data, setData] = useState([]);
    const [viewBlog, setViewBlog] = useState(false);
    const [blogData, setBlogData] = useState(null)

    const fetchData = async () => {
        try {
            const response = await fetch(`${apiurl}/myblogs/`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const result = await response.json();
            if (result.error) {
                message.error(result.error);
            } else {
                setData(result);
            }
        } catch (e) {
            message.error('Failed to fetch blogs');
        }
    };

    useEffect(() => {
        if (token) {
            fetchData();
        }
    }, [token]);


    const fetchParticularBlog = async (id) => {
        try {
            const response = await fetch(`${apiurl}/myblogs/?blog_id=${id}`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const result = await response.json();
            if (result.error) {
                message.error(result.error);
            } else {
                setViewBlog(true);
                setBlogData(result);
            }
        }
        catch (e) {
            message.error('Failed to fetch blogs');
        }
    }

    return (
        <div>
            <div className="blogs-list">
                {data.length > 0 ? (
                    data.map((item) => (
                        <Card
                            key={item.id}
                            hoverable
                            className="blog-card"
                            cover={<img alt="thumbnail" src={`${apiurl}${item.thumbnail}`} />}
                            onClick={() => {
                                fetchParticularBlog(item.id);
                            }}
                        >
                            <Badge.Ribbon text={item.is_approved} color={item.is_approved === true ? 'green' : 'red'}>
                                <Card.Meta title={item.title} description={item.content} />
                            </Badge.Ribbon>
                            {/* <Card.Meta title={item.title} description={item.description} /> */}
                        </Card>
                    ))
                ) : (
                    <p>No blogs available</p>
                )}
            </div>

            <Modal
                title="Blog Details"
                open={viewBlog}
                onCancel={() => {
                    setViewBlog(false)
                    setBlogData(null)
                }}
                footer={false}
            >
                {blogData ? (
                    <div className="blog-details">
                        {/* <h3>{blogData.status}</h3> */}
                        {blogData.thumbnail && (
                            <img
                                src={`${apiurl}${blogData.thumbnail}`}
                                alt="Blog Thumbnail"
                                className="blog-thumbnail"
                                style={{ height: '200px', width: "450px" }}
                            />
                        )}
                        <h3>{blogData.title}</h3>
                        <p>{blogData.content}</p>
                    </div>
                ) : (
                    <p>No blog selected</p>
                )}
            </Modal>
        </div>
    )
}


const AllBlogs = () => {
    const { apiurl, token } = useAuth();
    const [data, setData] = useState([]);
    const [viewBlog, setViewBlog] = useState(false);
    const [blogData, setBlogData] = useState(null)

    const fetchData = async () => {
        try {
            const response = await fetch(`${apiurl}/user/blogs/`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const result = await response.json();
            if (result.error) {
                message.error(result.error);
            } else {
                setData(result);
            }
        } catch (e) {
            message.error('Failed to fetch blogs');
        }
    };

    useEffect(() => {
        if (token) {
            fetchData();
        }
    }, [token]);


    const fetchParticularBlog = async (id) => {
        try {
            const response = await fetch(`${apiurl}/user/blogs/?blog_id=${id}`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const result = await response.json();
            if (result.error) {
                message.error(result.error);
            } else {
                setViewBlog(true);
                setBlogData(result);
            }
        }
        catch (e) {
            message.error('Failed to fetch blogs');
        }
    }

    return (
        <div>
            <div className="blogs-list">
                {data.length > 0 ? (
                    data.map((item) => (
                        <Card
                            key={item.id}
                            hoverable
                            className="blog-card"
                            cover={<img alt="thumbnail" src={`${apiurl}${item.thumbnail}`} />}
                            onClick={() => {
                                fetchParticularBlog(item.id);
                            }}
                        >
                            <Card.Meta title={item.title} />
                        </Card>
                    ))
                ) : (
                    <p>No blogs available</p>
                )}
            </div>

            <Modal
                title="Blog Details"
                open={viewBlog}
                onCancel={() => {
                    setViewBlog(false)
                    setBlogData(null)
                }}
                footer={false}
            >
                {blogData ? (
                    <div className="blog-details">
                        {/* <h3>{blogData.status}</h3> */}
                        {blogData.thumbnail && (
                            <img
                                src={`${apiurl}${blogData.thumbnail}`}
                                alt="Blog Thumbnail"
                                className="blog-thumbnail"
                                style={{ height: '200px', width: "450px" }}
                            />
                        )}
                        <h3>{blogData.title}</h3>
                        <p>{blogData.content}</p>
                    </div>
                ) : (
                    <p>No blog selected</p>
                )}
            </Modal>
        </div>
    )
}


const Blogs = () => {
    const { apiurl, token } = useAuth();
    const [modalVisible, setModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [myBlogs, setMyBlogs] = useState();

    const handleAddBlog = async (values) => {
        try {
            const formData = new FormData();
            formData.append('title', values.title);
            formData.append('content', values.content);
            formData.append('thumbnail', values.thumbnail[0].originFileObj);

            console.log('this function called')
            const response = await fetch(`${apiurl}/user/blogs/`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                body: formData
            });
            console.log("called backedn")

            const result = await response.json();
            if (result.error) {
                message.error(result.error);
            } else {
                message.success('Blog added successfully');
                // fetchData();
                setModalVisible(false);
                form.resetFields();
            }
        } catch (e) {
            message.error('Failed to add blog');
        }
    };

    return (
        <div className="blogs-container">
            <div className="blogs-head  er">
                <div className="blog-top">
                    <h2>Blogs</h2>
                    <div className="buttons">
                        {myBlogs ? (
                            <Button icon={<ContainerOutlined />} onClick={() => {
                                setMyBlogs(false)
                            }} >
                                All Blogs
                            </Button>
                        ) : (
                            <Button icon={<ContainerOutlined />}
                                onClick={() => {
                                    setMyBlogs(true);
                                }}
                            >
                                My Blogs
                            </Button>
                        )}
                        <Button type="primary" icon={<PlusOutlined />} onClick={() => setModalVisible(true)}>
                            Add New Blog
                        </Button>
                    </div>

                </div>
                {myBlogs ? (
                    <MyBlogs />
                ) : (
                    <AllBlogs />
                )}
            </div>

            <Modal
                title="Add New Blog"
                open={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={false}
            >
                <Form form={form} layout="vertical" onFinish={handleAddBlog}>
                    <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Please enter the title' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="content" label="Content" rules={[{ required: true, message: 'Please enter the Co' }]}>
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item name="thumbnail" label="Thumbnail" valuePropName="fileList" getValueFromEvent={(e) => e.fileList} rules={[{ required: true, message: 'Please upload an image' }]}>
                        <Upload beforeUpload={() => false} listType="picture">
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Add Blog
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

        </div>
    );
};

export default Blogs;
