import React, { useEffect, useState } from 'react'
import ViewJobPost from '../../../common/ViewJobPost'
import Main from '../Layout'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../../common/useAuth'

const CompleteJobPost = () => {
    const { id } = useParams();
    const { token, apiurl } = useAuth();
    const [job, setJob] = useState();
    const [interviewers, setInterviewers] = useState()
    const fetchJobDetails = async () => {
        if (token) {
            try {
                const response = await fetch(`${apiurl}/job-details?job_id=${id}`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setJob(data);
                setInterviewers(data.interview_details)
            } catch (error) {
                console.error("Error fetching job details:", error);
            }
        }
    };
    useEffect(() => {
        fetchJobDetails()
    }, [token])
    return (
        <Main defaultSelectedKey="2">
            {job &&
                <>
                    <h2>Organization {job.organization && job.organization.name}</h2>
                    <ViewJobPost id={id} job={job} interviewers={interviewers} />
                </>
            }
        </Main>
    )
}

export default CompleteJobPost