import React, { useState, useEffect } from 'react';
import Main from '../Layout';
import { useAuth } from '../../../common/useAuth';
import { message } from 'antd';
import './SelectedCandidates.css'; // Import CSS file

const SelectedCandidates = () => {
    const [data, setData] = useState([]);
    const { token, apiurl } = useAuth();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch(`${apiurl}/manager/selected-candidates/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const result = await response.json();
            if (result.error) {
                message.error(result.error);
            } else {
                setData(result);
            }
        } catch (e) {
            console.log(e);
            message.error("Failed to fetch data");
        }
    };

    return (
        <Main defaultSelectedKey="7">
            <h1 >List of Selected Candidates</h1>
            <div className="selected-candidate-list">
                {data.length > 0 ? (
                    data.map((candidate, index) => (
                        <div key={index} className="selected-candidate">
                            <h2>{candidate.candidate_name}</h2>
                            <p><strong>Date of Joining:</strong> {candidate.date_of_joining}</p>
                            <p><strong>Joining Status:</strong> {candidate.joining_status}</p>
                            <p><strong>Client Name:</strong> {candidate.client_name}</p>
                            <p><strong>Job Title:</strong> {candidate.job_title}</p>
                        </div>
                    ))
                ) : (
                    <p>No candidates selected yet.</p>
                )}
            </div>
        </Main>
    );
};

export default SelectedCandidates;
