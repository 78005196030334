import React, { useEffect, useState } from "react";
import { Select, Tabs } from "antd";
import "./Recruiters.css";
import Main from "../Layout";
import AllRecruiters from "./AllRecruiters";
import RecTaskTracking from './RecTaskTracking'
import RecruiterSummery from "./RecruiterSummery";
// import 
import { useAuth } from "../../../common/useAuth";

const { Option } = Select;
const { TabPane } = Tabs;

const Recruiters = () => {

	const [activeTab, setActiveTab] = useState("1")

	const tabs = [
		{ key: "1", label: "Task Tracking", content: <RecTaskTracking /> },
		{ key: "2", label: "Performance", content: <div>Performance</div> },
		{ key: "3", label: "Issue or Queries", content: <div>Performance</div> },
		{ key: "4", label: "Recruiters", content: <AllRecruiters /> },
		// { key: "5", label: "Recruiter summary", content: <RecruiterSummery/>}
	]

	return (
		<Main defaultSelectedKey='5'>
			<div className="tabs-container">
				<div className="tabs">
					{tabs.map((tab) => (
						<div
							key={tab.key}
							className={`tab-item ${activeTab === tab.key ? "active-tab" : ""}`}
							onClick={() => setActiveTab(tab.key)}
						>
							{tab.label}
						</div>
					))}
				</div>

				<div className="tab-content">
					{tabs.find((tab) => tab.key === activeTab)?.content}
				</div>
			</div>
		</Main>
	);
};

export default Recruiters;
