import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./useAuth";
import InterviewersTable from "./ViewInterviewers";
import { Button } from "antd";
import './ViewJobPost.css'

const ViewJobPost = ({ id, job, interviewers }) => {

    const navigate = useNavigate();
    id = parseInt(id);

    const handleBack = () => {
        navigate(-1);
    };

    return (

        <div className="job-details-container">
            {job && (
                <div>
                    <div className="job-detail">
                        <h2>Job Title :</h2>
                        <h3>{job.job_title}</h3>
                    </div>
                    <div className="job-detail">
                        <h2>Job Department :</h2>
                        <h3>{job.job_department}</h3>
                    </div>
                    <div className="job-detail">
                        <h2>Job Description:</h2>
                        <h3>{job.job_description}</h3>
                    </div>

                    <div className="job-detail">
                        <h2>Languages</h2>
                        <h3>{job.languages}</h3>
                    </div>
                    <div className="job-detail">
                        <h2>Notice Period</h2>
                        <h3>{job.notice_period}</h3>
                    </div>
                    <div className="job-detail">
                        <h2>No Positions</h2>

                        <h3>{job.num_of_positions}</h3>
                    </div>

                    <div className="job-detail">
                        <h2>Primary Skills</h2>
                        {job.primary_skills && job.primary_skills.map((item, index) => (
                            <h3>
                                {item.skill_name} -
                                {item.metric_type}
                                {item.metric_value}
                            </h3>
                        ))}
                    </div>

                    <div className="secondary-skills job-detail">
                        <h2>Secondary Skills</h2>
                        {job.secondary_skills && job.secondary_skills.map((item, index) => (
                            <h3>
                                {item.skill_name} -
                                {item.metric_type}
                                {item.metric_value}
                            </h3>
                        ))}
                    </div>

                    <div className="job-detail">
                        <h2>Years of Experience:</h2>
                        <h3>{job.years_of_experience}</h3>
                    </div>
                    <div className="job-detail">
                        <h2>CTC:</h2>
                        <h3>{job.ctc}</h3>
                    </div>
                    <div className="job-detail">
                        <h2>Rounds of Interview:</h2>
                        <h3>{job.rounds_of_interview}</h3>
                    </div>
                    <div className="job-detail">
                        <h2>Job Location:</h2>
                        <h3>{job.job_locations}</h3>
                    </div>
                    <div className="job-detail">
                        <h2>Job Type:</h2>
                        <h3>{job.job_type}</h3>
                    </div>
                    {job.job_type === 'Full Time' &&
                        <div className="job-detail">
                            <h2>Probation Type:</h2>
                            <h3>{job.probation_type}</h3>
                        </div>
                    }
                    <div className="job-detail">
                        <h2>Job Level:</h2>
                        <h3>{job.job_level}</h3>
                    </div>
                    <div className="job-detail">
                        <h2>Qualifications:</h2>
                        <h3>{job.qualifications}</h3>
                    </div>
                    <div className="job-detail">
                        <h2>Qualification Dept</h2>
                        <h3>{job.qualification_department}</h3>
                    </div>
                    <div className="job-detail">
                        <h2>Timings:</h2>
                        <h3>{job.timings}</h3>
                    </div>
                    <div className="job-detail">
                        <h2>Other Benefits:</h2>
                        <h3>{job.other_benefits}</h3>
                    </div>
                    <div className="job-detail">
                        <h2>Working Days per Week:</h2>
                        <h3>{job.working_days_per_week}</h3>
                    </div>
                    <div className="job-detail">
                        <h2>Decision Maker:</h2>
                        <h3>{job.decision_maker}</h3>
                    </div>
                    <div className="job-detail">
                        <h2>Decision Maker Email:</h2>
                        <h3>{job.decision_maker_email}</h3>
                    </div>
                    <div className="job-detail">
                        <h2>Bond:</h2>
                        <h3>{job.bond}</h3>
                    </div>
                    <div className="job-detail">
                        <h2>Rotational Shift:</h2>
                        <h3>{job.rotational_shift ? "Yes" : "No"}</h3>
                    </div>
                    <div className="job-detail">
                        <h2>Job Closing Date:</h2>
                        <h3>{job.job_close_duration}</h3>
                    </div>
                    <div className="job-detail">
                        <h2>Job Post Status</h2>
                        <h3>{job?.status}</h3>
                    </div>
                    <div className="job-detail">
                        <h2>Visa Status</h2>
                        <h3>{job?.visa_status}</h3>
                    </div>
                    <div className="job-detail">
                        <h2>Passport availability</h2>
                        <h3>{job?.passport_availability}</h3>
                    </div>

                    <hr />
                    <h2>Interviewers data</h2>
                    <div>
                        <InterviewersTable interviewers={interviewers} />
                    </div>
                </div>
            )}
        </div>
    )
}

export default ViewJobPost