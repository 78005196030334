import "./IntervieweReview.css"

const InterviewerReview = () => {
  // Sample data for skills
  const primarySkills = [
    { id: 2, name: "python ", rating: "08" },
    { id: 3, name: "django", rating: "10" },
    { id: 1, name: "java ", rating: "06" },
    { id: 4, name: "javascipt", rating: "09" },
    { id: 5, name: "git", rating: "08" },
  ]
  
  
  const secondarySkills = [
    { id: 1, name: "English", rating: "08" },
    { id: 2, name: "Telugu", rating: "08" },
    { id: 3, name: "hindi", rating: "08" },
    { id: 4, name: "", rating: "08" },
  ]

  // Sample data for previous reviews
  const previousReviews = [
    {
      id: 1,
      round: "Round 1",
      content:
        "he candidate demonstrated excellent communication skills, articulating their thoughts clearly and confidently. They maintained a professional demeanor throughout the interview, showing preparedness and a keen understanding of the discussed topics. Their ability to engage in meaningful conversation and express ideas effectively was commendable..",
    },
    {
      id: 2,
      round: "Round 2",
      content:
        "technical standpoint, the candidate displayed strong expertise and a solid grasp of key concepts relevant to the role. Their problem-solving approach was structured and efficient, showcasing logical thinking and analytical abilities. They navigated challenges well and provided insightful solutions, indicating both experience and adaptability.",
    },
  ]

  return (
    <div className="interviewer-review-container">
      <div className="interviewer-review-card">
        <div className="interviewer-review-header">
          <h2>Interviewer Review</h2>
          {/* <button className="close-button">✕</button> */}
        </div>

        <div className="skills-section">



        <div className="skills-column primary-skills">
            <div className="skills-header">
              <h3>Primary Skills</h3>
              <span className="rate-label">Rate of 1-10</span>
            </div>

            <div className="skills-list">
              {primarySkills.map((skill) => (
                <div key={skill.id} className="skill-item">
                  <span className="skill-name">{skill.name}</span>
                  <span className="skill-rating">{skill.rating}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="skills-divider"></div>

          <div className="skills-column secondary-skills">
            <div className="skills-header">
              <h3>Secondary Skills</h3>
              <span className="rate-label">Rate of 1-10</span>
            </div>

            <div className="skills-list">
              {secondarySkills.map((skill) => (
                <div key={skill.id} className="skill-item">
                  <span className="skill-name">{skill.name}</span>
                  <span className="skill-rating">{skill.rating}</span>
                </div>
              ))}
            </div>
          </div>


          
        </div>

        <div className="reviews-section">
          <div className="reviews-header">
            <h3>Reviews in previous Rounds</h3>
            <span className="summary-label">Summary</span>
          </div>

          <div className="reviews-list">
            {previousReviews.map((review) => (
              <div key={review.id} className="review-item">
                <h4>{review.round}</h4>
                <p>{review.content}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default InterviewerReview

