import "./ProfileCard.css";
import { useAuth } from "../../useAuth";
import { message, Button } from "antd";
import { useEffect, useState } from "react";
import Profile from "../../../../images/Client/profile.png";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const ProfileCard = () => {
  const { apiurl, token } = useAuth();
  const [data, setData] = useState(null);
  const [image, setImage] = useState(null);
  const { handleLogout } = useAuth();
  const navigate = useNavigate();

  // Fetch User Profile Data
  const fetchData = async () => {
    try {
      const response = await fetch(`${apiurl}/get-user-details/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();

      if (result.error) {
        message.error(result.error);
      } else if (result.data) {
        setData(result.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // Handle File Selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Check if the file type is JPG or PNG
      if (!file.type.startsWith("image/")) {
        message.error("Only image files are allowed!");
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        message.error("File size should be less than 5MB!");
        return;
      }

      setImage(file);
      uploadProfileImage(file);
    }
  };

  // Upload Image to Backend
  const uploadProfileImage = async (file) => {
    const formData = new FormData();
    formData.append("profile", file);

    try {
      const response = await fetch(`${apiurl}/add-profile/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const result = await response.json();
      if (result.error) {
        message.error(result.error);
      } else {
        message.success("Profile picture updated successfully!");
        fetchData(); // Refresh profile data
      }
    } catch (e) {
      console.log(e);
      message.error("Failed to upload image. Please try again.");
    }
  };

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, []);

  return (
    <div className="profile-card-container">
      {data && (
        <div className="profile-card">
          <div className="profile-image-container">
            <img
              src={data.profile ? `${apiurl}${data.profile}` : Profile}
              alt={`${data.username}'s profile`}
              className="profile-image"
            />
            {/* Hidden file input */}
            <input
              type="file"
              accept="image/png, image/jpeg"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="file-upload"
            />
            <label htmlFor="file-upload" className="add-button">
              <PlusOutlined size={20} color="white" />
            </label>
          </div>

          <div className="profile-info">
            <h2 className="profile-name">{data.username}</h2>
            <p className="profile-company">{data.company || "No company info"}</p>
            <p className="profile-employment-type">{data.email}</p>
            <Button danger onClick={() => {
              console.log("logout")
              handleLogout();
              navigate('/login')
            }}>
              Logout</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileCard;
