import React, { useState } from 'react'
import BG from "../../images/LandingPage/BG2.png"
import SuitCase from "../../images/LandingPage/suitcase.svg"
import Call from "../../images/LandingPage/call.svg"
import Chatting from "../../images/LandingPage/chatting.svg"
import Deal from "../../images/LandingPage/deal.svg"
import Location from "../../images/LandingPage/location 02.svg"
import TopNav from './TopNav'
import Faqs from "./Faqs.json"
import "./ContactUs.css"
import { DownOutlined, MailOutlined, PhoneOutlined, UserOutlined, BankOutlined, LinkedinFilled, XFilled, FacebookFilled, InstagramFilled } from '@ant-design/icons';
import Footer from './Footer'

const FaqComponent = ({ item }) => {
    const [open, setOpen] = useState(false)
    return (
        <div className="faq">
            <div className="topline" onClick={() => setOpen(!open)}>
                <span className="question">{item.question}</span>
                <span
                    className={`btn transition-transform duration-300 ${open ? "rotate-180" : "rotate-0"}`}
                >
                    <DownOutlined />
                </span>
            </div>
            {open &&
                <div className="answer">
                    {item.answer}
                </div>
            }
        </div>
    )
}

const ContactUs = () => {
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phone: "",
        organizationType: "consultancy",
        organizationName: "",
        description: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Submitted:", formData);
        // Add form submission logic here (e.g., API call)
    };
    return (
        <div className='contact-us'>
            <div className="section1"
                style={{ backgroundImage: `url(${BG})`, backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '0px 0px 0px 0px' }}
            >
                <TopNav color="blue"></TopNav>
                <div className="line1">
                    We'd   <span className='pink'>love</span> to here from you
                </div>
                <div className="line2">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quia, placeat?
                </div>
            </div>
            <div className="section2">
                <div className="contact-form">
                    <span className="text">
                        We are here to help you out
                    </span>
                    <div className="form">
                        <div className="line">
                            <span className="input-field name">
                                <UserOutlined />
                                <input
                                    type="text"
                                    name="fullName"
                                    placeholder="Full Name"
                                    value={formData.fullName}
                                    onChange={handleChange}
                                    required
                                />
                            </span>
                            <span className="input-field email">
                                <MailOutlined />
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </span>
                        </div>

                        <div className="line">
                            <span className="input-field phone">
                                <PhoneOutlined />
                                <input
                                    type="number"
                                    name="phone"
                                    placeholder="Phone Number"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    required
                                />
                            </span>
                            <span className="organization-type input-field">
                                <BankOutlined />
                                <select
                                    name="organizationType"
                                    value={formData.organizationType}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="consultancy">Consultancy</option>
                                    <option value="software">Software</option>
                                    <option value="industry">Industry</option>
                                    <option value="banking">Banking</option>
                                </select>
                            </span>
                        </div>

                        <span className="input-field organization-name">
                            <img src={SuitCase} alt="" />
                            <input
                                type="text"
                                name="organizationName"
                                placeholder="Organization Name"
                                value={formData.organizationName}
                                onChange={handleChange}
                                required
                            />
                        </span>

                        <span className="input-field description">
                            <textarea
                                name="description"
                                placeholder="Describe the reason"
                                value={formData.description}
                                onChange={handleChange}
                                required
                                rows="4"
                            />
                        </span>

                        <button type="submit">Submit</button>
                    </div>
                </div>
                <div className="contact-options">
                    <div className="item">
                        <img src={Chatting} alt="" />
                        <div className="data">
                            <span className="line1">Contact for customer support</span>
                            <span className="line2">Lorem ipsum dolor sit amet.</span>
                            <span className="mail">sales@hiresync.com</span>
                        </div>
                    </div>
                    <div className="item">
                        <img src={Deal} alt="" />
                        <div className="data">
                            <span className="line1">Contact for sales</span>
                            <span className="line2">Lorem ipsum dolor sit amet.</span>
                            <span className="mail">sales@hiresync.com</span>
                        </div>
                    </div>
                    <div className="item">
                        <img src={Location} alt="" />
                        <div className="data">
                            <span className="line1">Visit us</span>
                            <span className="line2">Lorem ipsum dolor sit amet.</span>
                            <a className='mail' href="wwww.googlemaps.com">Google maps</a>
                        </div>
                    </div>
                    <div className="item">
                        <img src={Chatting} alt="" />
                        <div className="data">
                            <span className="line1">Contact Us</span>
                            <span className="line2">Lorem ipsum dolor sit amet.</span>
                            <span className="mail">+912345678087</span>
                        </div>
                    </div>

                    <div className="social-media">
                        <a href="https://linkedin.com">
                            <LinkedinFilled />
                        </a>
                        <a href="https://twitter.com">
                            <XFilled />
                        </a>
                        <a href="https://facebook.com">
                            <FacebookFilled />
                        </a>
                        <a href="https://instagram.com">
                            <InstagramFilled />
                        </a>
                    </div>
                </div>
            </div>
            <div className="faqs">
                <span className="name">Frequently Asked <span className="pink">Questions</span></span>
                <span className="matter">
                    Lorem ipsum dolor sit amet consectetur. Aliquam urna montes amet laoreet pellentesque elementum nisl accumsan.
                </span>
                <div className="faqs-list">
                    {Faqs.faqs && Faqs.faqs.map((item, index) => (
                        <FaqComponent item={item} />
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ContactUs