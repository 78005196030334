import Main from "./Layout";
import CandDashboardCard from "./CandDashboardCard";
import CandInterviewCard from "./CandInterviewCard";
import "./Candidate.css";
import CandidateApplications from "./MyApplications";
import { useAuth } from "../../common/useAuth";
import { Button, message, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Candidate = () => {
  const [data, setData] = useState([]);
  const { token, apiurl } = useAuth();
  const navigate = useNavigate();
  const [reconfirmationData, SetReconfirmationData] = useState([]);

  // cand recofirmation
  const fetchSelectedCandidates = async () => {
    try {
      const API_URL = `${apiurl}/cand/reconfirmation/`;

      const response = await fetch(API_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Sending token for authentication
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      console.log("Selected Candidates:", data.selected_candidates);
      SetReconfirmationData(data || []);

      // Handle data (store in state if using React state management)
      return data.selected_candidates;
    } catch (error) {
      console.error("Failed to fetch selected candidates:", error.message);
      return [];
    }
  };

  console.log("reconfirmationData", reconfirmationData);

  console.log("reconfirmationData", reconfirmationData.job_details);

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiurl}/candidate/applications`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      if (result.error) {
        return message.error(result.error);
      }
      setData(result.data);
    } catch (e) {
      message.error("Failed to fetch applications");
      console.error(e);
    }
  };

  useEffect(() => {
    if (token) {
      fetchData();
      fetchSelectedCandidates();
      fetchInterviews();
    }
  }, [token]);

  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: "Job Title",
      dataIndex: ["job_id", "job_title"],
      key: "job_id",
      render: (jobTitle) => (
        <div
          style={{ color: "#2C5F99", textAlign: "center", fontSize: "16px" }}
        >
          {jobTitle}
        </div>
      ),
    },
    {
      title: "Application Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        const color =
          status === "selected"
            ? "green"
            : status === "rejected"
            ? "red"
            : "blue";
        return <Tag color={color}>{status.toUpperCase()}</Tag>;
      },
    },

    {
      title: "Sender",
      dataIndex: ["sender", "username"],
      key: "sender",
    },
    {
      title: "Receiver",
      dataIndex: ["receiver", "username"],
      key: "receiver",
    },
    // {
    //   title: "Message",
    //   dataIndex: "message",
    //   key: "message",
    //   render: (text) => (text ? text : "No message"),
    // },
    {
      title: "Next Interview",
      dataIndex: ["next_interview", "schedule_date"],
      key: "next_interview",
      render: (date) => (date ? new Date(date).toLocaleString() : "-"),
    },
    {
      title: "Round Number",
      dataIndex: "round_num",
      key: "round_num",
      render: (round_num) => {
        const round = round_num == 0 ? "-" : round_num;
        return <div style={{ color: "#2C5F99" }}>{round}</div>;
      },
    },
    
    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: (text, record) => (
    //     <Button
    //       onClick={() => viewApplication(record)}
    //       // className="view-button"
    //       style={{
    //         backgroundColor: "#1681FF",
    //         color: "#fff",
    //         border: "none",
    //         font: "5px",
    //         // padding: "10px",
    //         cursor: "pointer",
    //         width: "150px",
    //       }}
    //     >
    //       View job
    //     </Button>
    //   ),
    // },
  ];

  const viewApplication = () => {
    console.log("view applications");
  };

  const [interviews, setInterviews] = useState([]);

  const fetchInterviews = async () => {
    try {
      const response = await fetch(`${apiurl}/candidate/upcoming-interviews/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      if (result.error) {
        message.error(result.error);
      } else {
        // Map data to include unique keys for each row
        const formattedData = result.map((item, index) => ({
          ...item,
          key: index, // Ant Design Table requires a unique 'key' for each row
        }));
        setInterviews(formattedData);
      }
    } catch (e) {
      message.error(e.message || "Something went wrong.");
    }
  };

  console.log("interviews", interviews);

  return (
    <Main defaultSelectedKey="1">
      <div className="reconfirm-interview-div">
        <div className="head-reconfirm-container">
          <h2>Re-Confirmation</h2>
          {reconfirmationData?.job_details?.length > 0 ? (
            <div
              style={{ display: "flex", gap: "20px" }}
              className="re-confirm-container"
            >
              {reconfirmationData?.job_details?.map(
                (
                  {
                    job_title,
                    company_name,
                    job_location,
                    selected_candidate_ctc,
                    selected_candidate_id,
                    selected_candidate_joiningDate,
                  },
                  index
                ) => (
                  <CandDashboardCard
                    key={index} // Use a unique key, preferably an ID if available
                    position={job_title}
                    company={company_name}
                    locations={job_location}
                    salary={selected_candidate_ctc}
                    sc_candidate_id={selected_candidate_id}
                    date={selected_candidate_joiningDate}
                  />
                )
              )}
            </div>
          ) : (
            "no re-confirms are there"
          )}
        </div>
        <div className="head-cand-interviews">
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <h2>Candidate Interviews</h2>
            <button
              style={{ width: "", height: "35px" }}
              onClick={() => navigate("/candidate/upcoming_interviews")}
            >
              view More
            </button>
          </div>
          <div className="cand-dash-interviewcards-div">
            {interviews.map((obj, index) => (
              <CandInterviewCard
                key={index}
                round={obj.round_num}
                interviewerName={obj.interviewer_name}
                company={obj.job_id.company_name}
                position={obj.job_id.job_title}
                startTime={obj.scheduled_date_and_time.from_time}
                endTime={obj.scheduled_date_and_time.to_time}
                scheduledDate={obj.scheduled_date_and_time.scheduled_date}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="dashboard-cand-applications">
        <div className="cand-applications-conatiner">
          <h2 style={{ color: "#565E6C" }}>Candidate Applications</h2>
          <Table
            columns={columns}
            dataSource={data}
            rowKey="id"
            pagination={{ pageSize: 5 }}
          />
        </div>
      </div>
    </Main>
  );
};

export default Candidate;
