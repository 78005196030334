import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./CompleteJobPost.css";
import { Button, Table, Modal, Select, message } from "antd";
import Main from "../Layout";
import { useAuth } from "../../../common/useAuth";
import ViewJobPost from "../../../common/ViewJobPost";

const CompleteJobPost = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const { apiurl, token } = useAuth();
	const [job, setJob] = useState(null);
	const [recruiters, setRecruiters] = useState([]);
	const [selectedRecruiter, setSelectedRecruiter] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [status, setStatus] = useState();
	const [interviewers, setInterviewers] = useState();

	const fetchJobDetails = async () => {
		if (token) {
			try {
				const response = await fetch(`${apiurl}/job-details?job_id=${id}`, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				const data = await response.json();
				setJob(data);
				setInterviewers(data.interview_details)
			} catch (error) {
				console.error("Error fetching job details:", error);
			}
		}
	};

	const fetchJobEditDetails = async () => {
		try {

			const response = await fetch(`${apiurl}/client/job-edit-details?id=${id}`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			const data = await response.json()
			if (data.status) {
				setStatus(data.status);
			}
			else if (data.notFound) {
				setStatus('not found');
			}
		}
		catch (e) {
			console.error(e)
		}
	}

	useEffect(() => {
		fetchJobDetails();
		fetchJobEditDetails();
	}, [token, id, apiurl]);

	const fetchRecruiters = async () => {
		try {
			const response = await fetch(`${apiurl}/agency/recruiters/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			setRecruiters(data);
		} catch (error) {
			console.error("Error fetching recruiters:", error);
		}
	};

	const handleAssign = () => {
		setIsModalVisible(true);
		fetchRecruiters();
	};

	const handleChangeRecruiter = () => {
		setIsModalVisible(true);
		fetchRecruiters();
	};

	const handleAcceptJobPost = async (accept) => {
		try {
			const response = await fetch(`${apiurl}/org/accept-job/?id=${id}&accept=${accept}`, {
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${token}`
				}
			})
			const data = response.json();
			if (data.error) {
				message.error(data.error)
			}
			if (data.message) {
				message.success(data.message)
			}
			window.location.reload()
		}
		catch (e) {
			console.error(e)
			message.error(e)
		}
	}

	const handleCloseJob = async () => {
		try {
			const response = await fetch(`${apiurl}/manager/close-job/?id=${id}`, {
				method: 'POST',
				headers: {
					"Authorization": `Bearer ${token}`
				}
			})
			if (!response.data) {
				message.error("There is an error in closing job");
			}
			const data = await response.json();
			if (data.error) {
				return message.error(data.error);
			}
			if (data.message) {
				message.success(data.message);
				window.location.reload();
			}
			console.log(data)
		}
		catch (e) {
			console.error(e)
		}
	}


	const handleSubmitRecruiter = async () => {
		if (!selectedRecruiter) {
			message.error("Please select a recruiter");
			return;
		}
		try {
			const response = await fetch(`${apiurl}/assign-recruiter/`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ job_id: id, recruiter_ids: selectedRecruiter }),
			});
			const data = await response.json();
			if (response.ok) {
				message.success("Recruiter updated successfully");
				setIsModalVisible(false);
				fetchJobDetails();
			} else {
				message.error("Failed to update recruiter");
			}
		} catch (error) {
			console.error("Error updating recruiter:", error);
			message.error("An error occurred while updating the recruiter");
		}
	};

	return (
		<Main>
			{message && message.success && <div className="success">{message.success}</div>}
			{message && message.error && <div className="error">{message.error}</div>}
			<div className="job-details">
				{job && interviewers &&

					<div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
						{/* <h1>Job Details by {job.username.username}</h1> */}
						<ViewJobPost id={id} job={job} interviewers={interviewers}></ViewJobPost>
					</div>
				}
				<hr />
				{
					job?.approval_status === 'pending' && (
						<div className="buttons">
							<Button
								onClick={() => handleAcceptJobPost(true)}
								type="primary"
							>
								Accept
							</Button>
							<Button onClick={() => navigate(`/agency/edit_job/${id}`)}>
								Edit Job
							</Button>
							<Button
								onClick={() => handleAcceptJobPost(false)}
								type="primary"
								danger
							>
								Reject
							</Button>

						</div>
					)
				}
				{job?.approval_status === 'rejected' && (
					<h2 className="red">Rejected</h2>
				)}
				{job?.approval_status === 'accepted' && job?.status === "opened" && (
					<div>
						<div>
							<h3>Job Assigned To..</h3>
							{job.assigned_to && job.assigned_to.length > 0 ? (
								<>
									{job.assigned_to.map((recruiter) => (
										<h4 key={recruiter.id} className="green">
											{recruiter.username}
										</h4>
									))}
									<Button onClick={handleChangeRecruiter} disabled={status === 'pending'}>
										Change the Staff
									</Button>
								</>
							) : (
								<Button onClick={handleAssign} disabled={status === 'pending'}>
									Assign Job to Staff
								</Button>
							)}

							{status && (status == 'pending') && (
								<div className="red">
									Your Edit request is pending.....
								</div>
							)}

						</div>
					</div>
				)}
				{job?.status == 'openend' &&
					<div className="closeJob">
						<Button type="primary" danger onClick={() => { handleCloseJob() }}>Close Job</Button>
					</div>
				}

				{job?.status == 'closed' &&
					<div className="error">Job Closed</div>
				}

			</div>



			<Modal
				title="Select Recruiters"
				open={isModalVisible}
				onOk={handleSubmitRecruiter}
				onCancel={() => setIsModalVisible(false)}
				okText="Submit"
				cancelText="Cancel"
			>
				<Select
					mode="multiple" // Enable multiple selection
					style={{ width: "100%" }}
					placeholder="Select recruiters"
					onChange={setSelectedRecruiter} // Ensure selectedRecruiter is an array
					value={selectedRecruiter} // Should be an array of selected recruiter IDs
				>
					{recruiters.map((recruiter) => (
						<Select.Option key={recruiter.id} value={recruiter.id}>
							{recruiter.username}
						</Select.Option>
					))}
				</Select>
			</Modal>

		</Main>
	);
};

export default CompleteJobPost;
