import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../common/useAuth';
import { message, Spin } from 'antd';
import Location from "../../../../images/Client/Location.svg";
import Note from "../../../../images/Client/note.svg";
import Experience from "../../../../images/Client/Experience.svg";
import MoneyBag from "../../../../images/Client/money bag.svg";
import Piechart from "../../../../images/Client/Piechart.svg";
import Bag from "../../../../images/Client/Closed.svg";
import "./ViewResume.css";
import { Modal, Input } from 'antd';

const ViewResume = ({ id, onClose }) => {
    const { apiurl, token } = useAuth();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [modalType, setModalType] = useState("");
    const [reason, setReason] = useState("");
    const [currentResumeId, setCurrentResumeId] = useState();


    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await fetch(`${apiurl}/client/applications/complete-resume/?id=${id}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const resData = await response.json();
            if (resData.error) {
                message.error(resData.error);
            } else {
                setData(resData);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (token && id) fetchData();
    }, [token, id]);

    const openModal = (type, resumeId) => {
        setModalType(type);
        setCurrentResumeId(resumeId);
        setReason("");
    };


    const handleModalSubmit = async () => {
        setLoading(true);
        let url = "";
        let body = {};
        console.log(currentResumeId, " is the current application id")

        if (modalType === "accept") {
            url = `${apiurl}/client/accept-application/?id=${currentResumeId}`;
            body = { acceptReason: reason, resume_id: currentResumeId, round_num: 1 };
        } else if (modalType === "reject") {
            url = `${apiurl}/client/reject-application/?id=${currentResumeId}`;
            body = { feedback: reason };
        } else if (modalType === "select") {
            url = `${apiurl}/client/select-application/?id=${currentResumeId}`;
            body = { acceptReason: reason };
        }

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            });

            const resData = await response.json();
            if (!response.ok) {
                message.error(resData.error || "An error occurred.");
            } else {
                message.success(resData.message);
                window.location.reload();
            }
        } catch (e) {
            message.error(e.message);
        } finally {
            setLoading(false);
            setModalType("");
        }
    };


    return (
        <div className='application-modal'>
            {loading ? (
                <Spin tip="Loading..." />
            ) : data && (
                <div className="complete-application">
                    <div className="line1">
                        <div className="name-details">
                            <span className="cand-name">{data.candidate_name}</span>
                            <span className="dob">{data.date_of_birth || "N/A"}</span>
                        </div>
                        {data.resume && (
                            <a
                                className="view-resume"
                                href={`${apiurl}/${data.resume}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                View Resume
                            </a>
                        )}
                    </div>
                    {data.current_job_type &&
                        <div className="line2">
                            <span className="component"><img src={Bag} alt="" />{data.current_organization || "N/A"}</span>
                            <span className="component"><img src={Location} alt="" />{data.current_job_location || "N/A"}</span>
                            <span className="component"><img src={MoneyBag} alt="" />{data.current_ctc ? `${data.current_ctc} LPA` : "N/A"}</span>
                            {data.notice_period && <span className="component"><img src={Note} alt="" />{data.notice_period} days Notice</span>}
                            <span className="component"><img src={Piechart} alt="" />{data.current_job_type || "N/A"}</span>
                            <span className="component"><img src={Experience} alt="" />{data.experience ? `${data.experience} yrs experience` : "N/A"}</span>
                        </div>
                    }

                    <div className="line2">
                        <div className="component"><img src={MoneyBag} alt="" />Expected CTC: {data.expected_ctc ? `${data.expected_ctc} LPA` : "N/A"}</div>
                        <div className="component"><img src={Note} alt="" />Experience: {data.experience ? `${data.experience} years` : "N/A"}</div>
                    </div>

                    {
                        data.other_details && (
                            <div className="line2">
                                <div className="component">Other Details: {data.other_details}</div>
                            </div>
                        )
                    }

                    {/* Primary Skills */}
                    <div className="line3">
                        <div className="main">Primary Skills</div>
                        <div className="primary-skills skills">
                            {data.primary_skills && data.primary_skills.length > 0 ? data.primary_skills.map((skill, idx) => (
                                <div key={idx} className="skill">
                                    <span className="skill-name">{skill.skill_name}</span>
                                    <span className="feature">{skill.skill_metric}: {skill.metric_value}</span>
                                </div>
                            )) : <span>No primary skills listed.</span>}
                        </div>

                        {/* Secondary Skills */}
                        <div className="main">Secondary Skills</div>
                        <div className="secondary-skills skills">
                            {data.secondary_skills && data.secondary_skills.length > 0 ? data.secondary_skills.map((skill, idx) => (
                                <div key={idx} className="skill">
                                    <span className="skill-name">{skill.skill_name}</span>
                                    <span className="feature">{skill.skill_metric}: {skill.metric_value}</span>
                                </div>
                            )) : <span>No secondary skills listed.</span>}
                        </div>
                    </div>

                    {/* Other Details */}


                    {/* Action Buttons */}
                    {data.status === 'pending' ? (
                        <div className="buttons">
                            <button className='reject' onClick={() => openModal("reject", data.resume_id)}>Reject</button>
                            {data.next_interview ? (
                                <button className='accept' onClick={() => openModal("accept", data.resume_id)}>Shortlist</button>
                            ) : (
                                <button className='select' onClick={() => openModal("select", data.resume_id)}>Direct Select</button>
                            )}
                        </div>
                    ) : (
                        <div className="accept">
                            {data.status}
                        </div>
                    )}

                </div >
            )}
            <Modal
                title={
                    modalType === "accept" ? "Shortlist Candidate" :
                        modalType === "reject" ? "Reject Candidate" :
                            modalType === "select" ? "Direct Select Candidate" : ""
                }
                open={modalType !== ""}
                onOk={handleModalSubmit}
                onCancel={() => setModalType("")}
                okText="Submit"
            >
                <p>Please provide a reason:</p>
                <Input.TextArea
                    rows={4}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder="Enter reason here..."
                />
            </Modal>
        </div >
    );
};

export default ViewResume;
