import "./App.css";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Forms from "./components/forms/forms";
import ClientSignup from "./components/forms/ClientSignup";
import ForgotPassword1 from "./components/forms/ForgotPassword";
import SetPassword from "./components/forms/SetPassword";
import { AuthRoute } from "./components/common/AuthRoute";
import MainHome from "./components/Home/Main";
import MyJobPosts from "./components/dashboard/client/ViewJobPost/MyJobPosts";
import AgencySignUp from "./components/forms/AgencySignup";
import OrganizationTerms from "./components/dashboard/Manager/TermsAndConditions/TermsAndConditions";
import JobPosting from "./components/dashboard/client/PostingNewJob/PostJob";
import SeeNegotiations from "./components/dashboard/Manager/TermsAndConditions/Negotiations";
import JobPosts from "./components/dashboard/Manager/JobPost/JobPosts";
import CompleteJobPost from "./components/dashboard/Manager/JobPost/CompleteJobPost";
import Recruiters from "./components/dashboard/Manager/Recruiter/Recruiters";
import MyTask from "./components/dashboard/Recruiter/MyTask";
import CompleteJobPostRecruiter from "./components/dashboard/Recruiter/CompleteJobPosts";
import CompleteJobPost_Client from "./components/dashboard/client/ViewJobPost/CompleteJobPost";
import Editjob from "./components/dashboard/Manager/EditJob/Editjob";
import AnyEditRequests from "./components/dashboard/client/EditRequests/AnyEditRequests";
import ParticularJobEdit from "./components/dashboard/client/EditRequests/JobEditRequest";
import Resumes from "./components/dashboard/client/Resumes/Resumes";
import ScheduledInterviewsClient from "./components/dashboard/client/Interviewers/ScheduledInterviews";
import Approvals from "./components/dashboard/client/Approvals/Approvals";
import Interviewers from "./components/dashboard/client/Interviewers/Interviewers";
import ConductInterview from "./components/dashboard/Interviewer/ConductInterview";
import UpcomingInterviews from "./components/dashboard/Candidate/UpcomingInterviews";
import Profile from "./components/common/Profile";
// import Profile from "./components/dashboard/Candidate/profile/Profile";
import Candidate from "./components/dashboard/Candidate/Candidate";
import CandidateDetails from "./components/dashboard/Candidate/profile/CandidateProfile";
import CandidateApplications from "./components/dashboard/Candidate/MyApplications";
import RecruiterProfile from "./components/dashboard/Recruiter/Profile/RecruiterProfile";
import Invoice from "./components/dashboard/Manager/Invoices/AllInvoice";
import VerifyEmail from "./components/forms/VerifyEmail";
import ViewCandidate from "./components/dashboard/Manager/Candidate/ViewCandidate";
import ApplicationsToSchedule from "./components/dashboard/Recruiter/ScheduleInterviews/ApplicationsToSchedule";
import JobResponseCard from "./components/dashboard/Manager/managercards/JobResponseCard";
import JobResponses from "./components/dashboard/Manager/JobResponses/JobResponses";
import CandidatesCard from "./components/dashboard/Manager/managercards/CandidatesCard";
import Candidates from "./components/dashboard/Manager/Candidates/Candidates";
import RecTaskTracking from "./components/dashboard/Manager/Recruiter/RecTaskTracking";
import AllRecruiters from "./components/dashboard/Manager/Recruiter/AllRecruiters";
import ViewJobPost from "./components/common/ViewJobPost";
import Onhold from "./components/dashboard/client/Candidates/OnHold/Onhold";
import SelectedJobs from "./components/dashboard/Candidate/SelectedJobs/SelectedJobs";
import ReconfirmationCandidates from "./components/dashboard/Recruiter/Reconfirmation/ReconfirmationCandidates";
import AllInvoices from "./components/dashboard/client/Invoices/AllInvoices";
import AllInvoicesorg from "./components/dashboard/Manager/Invoices/AllInvoice";
import ReopenJobsList from "./components/dashboard/client/ReopenJobs/ReopenJobsList";
import ReopenJob from "./components/dashboard/client/ReopenJobs/ReopenJob";
import SelectedCandidates from "./components/dashboard/Manager/SelectedCandidates/SelectedCandidates";
import JoinedCandidates from "./components/dashboard/client/Candidates/JoinedCandidates/JoinedCandidates";
import Replacement from "./components/dashboard/client/Replacements/Replacement";
import OrganizationApplications from "./components/dashboard/Recruiter/OrganizationApplications";
import ClientJobs from "./components/dashboard/client/ViewJobPost/ClientJobs";
import ViewInterviewer from "./components/dashboard/client/Interviewers/ViewInterviewer";
import Interviews from "./components/dashboard/Interviewer/Interviews/Interviews";
import AllInterviewDetails from "./components/dashboard/client/Interviewers/AllInterviewDetails";
import Applications from "./components/dashboard/client/Resumes/Applications";
import Compare from "./components/dashboard/client/Resumes/Compare";
import CandidateClient from "./components/dashboard/client/Candidates/Candidates/CandidateClient";
import AllCandidatesClient from "./components/dashboard/client/Candidates/AllCandidatesClient";
import ResumeScoreCard from "./components/dashboard/Recruiter/Scorecard/ResumeScoreCard";
import FileUploadCard from "./components/dashboard/Recruiter/FileuploadCard/FileUploadCard";
import ViewTickets from "./components/tickets/ViewTickets";
import HandleTickets from "./components/tickets/HandleTickets";
import Admin from "./components/dashboard/Admin/Admin";
import AdminTickets from "./components/dashboard/Admin/AdminTickets";
import Blogs from "./components/Blogs/Blogs";
import AdminBlogs from "./components/dashboard/Admin/Blogs/AdminBlogs";
import InterviewCalendar from "./components/dashboard/Manager/managercards/InterviewCalendar";
import AnimatedDiv from "./components/animation/AnimatedDiv";
import RecruiterSummery from "./components/dashboard/Manager/Recruiter/RecruiterSummery";
import Accountant from "./components/dashboard/Accountants/Accountant";
import { RotateLeftOutlined } from "@ant-design/icons";
import LandingHome from "./components/LandingPage/LandingHome";
import Tickets from "./components/tickets/Tickets";
import AboutUs from "./components/LandingPage/AboutUs";
import ContactUs from "./components/LandingPage/ContactUs";
import SendApplication from "./components/dashboard/Recruiter/SendApplications/SendApplication";
import ComingSoon from "./components/ComingSoon";
import { useEffect } from "react";
const App = () => {
	const now = new Date();
	const launchDate = new Date(2025, 2, 30, 10, 0, 0);
	const isLaunchReady = now >= launchDate;
	const navigate = useNavigate();
	useEffect(() => {
		if (!isLaunchReady) {
			navigate("/coming-soon", { replace: true });
		}
	}, [isLaunchReady, navigate]);
	return (
		<>
			<Routes>
				{!isLaunchReady && <Route path="*" element={<Navigate to="/coming-soon" replace />} />}
				<Route path="/login" element={<Forms />} />
				<Route path="/forgot_password" element={<ForgotPassword1 />} />
				<Route path="/reset/:uuid/:token" element={<SetPassword />} />
				<Route path="/verify-email/:uuid/:token" element={<VerifyEmail />} />
				<Route path="/client/signup" element={<ClientSignup />} />
				<Route path="/agency/signup" element={<AgencySignUp />} />
				<Route path="/welcome" element={<LandingHome />} />
				<Route path="/coming-soon" element={<ComingSoon />} />
				<Route path="/about-us" element={<AboutUs />} />
				<Route path="/contact-us" element={<ContactUs />} />
				<Route path="/" element={<AuthRoute />}>
					<Route path="/" element={<MainHome />} />
					<Route path="/profile" element={<Profile />} />



					<Route path="/client/mypostings" element={<ClientJobs />} />
					<Route path="/client/postjob" element={<JobPosting />} />
					<Route path="/client/complete_job_post/:id" element={<CompleteJobPost_Client />}></Route>
					<Route path="/client/edited_job_details/:id" element={<ParticularJobEdit />} />
					<Route path="/client/edit-requests" element={<AnyEditRequests />}></Route>
					<Route path="/client/applications" element={<Applications />} />
					<Route path="/client/get-resumes/:id" element={<Resumes />} />
					<Route path="/client/get-resumes/compare/:id" element={<Compare />} />
					<Route path="/client/interviewers" element={<AllInterviewDetails />} />
					<Route path='/client/reopen-jobslist' element={<ReopenJobsList />} />
					<Route path='/client/reopen-job/:id' element={<ReopenJob />} />
					<Route path='/client/approvals/' element={<Approvals />}></Route>
					<Route path='/client/interviewer/:id' element={<ViewInterviewer />} />
					<Route path='/client/joined-candidates' element={<JoinedCandidates />} />
					<Route path='/client/replacements' element={<Replacement />} />
					<Route path='/client/on-hold' element={<Onhold />} />
					<Route path='/client/invoices' element={<AllInvoices />} />
					<Route path='/client/candidates/:id' element={<CandidateClient />} />
					<Route path='/client/candidates/' element={<AllCandidatesClient />} />

					<Route path="/interviewer/conduct-interview/:id" element={<ConductInterview />} />
					<Route path="/interviewer/interviews" element={<Interviews />} />

					<Route path="/agency/terms" element={<OrganizationTerms />} />
					<Route path="/animation" element={<AnimatedDiv />} />


					<Route path="/agency/reports" element={<AllInvoicesorg />} />
					<Route path="/agency/jobs" element={<JobPosts />} />
					<Route path="/agency/postings/:id" element={<CompleteJobPost />} />
					<Route path="/agency/edit_job/:id" element={<Editjob />} />
					<Route path="/agency/negotiations" element={<SeeNegotiations />} />
					<Route path="/agency/recruiters" element={<Recruiters />} />
					<Route path="/agency/invoices" element={<Invoice />}></Route>
					<Route path='/agency/view_candidate' element={<ViewCandidate />} />
					<Route path="/agency/all_recruiters" element={<AllRecruiters />} />
					<Route path="/agency/task_tracking" element={<RecTaskTracking />} />
					<Route path="/agency/jobresponses" element={<JobResponses />} />
					{/* <Route path="/agency/card" element={<JobResponseCard />} /> */}
					<Route path="/agency/card" element={<CandidatesCard />} />
					<Route path="/agency/Candidates" element={<Candidates />} />
					<Route path="/agency/calender" element={<InterviewCalendar />} />
					<Route path="/agency/selected-candidates" element={<SelectedCandidates />} />
					<Route path="/agency/recruiters/:id" element={<RecruiterSummery />} />


					<Route path="/recruiter/postings" element={<MyTask />} />
					{/* <Route path="/recruiter/postings/:id" element={<CompleteJobPostRecruiter />} /> */}
					<Route path="/recruiter/postings/:id" element={<SendApplication />} />
					<Route path="/recruiter/profile" element={<RecruiterProfile />} />
					<Route path="/recruiter/schedule_applications" element={<ApplicationsToSchedule />} />
					<Route path='/recruiter/reconfirmation-applications' element={<ReconfirmationCandidates />} />
					<Route path='/recruiter/organization-applications' element={<OrganizationApplications />} />
					<Route path="resumescorecard/" element={<ResumeScoreCard />}></Route>
					<Route path="upload/" element={<FileUploadCard />}></Route>


					<Route path='/candidate/profile' element={<CandidateDetails />}></Route>
					<Route path='/candidate/upcoming_interviews' element={<UpcomingInterviews />} />
					<Route path='/candidate/applications' element={<CandidateApplications />} />
					<Route path='/candidate/selected_jobs' element={<SelectedJobs />} />

					<Route path='/admin' element={<Admin />}></Route>
					<Route path='/admin/tickets' element={<AdminTickets />}></Route>

					<Route path='/view-tickets' element={<ViewTickets />} />
					<Route path='/tickets' element={<Tickets />} />
					<Route path='/handle-tickets' element={<HandleTickets />} />

					<Route path='/blogs' element={<Blogs />} />
					<Route path='/admin/blogs' element={<AdminBlogs />} />
					<Route path='/accountant/invoice' element={<Accountant />} />

				</Route>
			</Routes>
		</>
	);
};

export default App;
